import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header huonekortti-historia-item-header" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { TilaHistoriaResponse } from "@/api/models/tila-historia-response"
import { TilaResponse } from "@/api/models/tila-response"
import ComCard from "@/components/common/ComCard.vue"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import { diffAndSanitize } from "@/utils/diff"
import { computed } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComTilaHistoriaItem',
  props: {
    data: {},
    previousData: {}
  },
  setup(__props: any) {

const props = __props

const { tyyppiColor } = useHuonekorttiHistoriaMethods()
const tyyppi = computed(() => props.previousData.tyyppi)
const timelineColor = computed(() => tyyppiColor(tyyppi.value))
const tilatunnus = computed(() => {
    const a = props.previousData.tilatunnus ?? ""
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = props.data.tilatunnus ?? ""
        return diffAndSanitize(a, b)
    } else {
        return a
    }
})
const pintaAla = computed(() => {
    const a = props.previousData.pintaAla ? props.previousData.pintaAla.toString() : ""
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = props.data.pintaAla ? props.data.pintaAla.toString() : ""
        return diffAndSanitize(a, b)
    } else {
        return a
    }
})
const otsikko = computed(() => {
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Lisays) {
        return "Tila lisätty"
    }
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos) {
        return "Tilaa muokattu"
    }
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Poisto) {
        return "Tila poistettu"
    } else {
        throw new Error("Virheellinen tyyppi!")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!

  return (_openBlock(), _createBlock(_component_el_timeline_item, {
    timestamp: _ctx.previousData.aika,
    placement: "top",
    type: timelineColor.value,
    size: "large"
  }, {
    default: _withCtx(() => [
      _createVNode(ComCard, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(otsikko.value), 1),
          _createElementVNode("table", _hoisted_2, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _cache[0] || (_cache[0] = _createElementVNode("label", null, "Tekijä:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.previousData.kayttaja?.etunimi) + " " + _toDisplayString(_ctx.previousData.kayttaja?.sukunimi), 1)
                ]),
                _createElementVNode("td", null, [
                  _cache[1] || (_cache[1] = _createElementVNode("label", null, "Aika:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.previousData.aika), 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("label", null, "Tilatunnus:", -1)),
                  _createElementVNode("span", { innerHTML: tilatunnus.value }, null, 8, _hoisted_3)
                ]),
                _createElementVNode("td", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", null, "Pinta-ala:", -1)),
                  _createElementVNode("span", { innerHTML: pintaAla.value }, null, 8, _hoisted_4)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["timestamp", "type"]))
}
}

})