<template>
    <span @click="deleteHuonekorttiClick">Poista</span>
</template>

<script setup lang="ts">
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { ElMessageBox } from "element-plus"
import { huonekorttiListing } from "../huonekortit";

const props = defineProps<{
        huonekortti: HuonekorttiResponse
}>()

const { deleteHuonekortti } = huonekorttiListing()
const deleteHuonekorttiClick = () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa huonekortin: " + props.huonekortti.tilanimike?.nimi).then(
        () => {
            if (props.huonekortti.id) {
                deleteHuonekortti(props.huonekortti.id)
            }
        }
    )
}

</script>
