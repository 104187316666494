import http from "@/http-common"
import { useQuery } from "@tanstack/vue-query"

const url = "tilanimikkeet"

export function useTilanimikeet() {
    const key = [url]
    
    const fetcher = async () => {
        const response = await http.get(url)
        return response.data
    }

    const { data: tilanimikkeet, error, isPending: loading } = useQuery({ queryKey: key, queryFn: () => fetcher() })

    return {
        tilanimikkeet,
        error,
        loading,
    }
}
