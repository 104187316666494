import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { KommenttiResponse } from "@/api/models/kommentti-response"
import { computed,  PropType, watch } from "vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { useKayttaja } from "@/methods/kayttajaComposables"
import { useKommentitComponent } from "./kommentit"
import { useDeleteKommentti } from "@/methods/kommenttiComposables"
import { ElMessage, ElMessageBox } from "element-plus"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComDeleteKommentti',
  props: {
    kommentti: {
        type: Object as PropType<KommenttiResponse>,
        required: true,
    },
},
  setup(__props) {

const props = __props

const { isPaakayttaja } = useKommentitComponent()
const { deleteKommentti, error, response } = useDeleteKommentti()
const { kayttaja } = useKayttaja()
const hasRight = computed(() => {
    if (isPaakayttaja.value) {
        return true
    }
    if (kayttaja.value && kayttaja.value.id == props.kommentti.kayttaja?.id) {
        return true
    } else {
        return false
    }
})

const deleteKommenttiClick = () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa kommentin?", "Poista kommentti", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    }).then(() => {
        deleteKommentti(props.kommentti.id)
    })
}

watch(error, (val) => {
    if (val) {
        ElMessage.error("Kommentin poistaminen epäonnistui")
    }
})
watch(response, (val) => {
    if (val) {
        ElMessage.success("Kommentti poistettu")
    }
})


return (_ctx: any,_cache: any) => {
  return (hasRight.value)
    ? (_openBlock(), _createBlock(ComDeleteButton, {
        key: 0,
        onClick: deleteKommenttiClick
      }))
    : _createCommentVNode("", true)
}
}

})