<template>
    <el-dropdown class="huonekortti-info-dropdown">
        <ComMoreButton />
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="tulostaClick">Tulosta</el-dropdown-item>
                <el-dropdown-item @click="showHanketiedot = true">Tiedot</el-dropdown-item>
                <el-dropdown-item v-if="isHyvaksytty" @click="showMuutokset = true">Historia</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script setup lang="ts">
import raportointiService from "@/methods/raportointiService"
import ComMoreButton from "../common/buttons/ComMoreButton.vue"
import { huonekorttiPage } from "@/views/huonekortti"

const { isHyvaksytty, huonekortti, showMuutokset, showHanketiedot  } = huonekorttiPage()
const loadHuonekorttiRaportti = raportointiService.loadHuonekorttiRaportti

const tulostaClick = () => {
    if (huonekortti.value) {
        loadHuonekorttiRaportti(1, [huonekortti.value.id])
    }
}

</script>

<style scoped>
.huonekortti-info-dropdown {
    cursor: pointer;
}
</style>
