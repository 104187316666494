<template>
    <ComTrashButton @click="show = true" />
    <template v-if="show">
        <el-drawer v-model="show" append-to-body title="Roskakori" size="50%" destroy-on-close>
            <ComRoskakoriContent />
        </el-drawer>
    </template>
</template>

<script setup lang="ts">
import ComTrashButton from "@/components/common/buttons/ComTrashButton.vue"
import { ref } from "vue"
import ComRoskakoriContent from "./ComRoskakoriContent.vue"

const show = ref(false)
</script>
