import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { useHuonekorttiTable } from "./huonekorttiTable"
import { ElMessage, TableInstance } from "element-plus"
import { ref, watch } from "vue"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { hankePage } from "@/views/hanke"
import { useHuonekortti, useHuonekorttiUpdate } from "@/methods/huonekorttiComposablses"
import { huonekorttiListing } from "../huonekortit"
import _ from "lodash"

export function huonekorttiTableEditable() {
    const { id, hankeVastuuyksikot, tilanimikkeet, writableVastuuyksikot, paakayttaja, resetTilanumero } = hankePage()
    const { huonekortit, sort, sortChanged, updateHuonekortit, addTila, removeTila } = huonekorttiListing()
    const { formatTilat } = useHuonekorttiTable()
    const edit = ref()
    const editedHuonekortti = ref<HuonekorttiRequestPut>({} as HuonekorttiRequestPut)
    const { huonekortti } = useHuonekortti(edit)
    const { updateHuonekortti, response, loading, error  } = useHuonekorttiUpdate()
    const more = ref()
    const mallihuonekortti = ref<HuonekorttiResponse>()
    const mallihuonekorttiDialog = ref(false)
    const tableRef = ref<TableInstance>()

    const checkPoistoOikeus = (val: HuonekorttiResponse) => {
        if (val.status == HuonekorttiStatusResponse.Hyvaksytty && paakayttaja) {
            return true
        } else if (
            val.status != HuonekorttiStatusResponse.Hyvaksytty &&
            writableVastuuyksikot.value?.some((v) => v?.id == val.vastuuyksikko.id)
        ) {
            return true
        } else {
            return false
        }
    }
    const editHuonekortti = (item: HuonekorttiResponse) => {
        editedHuonekortti.value = _.cloneDeep(item)
        edit.value = item.id
    }
    const cancelEdit = () => {
        updateHuonekortit()
        resetTilanumero()

        edit.value = undefined
    }
    const saveEdit = async (item: HuonekorttiResponse) => {
        if (
            item.vastuuyksikko?.id &&
            item.tilanimike?.id &&
            item.tilat &&
            item.tilat.length > 0 &&
            item.id &&
            huonekortti.value
        ) {
            editedHuonekortti.value.toiminnanKuvaus = huonekortti.value.toiminnanKuvaus
            editedHuonekortti.value.toiminnanVaatimukset = huonekortti.value.toiminnanVaatimukset
            editedHuonekortti.value.henkilomaaraNorm = huonekortti.value.henkilomaaraNorm
            editedHuonekortti.value.henkilomaaraMax = huonekortti.value.henkilomaaraMax
            editedHuonekortti.value.sahkoluokka = huonekortti.value.sahkoluokka
            editedHuonekortti.value.toimintaAika = huonekortti.value.toimintaAika

            await updateHuonekortti(item.id, editedHuonekortti.value)
        }
    }
    const addMallihuonekorttiClick = (val: HuonekorttiResponse) => {
        mallihuonekortti.value = val
        mallihuonekorttiDialog.value = true
    }

    watch(id, () => {
        if (id.value) {
            tableRef.value?.sort(sort.value.prop, sort.value.order.toString())
        }
    })
    
    watch(loading, (val) => {
        if(!val && response.value) {
            ElMessage({ message: "Huonekortti tallennettu!", type: "success" })

            edit.value = undefined
        }
        if(!val && error.value) {
            ElMessage({ message: "Huonekortin tallennus epäonnistui!", type: "error" })
        }
    })
    
    return {
        huonekortit,
        edit,
        tilanimikkeet,
        hankeVastuuyksikot,
        mallihuonekorttiDialog,
        paakayttaja,
        writableVastuuyksikot,
        mallihuonekortti,
        more,
        sort,
        tableRef,
        editedHuonekortti,
        sortChanged,
        formatTilat,
        checkPoistoOikeus,
        addTila,
        removeTila,
        editHuonekortti,
        cancelEdit,
        saveEdit,
        addMallihuonekorttiClick,
    }
}
