import { KommenttiRequest } from "@/api/models/kommentti-request"
import kommenttiTyyppi from "@/types/Kommentti"
import {  computed, Ref } from "vue"
import http from "@/http-common"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { KommenttiResponse } from "@/api/models/kommentti-response"

const url = "/kommentit"

export function useKommentit(viite: Ref<number | undefined>, tyyppi: Ref<kommenttiTyyppi | undefined>) {
    const key = computed(() => [url, { viite: viite.value, tyyppi: tyyppi.value }]) 

    const fetcher = async (): Promise<KommenttiResponse[]> => {
        return (await http.get(url, { params: { viite: viite.value, tyyppi: tyyppi.value } })).data
    }

    const enabled = computed(() => {
        if(viite.value && tyyppi.value) {
            return true
        }
        else {
            return false
        }
    })

    const {
        data: kommentit,
        error,
        isLoading: loading,
    } = useQuery({ queryKey: key, queryFn: () => fetcher(), enabled: () => enabled.value })

    return {
        kommentit,
        error,
        loading,
    }
}

export function useAddKommentti() {
    const queryClient = useQueryClient()
    const {  data: response, error, isPending: loading, mutate } = useMutation({
        mutationFn: (kommentti: KommenttiRequest) => http.post(url, kommentti),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [url]})
            queryClient.invalidateQueries({queryKey: ["huonekortit"]})
            queryClient.invalidateQueries({queryKey: ["/hankkeet"]})
        }
    })

    const addKommentti = async (kommentti: KommenttiRequest) => {
        await mutate(kommentti)
    }

    return {
        response,
        error,
        loading,
        addKommentti,
    }
}

export function useDeleteKommentti() {
    const queryClient = useQueryClient()
    const {  data: response, error, isPending: loading, mutate } = useMutation({
        mutationFn: (id: number) => http.delete(url + "/" + id),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [url]})
            queryClient.invalidateQueries({queryKey: ["huonekortit"]})
        }
    })

    const deleteKommentti = async (id: number) => {
        await mutate(id)
    }

    return {
        response,
        error,
        loading,
        deleteKommentti,
    }
}

