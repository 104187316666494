<template>
    <ComCard>
        <div class="card-header huonekortti-historia-item-header">{{ item.otsikko }}</div>
        <table style="width: 100%">
            <tbody>
                <tr>
                    <td>
                        <label>Tekijä:</label>
                        {{ data?.kayttaja?.etunimi }} {{ data?.kayttaja?.sukunimi }}
                    </td>
                    <td>
                        <label>Aika:</label>
                        {{ data.aika }}
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Nimike:</label>
                        {{ data.nimike?.nimi }}
                    </td>
                    <td>
                        <label>Määrä:</label>
                        <span v-html="data.maara" />
                    </td>
                </tr>
                <tr>
                    <th colspan="4">Täsmennys</th>
                </tr>
                <tr>
                    <td colspan="4">
                        <span v-html="data.tasmennys" />
                    </td>
                </tr>
                <tr>
                    <th colspan="4">Ominaisuudet</th>
                </tr>
                <tr>
                    <td colspan="4">
                        <span v-html="ominaisuudet" />
                    </td>
                </tr>
            </tbody>
        </table>
    </ComCard>
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { computed } from "vue"
import HistoriaNimike from "@/types/HistoriaNimike"
import ComCard from "@/components/common/ComCard.vue"

const props = defineProps<{
    data: HistoriaNimike
}>()
const item = computed(() => {
    const item = {
        otsikko: "",
        sisalto: "",
    }
    const nimike = props.data.nimike?.nimi?.toLowerCase()
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Lisays) {
        item.otsikko = `Nimike ${nimike} lisätty`
    }
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Poisto) {
        item.otsikko = `Nimike ${nimike} poistettu`
    }
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        item.otsikko = `Nimikettä ${nimike} muokattu`
    }
    return item
})
const ominaisuudet = computed(() => {
    return props.data.ominaisuudet?.map((o) => o.nimi).join(", ")
})
</script>

<style scoped>
.huonekortti-historia-item-header {
    margin-bottom: 10px;
}
</style>
