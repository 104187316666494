import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

import { HuonekorttiHistoriaResponse } from "@/api/models/huonekortti-historia-response"
import { computed } from "vue"
import ComHuonekorttiHistoriaItem from "./ComHuonekorttiHistoriaItem.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { huonekorttiPage } from "@/views/huonekortti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiHistoria',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const { huonekortti } = huonekorttiPage()
const show = computed(() => {
    return historia.value && historia.value.length > 0
})
const historia = computed(() => {
    const temp = props.data

    return temp.sort((a, b) => a.id! - b.id!).reverse()
})

const currentData = (id: number) => {
    let temp: HuonekorttiHistoriaResponse | undefined = undefined
    const i = historia.value.findIndex((h) => h.id == id)

    temp = historia.value[i - 1]

    return temp ?? huonekortti?.value
}

return (_ctx: any,_cache: any) => {
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (show.value)
    ? (_openBlock(), _createBlock(_component_el_timeline, { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(historia.value, (muutos) => {
            return (_openBlock(), _createBlock(ComHuonekorttiHistoriaItem, {
              key: muutos.id,
              data: currentData(muutos.id!),
              previousData: muutos
            }, null, 8, ["data", "previousData"]))
          }), 128))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
}
}

})