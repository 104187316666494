<template>
    <el-form v-model="hankeRequest" label-width="150px" label-position="left">
        <el-form-item label="Hankkeen nimi">
            <el-input v-model="hankeRequest.nimi" />
        </el-form-item>
        <el-form-item label="Hankenumero">
            <el-input v-model="hankeRequest.hankenumero" />
        </el-form-item>
        <el-form-item label="Hankkeen työnumero">
            <el-input v-model="hankeRequest.tyonumero" />
        </el-form-item>
        <el-form-item label="Organisaatio">
            <div>{{ hanke?.sairaala?.nimi }}</div>
        </el-form-item>
        <el-form-item>
            <el-space>
                <ComSuccessButton @click="tallennaHankeClick">Tallenna</ComSuccessButton>
                <ComCancelButton @click="edit = false">Peruuta</ComCancelButton>
            </el-space>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { useUpdateHanke } from '@/methods/hankeComposables';
import { useHanketiedotComponent } from './hanketiedot';
import { HankeRequest } from '@/api/models/hanke-request';
import { ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';

const edit = defineModel({type: Boolean})
const { hanke } = useHanketiedotComponent()
const { response, error, updateHanke } = useUpdateHanke()
const hankeRequest = ref<HankeRequest>({} as HankeRequest)

const tallennaHankeClick = async () => {
    if (hanke.value && hankeRequest.value) {
        await updateHanke(hanke.value.id, hankeRequest.value)
    }
}

watch(hanke, (value) => {
    if (value && edit.value) {
        hankeRequest.value = Object.assign({} as HankeRequest, value)
    }
})
watch(() => response.value, (value) => {
    if (value) {
        ElMessage.success('Hankkeen tiedot tallennettu')
        edit.value = false
    }
})
watch(() => error.value, (value) => {
    if (value) {
        ElMessage.error('Hankkeen tietojen tallennus epäonnistui')
    }
})


</script>