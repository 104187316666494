<template>
    <span>
        <el-popover :width="350" trigger="click">
            <template #reference>
                <el-icon><Avatar /></el-icon>
            </template>
            <ComAvatar />
        </el-popover>
    </span>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { Avatar } from "@element-plus/icons-vue"
import ComAvatar from "@/components/layout/header/ComAvatar.vue"

export default defineComponent({
    name: "LogoutButton",
    components: { Avatar, ComAvatar },
    setup() {
        return {}
    },
})
</script>
