<template>
    <el-form ref="formRef" :model="kayttaja" :rules="formRules" label-width="120px" inline-message>
        <el-form-item label="Sähköpostiosoite" prop="email">
            <el-autocomplete
                v-if="!editedKayttaja"
                v-model="kayttaja.email"
                :fetch-suggestions="emailSuggestions"
                @select="suggestionSelected"
                value-key="email"
                style="width: 100%"
                size="small"/>
            <el-input v-else v-model="kayttaja.email" style="width: 100%" size="small" />
        </el-form-item>
        <el-form-item label="Etunimi" prop="etunimi">
            <el-input size="small" v-model="kayttaja.etunimi" :disabled="selected != undefined"></el-input>
        </el-form-item>
        <el-form-item label="Sukunimi" prop="sukunimi">
            <el-input size="small" v-model="kayttaja.sukunimi" :disabled="selected != undefined"></el-input>
        </el-form-item>
        <el-form-item label="Lisää oikeudet" v-if="!editedKayttaja">
            <ComLisaaKayttajaOikeudet @oikeudet-change="(val) => (oikeudet = val)" />
        </el-form-item>
        <el-form-item>
            <el-space wrap>
                <ComSuccessButton v-if="!selected && !editedKayttaja" @click="addKayttajaClick">
                    Tallenna
                </ComSuccessButton>
                <ComSuccessButton v-else @click="updateKayttajaClick">Päivitä</ComSuccessButton>
                <ComCancelButton @click="kayttajaModalClose">Peruuta</ComCancelButton>
            </el-space>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { KayttajaRequest } from "@/api/models/kayttaja-request"
import { ElMessage, FormInstance, FormRules } from "element-plus"
import { computed, ref, watch, watchEffect } from "vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import ComLisaaKayttajaOikeudet from "./ComLisaaKayttajaOikeudet.vue"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { KayttajaRequestPut } from "@/api/models/kayttaja-request-put"
import { hankePage } from "@/views/hanke"
import { useOikeudetComponent } from "../oikeudet"
import { getKayttajat } from "@/methods/paakayttajaComposables"

const { hanke } = hankePage()
const { addKayttajaResponse, updateKayttajaResponse, addOikeudetResponse, editedKayttaja, addKayttaja, updateKayttaja, addOikeudet, kayttajaModalClose } = useOikeudetComponent()
const kayttaja = ref<KayttajaRequest>({} as KayttajaRequest)
const oikeudet = ref<OikeusRequest[]>()
const suggestions = ref<KayttajaResponse[]>()
const selected = ref<KayttajaResponse>()
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const formRef = ref<FormInstance>()
const formRules = ref<FormRules>({
    email: [
        {
            required: true,
            message: "Täytä Sähköpostiosoite kenttä!",
        },
        {
            type: "email",
            pattern: emailRegex,
            message: "Epäkelpo sähköpostiosoite",
        },
    ],
    etunimi: [
        {
            required: true,
            message: "Täytä etunimi kenttä!",
        },
    ],
    sukunimi: [
        {
            required: true,
            message: "Täytä sukunimi kenttä!",
        },
    ],
})

const sairaala = computed(() => {
    return hanke?.value?.sairaala
})

const emailSuggestions = (query: string, callback: (val: KayttajaResponse[]) => void) => {
    selected.value = undefined

    if (query.length > 2) {
        getKayttajat(query).then((response) => {
            if (response) {
                const match = response.find((s) => s.email == kayttaja.value.email)

                if (match) {
                    suggestionSelected(match)

                    callback([])
                } else {
                    suggestions.value = response

                    callback(response)
                }
            }
        })
    }
}

const suggestionSelected = (val: KayttajaResponse) => {
    selected.value = val

    kayttaja.value.etunimi = val.etunimi
    kayttaja.value.sukunimi = val.sukunimi
}

const addKayttajaClick = () => {
    formRef.value?.validate().then((response) => {
        if (response && sairaala.value) {
            kayttaja.value.sairaalat = [
                {
                    sairaala: {
                        id: sairaala.value?.id,
                    },
                    suunnittelija: false,
                },
            ]

            addKayttaja(kayttaja.value)
        }
    }).catch(() => {
        return
    })
}

const updateKayttajaClick = async () => {
    if (selected.value) {
        let request = {} as KayttajaRequestPut

        if (selected.value.sairaalat?.some((s) => s.sairaala?.id == sairaala.value?.id)) {
            ElMessage.error("Käyttäjä on liitetty jo sairaalaan!")
        } else {
            if (sairaala.value) {
                request = Object.assign({} as KayttajaRequestPut, selected.value)

                request.sairaalat?.push({
                    sairaala: {
                        id: sairaala.value.id,
                    },
                    suunnittelija: false,
                })

                updateKayttaja(selected.value.id, request)
                setOikeudet(selected.value.id)
            }
        }
    } else if (editedKayttaja.value) {
        const edit: KayttajaResponse = JSON.parse(JSON.stringify(editedKayttaja.value))
        edit.email = kayttaja.value.email
        edit.etunimi = kayttaja.value.etunimi
        edit.sukunimi = kayttaja.value.sukunimi

        const request = Object.assign({} as KayttajaRequestPut, edit)

        updateKayttaja(editedKayttaja.value.id, request)
    }
}

const setOikeudet = (kayttajaId: number) => {
    if (oikeudet.value) {
        oikeudet.value.map((o) => (o.kayttaja = { id: kayttajaId }))

        addOikeudet(oikeudet.value)
    }
}

const resetFields = () => {
    if (formRef.value) {
        formRef.value.resetFields()
    }

    selected.value = undefined
}

watch(() => kayttaja.value.email, (n) => {
    if(!editedKayttaja.value) {
        formRef.value?.validateField("email").then((response : boolean) => {
            if(response && n) {
                const etunimi = n.substring(0, n.indexOf("."))
                const sukunimi = n.substring(n.indexOf(".") + 1, n.indexOf("@"))

                kayttaja.value.etunimi = etunimi.charAt(0).toUpperCase() + etunimi.slice(1)
                kayttaja.value.sukunimi =  sukunimi.charAt(0).toUpperCase() + sukunimi.slice(1)
            }
        }).catch(() => {
            return
        })
    }
})

watch(addKayttajaResponse, (response) => {
    if (response) {
        resetFields()
        setOikeudet(response.id)

        ElMessage.success("Käyttäjä lisätty!")
    }
})

watch(updateKayttajaResponse, (response) => {
    if (response) {
        resetFields()
        ElMessage.success("Käyttäjä päivitetty!")

        if(editedKayttaja.value) {
            kayttajaModalClose()
        }
    }
})

watch(addOikeudetResponse, (response) => {
    if (response) {
        ElMessage.success("Oikeudet lisätty!")
    }
})

watchEffect(() => {
    if (editedKayttaja.value) {
        kayttaja.value = JSON.parse(JSON.stringify(editedKayttaja.value))
    }
})

</script>
