<template>
    <ComCardExpand title="Suunnittelutiedot" :way="o" :show="true">
        <ComLisaaNimike :nimikkeet="kategoriat" v-if="kirjoitus"></ComLisaaNimike>
        <div v-if="nimikkeet?.length != 0">
            <div class="float-right-flex" style="padding-bottom: 20px">
                <el-space>
                    <el-checkbox label="Muutokset" v-if="hyvaksytty" v-model="muutoksetActive" border/>
                    <el-button @click="togleAll">Avaa/Sulje</el-button>
                </el-space>
            </div>
            <el-collapse v-model="active">
                <template v-for="(kategoria, index1) in kategoriat" :key="index1">
                    <el-collapse-item
                        class="suunnittelu-collapse-item"
                        :name="kategoria.id"
                        v-if="hasPaakategoriaItems(kategoria.id)">
                        <template #title>
                            <el-icon class="collapse-icon-right" v-if="!active.includes(kategoria.id)">
                                <Folder />
                            </el-icon>
                            <el-icon class="collapse-icon-right" v-else><FolderOpened /></el-icon>
                            <span class="label">{{ kategoria.nimi }}</span>
                        </template>
                        <table v-if="kategoria.children">
                            <tbody>
                                <template v-for="(aliKategoria, index2) in kategoria.children" :key="index2">
                                    <template v-if="hasAlikategoriaItems(aliKategoria.id)">
                                        <tr>
                                            <th colspan="5">{{ aliKategoria.nimi }}</th>
                                        </tr>
                                        <template v-if="!muutoksetActive">
                                            <template v-for="(item, index3) in nimikkeet" :key="index3">
                                                <ComSuunnittelutiedotRow
                                                    v-if="item.nimike?.kategoria?.id === aliKategoria.id"
                                                    :nimike="item" />
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-for="(item, index3) in nimikkeetHistoria" :key="index3">
                                                <ComSuunnittelutiedotRowHistoria
                                                    v-if="item.nimike?.kategoria?.id === aliKategoria.id"
                                                    :nimike="item" />
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </el-collapse-item>
                </template>
            </el-collapse>
        </div>
        <ComEmpty v-else />
    </ComCardExpand>
</template>

<script setup lang="ts">
import ComCardExpand from "../common/ComCardExpand.vue"
import ComLisaaNimike from "./ComLisaaNimike.vue"
import ComEmpty from "../common/ComEmpty.vue"
import { Folder, FolderOpened } from "@element-plus/icons-vue"
import ComSuunnittelutiedotRow from "./ComSuunnittelutiedotRow.vue"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import ComSuunnittelutiedotRowHistoria from "./ComSuunnittelutiedotRowHistoria.vue"

const { kategoriat, kirjoitus, nimikkeet, o, hyvaksytty, muutoksetActive, active, nimikkeetHistoria, hasAlikategoriaItems, hasPaakategoriaItems, togleAll, } = useSuunnittelutiedot()

</script>
<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
    margin-left: 10px;
}
table th {
    text-align: left;
    background-color: #f5f5f5;
}
table tr {
    border-bottom: 1px solid #dcdfe6;
}
table th,
td {
    padding-left: 10px;
}
table tr:last-child {
    border: none;
}
.collapse-icon-right {
    padding-right: 10px;
    font-size: 20px;
    font-weight: 800;
}
</style>
