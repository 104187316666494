import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "oikeus-tag-wrapper" }
const _hoisted_2 = { class: "oikeus-tag-wrapper" }
const _hoisted_3 = { class: "el-table" }
const _hoisted_4 = { class: "el-table__row" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "el-table__cell name_coll" }

import ComOikeusTableButtons from "./ComOikeusTableButtons.vue"
import ComDeleteButton from "@/components/common/buttons/ComDeleteButton.vue"
import ComLisaaOikeus from "./ComLisaaOikeus.vue"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComLisaaKayttaja from "./kayttaja/ComLisaaKayttaja.vue"
import { useOikeudetComponent } from "./oikeudet"
import { KayttajaOikeusResponse } from "@/api/models/kayttaja-oikeus-response"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComOikeudet',
  setup(__props) {

const {filter, editedKayttaja, filteredOikeudet, selectedVastuuyksikot, lisaaKayttajaModal, deleteOikeus, deleteOikeudet, getVastuuyksikkoOikeus, addOikeus, updateOikeus, kayttajaModalClose} = useOikeudetComponent()

const deleteOikeudetClick = (kayttaja: KayttajaOikeusResponse) => {
    const ids = kayttaja.oikeudet?.map(o => o.id)
    
    if(ids) {
        deleteOikeudet(ids)
    }
}

const ddd = () => {
    console.log("ddd")

}



return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComFilterInput, {
      modelValue: _unref(filter),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filter) ? (filter).value = $event : null))
    }, null, 8, ["modelValue"]),
    _createVNode(ComLisaaOikeus),
    _createVNode(_component_el_row, { class: "row-margin" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createVNode(ComSuccessButton, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (lisaaKayttajaModal.value = true))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Lisää uusi käyttäjä")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_drawer, {
      title: _unref(editedKayttaja) ? 'Muokkaa käyttäjän tietoja' : 'Lisää käyttäjä',
      modelValue: _unref(lisaaKayttajaModal),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(lisaaKayttajaModal) ? (lisaaKayttajaModal).value = $event : null)),
      "destroy-on-close": "",
      onClose: _unref(kayttajaModalClose)
    }, {
      default: _withCtx(() => [
        _createVNode(ComLisaaKayttaja)
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onClose"]),
    _createVNode(_component_el_divider),
    (_unref(filteredOikeudet) && _unref(selectedVastuuyksikot) && _unref(selectedVastuuyksikot).length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_tag, {
                  class: "tag",
                  type: "success"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("L")
                  ])),
                  _: 1
                }),
                _cache[5] || (_cache[5] = _createTextVNode(" = Lukuoikeus "))
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_tag, {
                  class: "tag",
                  type: "success"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("K")
                  ])),
                  _: 1
                }),
                _cache[7] || (_cache[7] = _createTextVNode(" = Kirjoitusoikeus "))
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_el_tag, {
                  class: "tag",
                  type: "success"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("R")
                  ])),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createTextVNode(" = Raportointioikeus "))
              ])
            ]),
            _: 1
          }),
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_4, [
                _cache[10] || (_cache[10] = _createElementVNode("th", {
                  class: "el-table__cell name_coll",
                  style: {"min-width":"200px"}
                }, "Nimi", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedVastuuyksikot), (vastuuyksikko, index) => {
                  return (_openBlock(), _createElementBlock("th", {
                    class: "el-table__cell",
                    style: {"max-width":"200px","min-width":"200px","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","padding-right":"20px"},
                    key: index
                  }, _toDisplayString(vastuuyksikko.nimi), 1))
                }), 128))
              ])
            ]),
            (_unref(filteredOikeudet))
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredOikeudet), (kayttaja, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: "el-table__row",
                      key: index
                    }, [
                      _createElementVNode("td", _hoisted_6, [
                        _createTextVNode(_toDisplayString(kayttaja.etunimi) + " " + _toDisplayString(kayttaja.sukunimi) + " ", 1),
                        _createVNode(ComDeleteButton, {
                          onClick: ($event: any) => (deleteOikeudetClick(kayttaja))
                        }, null, 8, ["onClick"])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selectedVastuuyksikot), (vastuuyksikko, index) => {
                        return (_openBlock(), _createElementBlock("td", {
                          class: "el-table__cell",
                          key: index
                        }, [
                          _createVNode(ComOikeusTableButtons, {
                            kayttaja: kayttaja.id,
                            vastuuyksikko: vastuuyksikko.id,
                            oikeus: _unref(getVastuuyksikkoOikeus)(kayttaja.oikeudet, vastuuyksikko.id),
                            onUpdateOikeus: _unref(updateOikeus),
                            onAddOikeus: _unref(addOikeus),
                            onDeleteOikeus: _unref(deleteOikeus)
                          }, null, 8, ["kayttaja", "vastuuyksikko", "oikeus", "onUpdateOikeus", "onAddOikeus", "onDeleteOikeus"])
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})