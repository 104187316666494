<template>
    <div v-loading="kayttaja == undefined">
        <div v-if="kayttaja">
            <h3>Omat tiedot</h3>
            <el-row>
                <el-col :span="6"><label>Nimi</label></el-col>
                <el-col :span="18">{{ kayttaja.etunimi + " " + kayttaja.sukunimi }}</el-col>
            </el-row>
            <el-row>
                <el-col :span="6"><label>Sähköposti</label></el-col>
                <el-col :span="18">{{ kayttaja.email }}</el-col>
            </el-row>
            <el-row style="padding-top: 20px">
                <el-col :span="24"><el-link @click.stop="changePasswordClick">Vaihda salasanaa</el-link></el-col>
            </el-row>
        </div>
        <div v-else>
            <ComEmpty></ComEmpty>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch } from "vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { useKayttaja, useResetPassword } from "@/methods/kayttajaComposables";
import { ElMessage } from "element-plus";

const { kayttaja } = useKayttaja()
const { resetPassword, response, error } = useResetPassword()

const changePasswordClick = () => {
    resetPassword()
}

watch(response, () => {
    if (response.value) {
        ElMessage.success("Salasanan vaihto onnistui!")
    }
})

watch(error, () => {
    if (error.value) {
        ElMessage.error("Salasanan vaihto epäonnistui!")
    }
})
</script>
