<template>
    <template v-if="huonekortti">
        <ComPageHeader :contentTitle="headerTitle" :contentSubtitle="headerSubtitle">
            <div style="float: right">
                <el-space>
                    <el-badge :value="liitteetCount" :hidden="!liitteetCount">
                        <ComLiitteet :viite="huonekortti.id" :tyyppi="1" />
                    </el-badge>
                    <el-badge :value="kommentitCount" :hidden="!kommentitCount">
                        <ComKommentointi :paakayttaja="paakayttaja ?? false" :viite="huonekortti.id" :tyyppi="1" />
                    </el-badge>
                    <ComHuonekorttiInfoDropDownButton/>
                </el-space>
            </div>
        </ComPageHeader>
        <ComToimintatiedot :data="huonekortti" class="card-margin-bottom"></ComToimintatiedot>
        <ComSuunnittelutiedot></ComSuunnittelutiedot>
        <template v-if="showHanketiedot">
            <el-drawer
                v-model="showHanketiedot"
                class="hanketiedotDrawer"
                append-to-body
                size="50%"
                title="Tiedot"
                destroy-on-close>
                <ComHanketiedot :huonekorttiId="huonekortti.id" :hankeId="hanke?.id" :editable="false" />
            </el-drawer>
        </template>
        <template v-if="showMuutokset">
            <el-drawer
                v-model="showMuutokset"
                size="50%"
                v-if="huonekortti && huonekortti.status == 'Hyvaksytty'"
                title="Historia"
                destroy-on-close>
                <ComHistoria :huonekortti="huonekortti" />
            </el-drawer>
        </template>
    </template>
    <ComCard v-else-if="loading">
        <ComLoading />
    </ComCard>
</template>

<script lang="ts">
export default {
    name: "HuonekorttiView",
}
</script>

<script setup lang="ts">
import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComToimintatiedot from "@/components/huonekortti/ComToimintatiedot.vue"
import ComSuunnittelutiedot from "@/components/huonekortti/ComSuunnittelutiedot.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComHistoria from "@/components/huonekortti/historia/ComHistoria.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiInfoDropDownButton from "@/components/huonekortti/ComHuonekorttiInfoDropDownButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComLiitteet from "@/components/liitteet/ComLiitteet.vue"
import ComCard from "@/components/common/ComCard.vue"
import { huonekorttiPage } from "./huonekortti"

const {
    huonekortti,
    showMuutokset,
    headerTitle,
    headerSubtitle,
    liitteetCount,
    kommentitCount,
    showHanketiedot,
    hanke,
    loading,
    paakayttaja
} = huonekorttiPage()
</script>
<style>
.hanketiedotDrawer {
    overflow-y: scroll !important;
}
.el-drawer__header {
    margin-bottom: 0px !important;
}
.el-descriptions__label:not(.is-bordered-label) {
    width: 200px;
    display: inline-block;
    font-weight: bold;
}
</style>
