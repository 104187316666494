import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { useYllapitoHankeComponent } from "./yllapitoHanke"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComPlusButton from "../common/buttons/ComPlusButton.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeLisaaVastuuyksikko',
  setup(__props) {

const { hanke, addVaastuuyksikko, deleteVaastuuyksikko } = useYllapitoHankeComponent()

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hanke).vastuuyksikot, (vastuuyksikko, index) => {
    return (_openBlock(), _createBlock(_component_el_form_item, {
      key: index,
      prop: 'vastuuyksikko' + index,
      label: 'Vastuuyksikkö ' + (index + 1)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              size: "small",
              placeholder: "Nimi",
              modelValue: vastuuyksikko.nimi,
              "onUpdate:modelValue": ($event: any) => ((vastuuyksikko.nimi) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(_component_el_input, {
              size: "small",
              placeholder: "Vaatimukset",
              modelValue: vastuuyksikko.vaatimukset,
              "onUpdate:modelValue": ($event: any) => ((vastuuyksikko.vaatimukset) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createVNode(ComDeleteButton, {
              onClick: ($event: any) => (_unref(deleteVaastuuyksikko)(index))
            }, null, 8, ["onClick"]),
            (index + 1 == _unref(hanke).vastuuyksikot.length)
              ? (_openBlock(), _createBlock(ComPlusButton, {
                  key: 0,
                  onClick: _unref(addVaastuuyksikko)
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, 1032, ["prop", "label"]))
  }), 128))
}
}

})