<template>
    <el-menu mode="horizontal" :ellipsis="false">
        <el-menu-item index="etusivu" @click="etusivuClick">Etusivu</el-menu-item>
        <el-menu-item index="ohje" @click="ohjeClick">Ohje</el-menu-item>
        <el-menu-item v-if="superUser" @click="yllapitoClick" index="yllapito">Ylläpito</el-menu-item>
        <div style="flex-grow: 1" />
        <el-menu-item index="profiili">
            <ComAvatarButton />
        </el-menu-item>
        <el-menu-item index="kirjaudu">
            <ComLogoutButton>
                <el-icon><SwitchButton /></el-icon>
            </ComLogoutButton>
        </el-menu-item>
    </el-menu>
</template>

<script setup lang="ts">
import ComLogoutButton from "@/components/common/buttons/ComLogoutButton.vue"
import ComAvatarButton from "@/components/common/buttons/ComAvatarButton.vue"
import { SwitchButton } from "@element-plus/icons-vue"
import router from "@/router"
import { useSuperUserOikeudet } from "@/methods/kayttajaOikeusComposables"

const { superUser } = useSuperUserOikeudet()

const etusivuClick = () => {
    router.push({ name: "Etusivu" })
}

const ohjeClick = () => {
    window.open("https://scribehow.com/page/Vahti_ohje__wfWdtIrpRCyPAv802UyY0w", "_blank")
}

const yllapitoClick = () => {
    router.push({ name: "Yllapito" })
}

</script>
