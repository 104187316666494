enum TilaSortEnum {
    id = "id",
    tilatunnus = "tilatunnus",
    pintaAla = "pintaAla",
    tilanimike = "tilanimike",
    tasmennys = "tasmennys",
    vastuuyksikko = "vastuuyksikko",
}

export default TilaSortEnum
