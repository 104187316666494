import { TilaResponse } from "@/api/models/tila-response"
import { computed } from "vue"
import huonekorttiTableStore from "./huonekorttiTableStore"

export function useHuonekorttiTable() {
    const { getters, methods } = huonekorttiTableStore

    const huonekorttiIdsSelected = computed({
        get(): number[] {
            return getters.getSelected()
        },
        set(val: number[]): void {
            methods.setSelected(val)
        },
    })

    const formatTilat = (tilat: TilaResponse[]) => {
        if (tilat != undefined) {
            return tilat
                .map((t: TilaResponse) => {
                    return t.tilatunnus
                })
                .join(", ")
        }
    }

    return {
        huonekorttiIdsSelected,
        formatTilat,
    }
}
