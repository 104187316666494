import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useAuth0 } from "@/auth/auth0-plugin"
import { ElMessage } from "element-plus"
import { ref, watch } from "vue"
import ComCancelButton from "../common/buttons/ComCancelButton.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComCard from "../common/ComCard.vue"
import { useConsestGDPR } from "@/methods/kayttajaComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComKayttoehdotConsest',
  setup(__props) {

const { consestGDPR, error, response } = useConsestGDPR()
const auth0 = useAuth0()
const hyvaksy = ref(false)
const logoutUrl = process.env.VUE_APP_AUTH0_LOGOUT_URL

const hyvaksyClick = async () => {
    if (!hyvaksy.value) {
        ElMessage.warning("Sinun tulee hyväksyä ehdot jatkaaksesi!")
    } else {
        consestGDPR()
    }
}

const hylkaaClick = () => {
    if (!auth0) {
        return
    }

    auth0.logout({
        logoutParams: {
            returnTo: logoutUrl,
        },
    })
}

watch(response, () => {
    if (response.value) {
        window.location.reload()
        ElMessage.success("Käyttöehdot hyväksytty!")
    }
})
watch(error, () => {
    if (error.value) {
        ElMessage.error("Käyttöehtojen hyväksyminen epäonnistui!")
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_openBlock(), _createBlock(ComCard, { header: true }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createTextVNode("Hyväksy käyttöehdot")
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _cache[5] || (_cache[5] = _createTextVNode(" Tiedoksesi, että kirjautumalla järjestelmään hyväksyt sen, että henkilötietosi tallentuvat SSTY:n (Suomen Sairaalatekniikan yhdistys) Vahti -vaatimustenhallintajärjestelmään. Rekisterin tietoja käytetään ainoastaan tässä järjestelmässä. Tietojasi ei käytetä tai myydä kaupalliseen tarkoitukseen. Tallennamme ainoastaa nimesi ja sähköpostiosoitteesi. Tarkemmat tiedot löytyvät rekisteriselosteesta. ")),
        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
        _cache[8] || (_cache[8] = _createElementVNode("a", {
          target: "_blank",
          href: "https://storage.googleapis.com/vahti_images/Rekisteriseloste.pdf"
        }, " Rekisteriseloste ", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _cache[11] || (_cache[11] = _createTextVNode(" - Sinut kirjataan ulos jos et hyväksy käyttöehtoja. ")),
        _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
        _cache[13] || (_cache[13] = _createTextVNode(" - Mikäli, et halua tietojasi tallentuvan järjestelmään, ota yhteys hankkeesi vastuuhenkilöön. ")),
        _createVNode(_component_el_divider),
        _createElementVNode("div", null, [
          _createVNode(_component_el_checkbox, {
            modelValue: hyvaksy.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hyvaksy).value = $event))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Hyväksyn ehdot")
            ])),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createVNode(_component_el_space, null, {
          default: _withCtx(() => [
            _createVNode(ComSuccessButton, { onClick: hyvaksyClick }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Hyväksyn")
              ])),
              _: 1
            }),
            _createVNode(ComCancelButton, { onClick: hylkaaClick }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("En hyväksy")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})