<template>
    <el-row>
        <el-col :span="8" :offset="8" style="text-align: center; margin-bottom: 40px">
            <el-image :src="require('@/assets/vahtilogo.png')"></el-image>
        </el-col>
    </el-row>
    <el-col :span="8" :offset="8">
        <el-card>
            <h1>Vahti Vaatimustenhallintajärjestelmä</h1>
            <p>Tämä on Vahti- järjestelmän kirjautumissivu.</p>
            <p>
                Vahti on järjestelmä, jota käytetään terveydenhuollon rakennushankkeissa. Vahti ohjaa ja opastaa
                käyttäjää kirjaamaan toiminnan tiloille aiheuttamat tarpeet eli vaatimukset.
            </p>
            <p>
                Vahdissa tilanimikkeistö on yhtenäistetty sairaanhoitopiirien käytössä olevien tilanimikkeiden pohjalta
                ja suunnittelualakohtainen sisältö noudattaa vakiintunutta nimikkeistöä.
            </p>
            <p>
                Tiedot tallennetaan tietokantaan, mikä mahdollistaa monipuoliset hakutoiminnot sekä raportoinnin ja
                vertailun.
            </p>
            <p>
                Vahti-järjestelmän omistaa Suomen sairaalatekniikan yhdistys ry (SSTY ry) Järjestelmän toteutuksesta ja
                ylläpidosta vastaa Datasund Oy yhteyshenkilönä toimii Jani Saine, Datasund Oy
            </p>
            <p>jani.saine[ät]tietosund.fi</p>
            <p><ComLoginButton /></p>
        </el-card>
    </el-col>
</template>

<script lang="ts">
import ComLoginButton from "@/components/common/buttons/ComLoginButton.vue"
import { defineComponent, onMounted } from "vue"

export default defineComponent({
    components: { ComLoginButton },
    name: "KirjauduView",
    setup() {
        onMounted(() => {
            return
        })

        return {}
    },
})
</script>
