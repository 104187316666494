<template>
    <el-form label-width="80px">
        <el-form-item label="Nimi">
            <el-input v-model="liite.nimi" />
        </el-form-item>
        <el-form-item label="Seloste">
            <el-input type="textarea" v-model="liite.seloste" />
        </el-form-item>
        <el-form-item label="Tiedosto">
            <ComUploadFile style="width: 100%" drag v-model="file" />
        </el-form-item>
        <el-form-item>
            <ComSuccessButton @click="addFile">Lisää liite</ComSuccessButton>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { LiiteRequest } from "@/api/models/liite-request"
import { ElMessage, UploadUserFile } from "element-plus"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComUploadFile from "../common/ComUploadFile.vue"
import { useAddLiite } from "@/methods/liiteComposables"
import { useLiiteComponent } from "./liite"

const { addLiite, loading, error, response } = useAddLiite()
const { tyyppi, viite, liitteet } = useLiiteComponent()
const liite = ref<LiiteRequest>({} as LiiteRequest)
const file = ref<UploadUserFile[]>([])

const addFile = async () => {
    if (liitteet.value && liitteet.value.length > 4) {
        ElMessage.error("Maksimissaan 5 liitettä per huonekortti!")
    } else if (!file.value[0]) {
        ElMessage.error("Valitse tiedosto")
    } else if (file.value[0].size && file.value[0].size > 5000000){
        ElMessage.error("Tiedosto on liian suuri")
    } else if (viite.value && tyyppi.value && file.value[0].raw) {
        liite.value.tyyppi = { id: tyyppi.value }
        liite.value.viite = { id: viite.value }

        addLiite(file.value[0].raw, liite.value)
    }
}

watch(loading, () => {
    if (response.value) {
        ElMessage.success("Liite lisätty")

        liite.value = {}
        file.value = []
    }
})

watch(error, () => {
    if (error.value) {
        ElMessage.error("Liitteen lisäys epäonnistui")
    }
})

</script>
