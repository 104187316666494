<template>
    <div class="side-menu-container hidden-xs-only" :style="{ width: width + 'px' }">
        <div class="side-menu-header">
            <el-image :src="require('@/assets/vahtilogo.png')" class="side-menu-logo" />
            <div class="side-menu-expand">
                <ComExpand :way="Orientation.horizontal" :show="expand" @expand="expandMenu" />
            </div>
        </div>
        <div><label>Hankkeet</label></div>
        <el-tree :data="hankkeet" node-key="id" :props="props" class="side-menu-tree" :highlight-current="false">
            <template #default="{ node, data }">
                <template v-if="node.level === 2">
                    <span :class="{ 'arkistoitu-text': node.parent.data.arkistoitu }" style="overflow: hidden">
                        <ComSideMenuVastuuyksikko :data="data" :checkbox="false" />
                    </span>
                </template>
                <template v-else>
                    <div class="side-menu-hanke" :class="{ 'arkistoitu-text': data.arkistoitu }">
                        <ComTooltip :content="data.nimi">
                            <span class="node_title">{{ data.nimi }}</span>
                        </ComTooltip>
                        <el-icon @click.stop="navigate(data.id)" class="side-menu-hanke-icon">
                            <DocumentCopy></DocumentCopy>
                        </el-icon>
                    </div>
                </template>
            </template>
        </el-tree>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import ComSideMenuVastuuyksikko from "./ComSideMenuVastuuyksikko.vue"
import ComExpand from "@/components/common/ComExpand.vue"
import { DocumentCopy } from "@element-plus/icons-vue"
import router from "@/router"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHankkeet } from "@/methods/hankeComposables"
import Orientation from "@/types/OrientationEnum"

const props = {
    children: "vastuuyksikot",
    label: "nimi",
}
const { hankkeet } = useHankkeet()
const expand = ref(false)
const width = ref(280)

const expandMenu = () => {
    expand.value = !expand.value
    width.value = expand.value ? 480 : 280
}

const navigate = (id: number) => {
    router.push({ name: "Hanke", params: { hankeId: id } })
}

</script>
<style scoped>
.side-menu-tree >>> .el-tree-node__content {
    height: auto !important;
    align-items: flex-start;
    flex-wrap: wrap;
}
.break {
    flex-basis: 100%;
    height: 0;
}
.side-menu-tree >>> .el-tree-node__expand-icon.is-leaf {
    display: none;
}
.side-menu-logo {
    width: 150px;
    margin: 0px 10px;
}
.side-menu-container {
    padding: 0px 10px;
}
.side-menu-header {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.side-menu-expand {
    margin-left: auto;
    order: 2;
}
</style>
