<template>
    <el-button type="info" size="small" circle>
        <ComTooltip content="Kommentoi">
            <el-icon>
                <ChatRound />
            </el-icon>
        </ComTooltip>
    </el-button>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ChatRound } from "@element-plus/icons-vue"
import ComTooltip from "../ComTooltip.vue"

export default defineComponent({
    components: {
        ChatRound,
        ComTooltip,
    },
})
</script>
