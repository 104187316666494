import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { useHanke } from "@/methods/hankeComposables"
import { useHuonekortti } from "@/methods/huonekorttiComposablses"
import { computed, ref } from "vue"

const state = ref({
    huonekorttiId : undefined as number | undefined,
    hankeId : undefined as number | undefined,
    editable : false as boolean,
    vastuuyksikot : undefined as VastuuyksikkoResponse[] | undefined
})

export function useHanketiedotComponent() {
    const huonekorttiId = computed({
        get: () => state.value.huonekorttiId,
        set: (value) => {
            state.value.huonekorttiId = value
        }
    })
    const hankeId = computed({
        get: () => state.value.hankeId,
        set: (value) => {
            state.value.hankeId = value
        }
    })
    const editable = computed({
        get: () => state.value.editable,
        set: (value) => {
            state.value.editable = value
        }
    })
    const vastuuyksikot = computed({
        get: () => state.value.vastuuyksikot,
        set: (value) => {
            state.value.vastuuyksikot = value
        }
    })

    const { huonekortti, loading : huonekorttiLoading, vastuuyksikko, tilatString: tilat } = useHuonekortti(huonekorttiId)
    const { hanke, loading : hankeLoading, mutate : reloadHanke } = useHanke(hankeId)

    return {
        huonekortti,
        hanke,
        hankeId,
        huonekorttiId,
        vastuuyksikko,
        vastuuyksikot,
        editable,
        hankeLoading,
        huonekorttiLoading,
        tilat,
        reloadHanke
    }
}