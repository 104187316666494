import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

import ComLogoutButton from "@/components/common/buttons/ComLogoutButton.vue"
import ComAvatarButton from "@/components/common/buttons/ComAvatarButton.vue"
import { SwitchButton } from "@element-plus/icons-vue"
import router from "@/router"
import { useSuperUserOikeudet } from "@/methods/kayttajaOikeusComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHeaderMenu',
  setup(__props) {

const { superUser } = useSuperUserOikeudet()

const etusivuClick = () => {
    router.push({ name: "Etusivu" })
}

const ohjeClick = () => {
    window.open("https://scribehow.com/page/Vahti_ohje__wfWdtIrpRCyPAv802UyY0w", "_blank")
}

const yllapitoClick = () => {
    router.push({ name: "Yllapito" })
}


return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    mode: "horizontal",
    ellipsis: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu_item, {
        index: "etusivu",
        onClick: etusivuClick
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Etusivu")
        ])),
        _: 1
      }),
      _createVNode(_component_el_menu_item, {
        index: "ohje",
        onClick: ohjeClick
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Ohje")
        ])),
        _: 1
      }),
      (_unref(superUser))
        ? (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 0,
            onClick: yllapitoClick,
            index: "yllapito"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Ylläpito")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"flex-grow":"1"} }, null, -1)),
      _createVNode(_component_el_menu_item, { index: "profiili" }, {
        default: _withCtx(() => [
          _createVNode(ComAvatarButton)
        ]),
        _: 1
      }),
      _createVNode(_component_el_menu_item, { index: "kirjaudu" }, {
        default: _withCtx(() => [
          _createVNode(ComLogoutButton, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_unref(SwitchButton))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})