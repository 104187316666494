<template>
    <div class="oikeus-tag-wrapper">
        <el-tag class="oikeus-tag" :type="'success'">Luku</el-tag>
    </div>
    <div class="oikeus-tag-wrapper">
        <el-tag class="oikeus-tag" @click="oikeusClick('kirjoitus')" :type="oikeudet.kirjoitus ? 'success' : 'danger'">
            Kirjoitus
        </el-tag>
    </div>
    <div class="oikeus-tag-wrapper">
        <el-tag
            class="oikeus-tag"
            @click="oikeusClick('raportointi')"
            :type="oikeudet.raportointi ? 'success' : 'danger'">
            Raportointi
        </el-tag>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { OikeusProperties } from "@/api/models/oikeus-properties"

export default defineComponent({
    emits: ["oikeusChange"],
    props: {
        oikeudet: {
            type: Object as PropType<OikeusProperties>,
            default: (val: OikeusProperties) => {
                val.luku = true
                val.kirjoitus = false
                val.raportointi = false
            },
        },
    },
    setup(props, { emit }) {
        const oikeusClick = (type: string) => {
            const oikeudet = props.oikeudet

            if (type == "kirjoitus") {
                if (!oikeudet.kirjoitus) {
                    oikeudet.kirjoitus = true
                    oikeudet.raportointi = true
                } else {
                    oikeudet.kirjoitus = false
                }
            }
            if (type == "raportointi") {
                oikeudet.raportointi = !oikeudet.raportointi
            }

            emit("oikeusChange", oikeudet)
        }

        return {
            oikeusClick,
        }
    },
})
</script>
