import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx } from "vue"

import ComSelect from "../../common/ComSelect.vue"
import { useComTuonti } from "./tuonti";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComVastuuyksikkoValinta',
  setup(__props) {

const { vastuuyksikot, vastuuyksikko } = useComTuonti()


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!

  return (_openBlock(), _createBlock(ComSelect, {
    modelValue: _unref(vastuuyksikko),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(vastuuyksikko) ? (vastuuyksikko).value = $event : null))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vastuuyksikot), (vastuuyksikko) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: vastuuyksikko.id,
          label: vastuuyksikko.nimi,
          value: vastuuyksikko.id
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})