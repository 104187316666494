<template>
    <el-dropdown v-if="!readOnly" class="cursor">
        <ComStatusTag :status="modelValue" />
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item
                    v-if="modelValue == HuonekorttiStatusResponse.Tyo"
                    @click="julkaiseHuonekortti(huonekortti.id)">
                    Julkaise
                </el-dropdown-item>
                <template v-if="modelValue == HuonekorttiStatusResponse.Julkaistu">
                    <el-dropdown-item v-if="paakayttaja" @click="hyvaksyHuonekortti(huonekortti.id)">
                        Hyväksy
                    </el-dropdown-item>
                    <el-dropdown-item v-else @click="hyvaksytaHuonekortti(huonekortti.id)">
                        Lähetä hyväksyttäväksi
                    </el-dropdown-item>
                </template>
                <template v-if="modelValue == HuonekorttiStatusResponse.Odottaa">
                    <template v-if="paakayttaja">
                        <el-dropdown-item @click="hyvaksyHuonekortti(huonekortti.id)">Hyväksy</el-dropdown-item>
                        <el-dropdown-item @click="hylkaaHuonekortti(huonekortti.id)">Hylkää</el-dropdown-item>
                    </template>
                    <template v-else>
                        <el-dropdown-item @click="hylkaaHuonekortti(huonekortti.id)">Peruuta</el-dropdown-item>
                    </template>
                </template>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
    <ComStatusTag v-else :status="modelValue" />
</template>

<script setup lang="ts">
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComStatusTag from "@/components/common/ComStatusTag.vue"
import { computed, watch } from "vue"
import { huonekorttiListing } from "./huonekortit"
import {
    useHylkaaHuonekortti,
    useHyvaksyHuonekortti,
    useHyvaksytaHuonekortti,
    useJulkaiseHuonekortti,
} from "@/methods/huonekorttiComposablses"

const props = defineProps<{
    modelValue: HuonekorttiStatusResponse
    huonekortti: HuonekorttiResponse
}>()
const { writableVastuuyksikot, paakayttaja, updateHuonekortit } = huonekorttiListing()
const {
    julkaiseHuonekortti,
    error: julkaiseError,
    loading: julkaiseLoading,
    response: julkaiseResponse,
} = useJulkaiseHuonekortti()
const {
    hylkaaHuonekortti,
    error: hylkaaError,
    loading: hylkaaLoading,
    response: hylkaaResponse,
} = useHylkaaHuonekortti()
const {
    hyvaksyHuonekortti,
    error: hyvaksyError,
    loading: hyvaksyLoading,
    response: hyvaksyResponse,
} = useHyvaksyHuonekortti()
const {
    hyvaksytaHuonekortti,
    error: hyvaksytaError,
    loading: hyvaksytaLoading,
    response: hyvaksytaResponse,
} = useHyvaksytaHuonekortti()

const readOnly = computed(() => {
    return writableVastuuyksikot.value.find((o) => o.id == props.huonekortti.vastuuyksikko.id) ? false : true
})

watch(julkaiseLoading, (val) => {
    if (!val && julkaiseResponse.value) {
        updateHuonekortit()
    }
})
watch(hylkaaLoading, (val) => {
    if (!val && hylkaaResponse.value) {
        updateHuonekortit()
    }
})
watch(hyvaksyLoading, (val) => {
    if (!val && hyvaksyResponse.value) {
        updateHuonekortit()
    }
})
watch(hyvaksytaLoading, (val) => {
    if (!val && hyvaksytaResponse.value) {
        updateHuonekortit()
    }
})
</script>
<style scoped>
.cursor {
    cursor: pointer;
}
</style>
