import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import { watch } from "vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { useKayttaja, useResetPassword } from "@/methods/kayttajaComposables";
import { ElMessage } from "element-plus";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComAvatar',
  setup(__props) {

const { kayttaja } = useKayttaja()
const { resetPassword, response, error } = useResetPassword()

const changePasswordClick = () => {
    resetPassword()
}

watch(response, () => {
    if (response.value) {
        ElMessage.success("Salasanan vaihto onnistui!")
    }
})

watch(error, () => {
    if (error.value) {
        ElMessage.error("Salasanan vaihto epäonnistui!")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_link = _resolveComponent("el-link")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    (_unref(kayttaja))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Omat tiedot", -1)),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 6 }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("label", null, "Nimi", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 18 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(kayttaja).etunimi + " " + _unref(kayttaja).sukunimi), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 6 }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("label", null, "Sähköposti", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 18 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(kayttaja).email), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { style: {"padding-top":"20px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_link, {
                    onClick: _withModifiers(changePasswordClick, ["stop"])
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Vaihda salasanaa")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(ComEmpty)
        ]))
  ])), [
    [_directive_loading, _unref(kayttaja) == undefined]
  ])
}
}

})