<template>
    <ComLoading v-if="loading" />
    <div v-else>
        <el-row>
            <el-col :span="6">
                <ComFilterInput v-model="filter" />
            </el-col>
            <el-col :span="18">
                <ComPagination
                    :total="pageTotal"
                    v-model:page-size="pageSize"
                    v-model:current-page="currentPage"
                    style="float: right" />
            </el-col>
        </el-row>
        <el-table :data="tilat" show-summary @sort-change="sortChanged" :summary-method="getSum" ref="tableRef">
            <el-table-column prop="tilatunnus" label="Tilatunnus" sortable="custom" width="120">
                <template #default="scope">
                    <router-link
                        :to="{ name: 'Huonekortti', params: { huonekorttiId: scope.row.huonekortti.id } }"
                        v-slot="{ href, navigate }"
                        custom>
                        <el-link type="primary" :href="href" @click="navigate">{{ scope.row.tilatunnus }}</el-link>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column prop="pintaAla" label="Pinta-ala" sortable="custom" width="120"></el-table-column>
            <el-table-column
                prop="huonekortti.tilanimike.nimi"
                label="Tilanimike"
                sortable="custom"
                width="200"></el-table-column>
            <el-table-column prop="vastuuyksikko.nimi" label="Vastuuyksikkö" sortable="custom" show-overflow-tooltip>
                <template #default="scope">
                    {{ vastuuyksikko(scope.row.vastuuyksikko.id) }}
                </template>
            </el-table-column>
            <el-table-column prop="huonekortti.toiminta" label="Täsmennys" sortable="custom" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
</template>


<script setup lang="ts">
import ComPagination from '@/components/common/ComPagination.vue';
import { tilaListing } from './tilat';
import ComFilterInput from '@/components/common/ComFilterInput.vue';
import ComLoading from '@/components/common/ComLoading.vue';

const {tilat, filter, loading, pageTotal, currentPage, pageSize, tableRef, sortChanged, getSum, vastuuyksikko } = tilaListing();
</script>
<style scoped>
:deep(.el-table .el-table__footer .cell) {
    white-space: pre-line;
}
</style>
