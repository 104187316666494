<template>
    <App />
</template>

<script lang="ts">
import App from "@/App.vue"
import { provideAuth0 } from "./auth/auth0-plugin"
export default {
    name: "ShellView",
    components: { App },
    setup(): void {
        provideAuth0()
    },
}
</script>
