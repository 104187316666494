import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useUpdateHanke } from '@/methods/hankeComposables';
import { useHanketiedotComponent } from './hanketiedot';
import { HankeRequest } from '@/api/models/hanke-request';
import { ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHanketiedotHankeEdit',
  props: {
    "modelValue": {type: Boolean},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const edit = _useModel(__props, "modelValue")
const { hanke } = useHanketiedotComponent()
const { response, error, updateHanke } = useUpdateHanke()
const hankeRequest = ref<HankeRequest>({} as HankeRequest)

const tallennaHankeClick = async () => {
    if (hanke.value && hankeRequest.value) {
        await updateHanke(hanke.value.id, hankeRequest.value)
    }
}

watch(hanke, (value) => {
    if (value && edit.value) {
        hankeRequest.value = Object.assign({} as HankeRequest, value)
    }
})
watch(() => response.value, (value) => {
    if (value) {
        ElMessage.success('Hankkeen tiedot tallennettu')
        edit.value = false
    }
})
watch(() => error.value, (value) => {
    if (value) {
        ElMessage.error('Hankkeen tietojen tallennus epäonnistui')
    }
})



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    modelValue: hankeRequest.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((hankeRequest).value = $event)),
    "label-width": "150px",
    "label-position": "left"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Hankkeen nimi" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: hankeRequest.value.nimi,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hankeRequest.value.nimi) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Hankenumero" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: hankeRequest.value.hankenumero,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((hankeRequest.value.hankenumero) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Hankkeen työnumero" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: hankeRequest.value.tyonumero,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hankeRequest.value.tyonumero) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Organisaatio" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_unref(hanke)?.sairaala?.nimi), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_space, null, {
            default: _withCtx(() => [
              _createVNode(ComSuccessButton, { onClick: tallennaHankeClick }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Tallenna")
                ])),
                _: 1
              }),
              _createVNode(ComCancelButton, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (edit.value = false))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Peruuta")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})