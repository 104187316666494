import { computed, nextTick, ref, watch, watchEffect } from "vue"
import { ColumnSortParams, Sort, TableColumnCtx, TableInstance } from "element-plus"
import { TilaResponseExtended } from "@/api/models/tila-response-extended"
import TilaSort from "@/types/TilaSort"
import TilaSortEnum from "@/types/TilaSortEnum"
import OrderEnum from "@/types/OrderEnum"
import { hankePage } from "@/views/hanke"
import { useTilat } from "@/methods/tilaComposables"
import { useLocalStorage } from "@vueuse/core"

interface perHanke {
    id: number
    filter: string
    page: number
}

interface state {
    perHanke: perHanke[],
    sort: TilaSort
}

const state = ref<state>({
    perHanke: [],
    sort: { order: OrderEnum.asc, prop: TilaSortEnum.id },
})

export function tilaListing() {
    interface SummaryMethodProps<T = TilaResponseExtended> {
        columns: TableColumnCtx<T>[]
        data: T[]
    }

    const { selectedVastuuyksikkoIds, hankeVastuuyksikot: vastuuyksikot, id } = hankePage()
    const { tilat, loading, pageTotal, pintaAlaTotal, paramsObject, setAllFilters} = useTilat(id)
    const tableRef = ref<TableInstance>()
    const pageSize = useLocalStorage("huonekorttiPageSize", 30)
    const filter = computed({
        get: () => state.value?.perHanke.find((h) => h.id === id.value)?.filter ?? "",
        set: (val) => {
            const index = state.value?.perHanke.findIndex((h) => h.id === id.value) ?? -1

            if (index === -1) {
                addState(val)
            } else {
                state.value.perHanke[index].filter = val
            }
        },
    })
    const currentPage = computed({
        get: () => state.value?.perHanke.find((h) => h.id === id.value)?.page ?? 1,
        set: (val) => {
            const index = state.value?.perHanke.findIndex((h) => h.id === id.value) ?? -1

            if (index === -1) {
                addState(undefined, val)
            } else {
                state.value.perHanke[index].page = val
            }
        },
    })
    const sort = computed({
        get: () => state.value?.sort ?? { order: OrderEnum.asc, prop: TilaSortEnum.id },
        set: (val) => {
            if(!val.order) {
                state.value.sort = { order: OrderEnum.asc, prop: TilaSortEnum.id }
            }
            else {
                state.value.sort = { order: val.order, prop: val.prop}
            }
        }
    })

    const addState = (filter?: string, page?: number) => {
        state.value.perHanke.push({
            id: id.value,
            filter: filter ?? "",
            page: page ?? 1,
        })
    }
    const getSum = (params: SummaryMethodProps) => {
        const { columns, data } = params
        const sums = [] as string[]
        const pageSum = data.flatMap((t) => t.pintaAla ?? 0).reduce((a, c) => a + c, 0)
    
        columns.forEach((v, i) => {
            if (i === 0) {
                sums[i] = `Pinta-ala:`
            } else if (i === 1) {
                sums[i] = `Sivu:\n${pageSum.toFixed(2)} m2`
            } else if (i === 2) {
                sums[i] = `Valinnat yhteensä:\n${pintaAlaTotal.value.toFixed(2)} m2`
            } else {
                sums[i] = ""
            }
        })
    
        return sums
    }
    
    const vastuuyksikko = (id: number) => {
        return vastuuyksikot.value?.find((v) => v.id === id)?.nimi
    }
    
    const sortChanged = (val : {column: any, prop: TilaSortEnum, order: OrderEnum }) => {
        sort.value = { order: val.order, prop: val.prop }
    }

    watchEffect(() => {
        paramsObject.value.paging = {
            currentPage: currentPage.value,
            pageSize: pageSize.value,
        }
        paramsObject.value.vastuuyksikot = selectedVastuuyksikkoIds.value
        paramsObject.value.sort = sort.value.prop
        paramsObject.value.order = sort.value.order

        setAllFilters(filter.value)
    })

    watch(id, () => {
        if (id.value) {
            tableRef.value?.sort(sort.value.prop, sort.value.order.toString())
        }
    })
    
    watch(filter, () => {
        currentPage.value = 1
    })

    return {
        tilat,
        filter,
        sort,
        pageTotal,
        currentPage,
        pageSize,
        loading,
        tableRef,
        vastuuyksikko,
        getSum,
        sortChanged
    }
}