import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Etusivu from "../views/Etusivu.vue"
import Huonekortti from "../views/Huonekortti.vue"
import Hanke from "../views/Hanke.vue"
import Huolto from "../views/Huolto.vue"
import PageNotFound from "@/errors/PageNotFound.vue"
import {
    authenticationGuard,
    KayttoehdotAuthenticationGuard,
    loginAuthenticationGuard,
} from "@/auth/authentication-guard"
import Kirjaudu from "@/views/Kirjaudu.vue"
import Kayttoehdot from "@/views/Kayttoehdot.vue"
import Yllapito from "@/views/Yllapito.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Etusivu",
        component: Etusivu,
        beforeEnter: authenticationGuard,
    },
    {
        path: "/kirjaudu",
        name: "Kirjaudu",
        component: Kirjaudu,
        beforeEnter: loginAuthenticationGuard,
    },
    {
        path: "/kayttoehdot",
        name: "Kayttoehdot",
        component: Kayttoehdot,
        beforeEnter: KayttoehdotAuthenticationGuard,
    },
    {
        path: "/huonekortti/:huonekorttiId",
        name: "Huonekortti",
        component: Huonekortti,
        beforeEnter: authenticationGuard,
    },
    {
        path: "/hanke/:hankeId",
        name: "Hanke",
        props: ({ params, query }) => {
            return {
                hankeId: !Array.isArray(params.hankeId) ? Number.parseInt(params.hankeId, 10) || 0 : 0,
                tab: query.tab ? Number.parseInt(query.tab.toString(), 10) || 0 : 0,
                vastuuyksikot: query.vastuuyksikot
                    ? query.vastuuyksikot
                          ?.toString()
                          .split(",")
                          .map((v) => Number.parseInt(v))
                    : null,
                huonekorttiParams: query.huonekorttiParams ? JSON.parse(query.huonekorttiParams.toString()) : null,
                tilaParams: query.tilaParams ? JSON.parse(query.tilaParams.toString()) : null,
            }
        },
        component: Hanke,
        beforeEnter: authenticationGuard,
    },
    {
        path: "/huolto",
        name: "Huolto",
        component: Huolto,
    },
    {
        path: "/yllapito",
        name: "Yllapito",
        component: Yllapito,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
        beforeEnter: authenticationGuard,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
