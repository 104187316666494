import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComOhjePohja = _resolveComponent("ComOhjePohja")!

  return (_openBlock(), _createBlock(_component_ComOhjePohja, { title: 'Sähköluokat' }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", null, [
        _createElementVNode("b", null, "G2"),
        _createElementVNode("p", null, " Lääkintätila, jossa lääkintälaitetta käytetään sydämenläheisiin toimintoihin ja/tai jossa lääkintälaitteen rikkoontuminen saattaa aiheuttaa potilaalle välittömän hengenvaaran (leikkaussalit, tehohoito jne.); Tilat suojataan IT-sähkönjakelujärjestelmällä, sekä niihin liittyvällä hälytysjärjestelmällä (EV-MEV = eristys valvontalaitteisto) "),
        _createElementVNode("b", null, "G1"),
        _createElementVNode("p", null, " Lääkintätila, jossa käytetään sähkökäyttöisiä lääkintälaitteita (vastaanottohuoneet, potilashuoneet, potilasvessat) Tilat suojataan vikavirtasuojakytkimillä ja lisäpotentiaalintasauksella. "),
        _createElementVNode("b", null, "G0"),
        _createElementVNode("p", null, " Tila, jossa ei käytetä sähkökäyttöisiä lääkintälaitteita (toimistot, varastot, yleiset WC:t jne.) Tilat suojataan vikavirtasuojakytkimillä ")
      ], -1)
    ])),
    _: 1
  }))
}