import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { createGlobalState } from "@vueuse/core"
import { ref } from "vue"
import http from "@/http-common"

const kayttajaState = createGlobalState(async () => {
    const kayttaja = ref<KayttajaResponse>()
    const kayttoehdot = ref<boolean>(false)

    const loadKayttaja = async () => {
        const response = await http.get("kayttajat/kirjautunut")

        if (response) {
            kayttaja.value = response.data
        }
    }

    if (!kayttaja.value) {
        await loadKayttaja()
    }

    if (kayttaja.value) {
        kayttoehdot.value = kayttaja.value.ehdot ?? false
    }

    return {
        kayttaja,
        kayttoehdot,
    }
})


export default {
    kayttajaState,
}
