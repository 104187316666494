<template>
    <el-form label-width="100px">
        <el-form-item label="Kommentti">
            <el-input type="textarea" v-model="kommentti.kommentti"></el-input>
        </el-form-item>
        <el-space direction="vertical" fill>
            <el-alert type="info" show-icon :closable="false">
                Alla olevasta kentästä voit lähettää valituille käyttäjille sähköpostiviestin kommentista. Hae ja
                valitse haluamasi käyttäjät sähköpostin perusteella.
            </el-alert>
            <el-form-item label="Käyttäjät">
                <ComNotifyKommentti @valitutKayttajat="valitutKayttajatChange"></ComNotifyKommentti>
            </el-form-item>
        </el-space>
        <el-form-item>
            <ComSuccessButton @click="addCommentClick">Kommentoi</ComSuccessButton>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { KommenttiRequest } from "@/api/models/kommentti-request"
import { ref, watch } from "vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComNotifyKommentti from "./ComNotifyKommentti.vue"
import { useAddKommentti } from "@/methods/kommenttiComposables"
import { useKommentitComponent } from "./kommentit"
import { ElMessage } from "element-plus"

const { addKommentti, error, response } = useAddKommentti()
const { tyyppi, viite } = useKommentitComponent()
const kommentti = ref<KommenttiRequest>({} as KommenttiRequest)

const addCommentClick = () => {
    if(tyyppi.value && viite.value) {
        kommentti.value.tyyppi = { id: tyyppi.value }
        kommentti.value.viite = { id: viite.value }

        if(!kommentti.value.huomio) {
            kommentti.value.huomio = []
        }

        addKommentti(kommentti.value)
    }
}

const valitutKayttajatChange = (val: KayttajaResponse[] | undefined) => {
    if (val) {
        kommentti.value.huomio = val.map((v) => {
            return { id: v.id }
        })
    }
}

watch(error, (val) => {
    if(val) {
        ElMessage.error("Kommentin lisääminen epäonnistui")
    }
})
watch(response, (val) => {
    if(val) {
        ElMessage.success("Kommentti lisätty")

        kommentti.value.kommentti = ""
    }
})

</script>
