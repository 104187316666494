<template>
    <el-card v-show="!loading">
        <template #header v-if="header && !empty">
            <slot name="header"></slot>
        </template>
        <template v-if="!empty">
            <slot></slot>
        </template>
        <ComEmpty v-else />
    </el-card>
    <el-card v-show="loading">
        <ComLoading/>
    </el-card>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ComEmpty from "./ComEmpty.vue"
import ComLoading from "./ComLoading.vue"

export default defineComponent({
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Boolean,
            default: false,
        },
    },
    components: { ComEmpty, ComLoading },
})
</script>
