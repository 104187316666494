import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

import { HankeRequest } from "@/api/models/hanke-request"
import { ElMessage, FormInstance, FormRules } from "element-plus"
import { ref, watch } from "vue"
import ComLoading from "../common/ComLoading.vue"
import ComYllapitoHankeLisaaVastuuyksikko from "./ComYllapitoHankeLisaaVastuuyksikko.vue"
import { useSairaalat } from "@/methods/sairaalaComposables"
import { useYllapitoHankeComponent } from "./yllapitoHanke"
import { useAddHanke } from "@/methods/hankeComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeLisaa',
  setup(__props) {

const { sairaalat } = useSairaalat()
const { hanke, formatHanke } = useYllapitoHankeComponent()
const { addHanke, error, response } = useAddHanke()

const ruleFormRef = ref<FormInstance>()
const rules = ref<FormRules<HankeRequest>>({
    nimi: [{ required: true, message: "Nimi vaaditaan", trigger: "blur" }],
    sairaala: [{ required: true, message: "Sairaala vaaditaan", trigger: "blur" }],
})

const addHankeClick = async () => {
    await ruleFormRef.value?.validate((valid) => {
        if (!valid) {
            return
        } else {
            addHanke(hanke.value)
        }
    })
}

watch(error, () => {
    ElMessage.error("Hankeen lisäys epäonnistui")
})

watch(response, () => {
    if(response.value) {
        formatHanke()
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(sairaalat))
      ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
      : (_openBlock(), _createBlock(_component_el_form, {
          key: 1,
          ref_key: "ruleFormRef",
          ref: ruleFormRef,
          model: _unref(hanke),
          rules: rules.value
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Nimi",
              prop: "nimi"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "small",
                  modelValue: _unref(hanke).nimi,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(hanke).nimi) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Vaatimukset",
              prop: "vaatimukset"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "small",
                  modelValue: _unref(hanke).vaatimukset,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(hanke).vaatimukset) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Hankenumero",
              prop: "hankenumero"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "small",
                  modelValue: _unref(hanke).hankenumero,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(hanke).hankenumero) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Työnumero",
              prop: "tyonumero"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  size: "small",
                  modelValue: _unref(hanke).tyonumero,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(hanke).tyonumero) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Sairaala",
              prop: "sairaala"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  size: "small",
                  modelValue: _unref(hanke).sairaala,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(hanke).sairaala) = $event)),
                  "value-key": "id"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sairaalat), (sairaala) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: sairaala.id,
                        label: sairaala.nimi,
                        value: sairaala
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(ComYllapitoHankeLisaaVastuuyksikko),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: addHankeClick
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Lisää")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"]))
  ]))
}
}

})