import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { Ref, computed, ref, watch } from "vue"
import HuonekorttiListingParams from "@/types/HuonekorttiListingParams"
import http from "@/http-common"
import { useQuery } from "@tanstack/vue-query"
import { AxiosResponseHeaders } from "axios"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"

export function useHuonekortit(val?: Ref<HuonekorttiListingParams>) {
    const huonekortit = ref<HuonekorttiResponse[]>([])
    const total = ref(0)
    const key = ref<any[]>([])
    const paramsObject =
        val ??
        ref<HuonekorttiListingParams>({
            paging: {
                pageSize: 30,
                currentPage: 1,
            },
            sort: undefined,
            order: undefined,
            vastuuyksikot: [],
            nimikkeet: false,
            tilanimike: "",
            tilanimikeTasmennys: "",
            tilatunnus: "",
            status: [],
            poistettu: undefined,
        })
    const filterAll = ref<string>("")
    const enabled = computed(() => key.value.length != 0 && paramsObject.value.vastuuyksikot?.length !== 0)
    const currentPage = computed({
        get: () => paramsObject.value.paging?.currentPage || 1,
        set: (val: number) => {
            if(paramsObject.value.paging) {
                paramsObject.value.paging.currentPage = val
            }
        },
    })
    const pageSize = computed({
        get: () => paramsObject.value.paging?.pageSize || 30,
        set: (val: number) => {
            if(paramsObject.value.paging) {
                paramsObject.value.paging.pageSize = val
            }
        },
    })
    const filter = computed({
        get: () => filterAll.value,
        set: (val: string) => {
            filterAll.value = val
            setAllFilters(val)
        },
    })
    const status = computed({
        get: () => paramsObject.value.status,
        set: (val?: HuonekorttiStatusResponse[]) => {
            paramsObject.value.status = val ?? []
        },
    })

    const getUrlParams = () => {
        let order = ""

        if(paramsObject.value.order) {
            if (paramsObject.value.order.toString() === "ascending") {
                order = "asc"
            } else if (paramsObject.value.order.toString() === "descending") {
                order = "desc"
            }
        }

        const params = Object.assign(
            {},
            paramsObject.value.vastuuyksikot ? { vastuuyksikot: paramsObject.value.vastuuyksikot.join(",") } : null,
            paramsObject.value.paging?.currentPage ? { page: paramsObject.value.paging.currentPage } : null,
            paramsObject.value.paging?.pageSize ? { per_page: paramsObject.value.paging.pageSize } : null,
            paramsObject.value.sort ? { sort: paramsObject.value.sort } : null,
            paramsObject.value.order ? { order: order } : null,
            paramsObject.value.tilanimike ? { tilanimike: paramsObject.value.tilanimike } : null,
            paramsObject.value.tilanimikeTasmennys
                ? { tilanimike_tasmennys: paramsObject.value.tilanimikeTasmennys }
                : null,
            paramsObject.value.tilatunnus ? { tilatunnus: paramsObject.value.tilatunnus } : null,
            paramsObject.value.nimikkeet ? { nimikkeet: paramsObject.value.nimikkeet } : null,
            paramsObject.value.status && paramsObject.value.status.length > 0
                ? { status: paramsObject.value.status.join(",") }
                : null,
            paramsObject.value.poistettu ? { poistettu: paramsObject.value.poistettu } : null
        )

        return ["huonekortit", params]
    }
    const setAllFilters = (val: string) => {
        paramsObject.value.tilanimike = val
        paramsObject.value.tilanimikeTasmennys = val
        paramsObject.value.tilatunnus = val
    }
    const fetcher = async () => {
        const response = await http.get("/huonekortit", { params: key.value[1] })

        return {
            data: response.data as HuonekorttiResponse[],
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { data, error, isLoading: loading, refetch: mutate } = useQuery({ 
        queryKey: key, 
        queryFn: () => fetcher(), 
        enabled: enabled
    })

    watch(
        paramsObject,
        () => {
            key.value = getUrlParams()
        },
        { deep: true }
    )

    watch(data, () => {
        if (data) {
            huonekortit.value = data.value?.data ?? []
            total.value = data.value?.headers["page-total-count"]
                ? parseInt(data.value?.headers["page-total-count"])
                : 0
        }
    })

    return {
        huonekortit: huonekortit,
        error: error,
        total: total,
        paramsObject,
        loading,
        currentPage,
        pageSize,
        filter,
        status,
        mutate,
        setAllFilters,
    }
}
