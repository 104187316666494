<template>
    <ComSelect v-model="vastuuyksikko">
        <el-option
            v-for="vastuuyksikko in vastuuyksikot"
            :key="vastuuyksikko.id"
            :label="vastuuyksikko.nimi"
            :value="vastuuyksikko.id" />
    </ComSelect>
</template>

<script setup lang="ts">
import ComSelect from "../../common/ComSelect.vue"
import { useComTuonti } from "./tuonti";

const { vastuuyksikot, vastuuyksikko } = useComTuonti()

</script>
