<template>
    <el-popover v-model:visible="visible" placement="left" width="800px">
        <template #reference>
            <ComInfoButton @click="visible = !visible"></ComInfoButton>
        </template>
        <template v-if="visible">
            <ComHuonekorttiNimikeHistoriaItem :data="historiaNimikkeet[active]" />
            <el-row>
                <ul style="padding-left: 0px">
                    <li v-for="(historia, index) in historiaNimikkeet" :key="historia.id" style="display: inline-block">
                        <el-button :type="buttonColor(historia.tyyppi)" @click="active = index" text>
                            {{ historia.aika }}
                        </el-button>
                    </li>
                </ul>
            </el-row>
            <el-row>
                <el-col>
                    <el-button @click="visible = false">Sulje</el-button>
                </el-col>
            </el-row>
        </template>
    </el-popover>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import ComHuonekorttiNimikeHistoriaItem from "./ComHuonekorttiNimikeHistoriaItem.vue"
import ComInfoButton from "@/components/common/buttons/ComInfoButton.vue"
import { useHuonekorttiHistoria, useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import { huonekorttiPage } from "@/views/huonekortti"

const props = defineProps<{
    id: number
}>()

const { huonekortti } = huonekorttiPage()
const huonekorttiId = computed(() => huonekortti.value?.id)
const { historia } = useHuonekorttiHistoria(huonekorttiId)
const { diffAllHistoriaNimike, tyyppiColor: buttonColor } = useHuonekorttiHistoriaMethods()
const visible = ref(false)
const active = ref(0)

const historiaNimikkeet = computed(() => {
    if (visible.value) {
        const hn = historia.value?.nimikkeet
        const nimike = huonekortti?.value?.nimikkeet?.find((n) => n.id == props.id)

        if (hn) {
            return diffAllHistoriaNimike(hn, props.id, nimike)
        }
    }

    return []
})
</script>
