<template>
    <el-row>
        <el-col :span="6">
            <el-cascader-panel
                :options="filteredKayttajat"
                :props="props"
                v-model="selected"
                class="kayttaja-oikeudet-cascader">
                <template #default="{ data }">
                    <div
                        @mouseover="valittuKayttaja = data"
                        @mouseleave="valittuKayttaja = undefined"
                        class="cascader-valinta">
                        <div class="cascader-valinta-nimi">{{ data.etunimi }} {{ data.sukunimi }}</div>
                        <div class="cascader-valinta-napit">
                            <ComEditButton @click="editKayttaja(data)" />
                        </div>
                    </div>
                </template>
            </el-cascader-panel>
        </el-col>
        <el-col :span="8" :offset="1">
            <el-row>
                <el-col>
                    <label>Nimi</label>
                    {{ valittuKayttaja ? valittuKayttaja.etunimi + " " + valittuKayttaja.sukunimi : "" }}
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <label>Email</label>
                    {{ valittuKayttaja ? valittuKayttaja.email : "" }}
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <label>Kirjautunut viimeksi</label>
                    {{ valittuKayttaja ? valittuKayttaja.kirjautunutViimeksi : "" }}
                </el-col>
            </el-row>
            <el-row class="row-margin">
                <el-col>
                    <ComOikeusButtons :oikeudet="oikeudet" @oikeus-change="oikeusClick" />
                    <ComSuccessButton @click="addOikeudetClick">Lisää oikeudet</ComSuccessButton>
                </el-col>
            </el-row>
            <el-row style="position: absolute; bottom: 0">
                <el-col>
                    <ComSuccessButton @click="addPaakayttajaClick">Lisää pääkäyttäjäoikeudet</ComSuccessButton>
                </el-col>
            </el-row>
        </el-col>
        <el-col :span="9">
            <ComPaakayttajat />
        </el-col>
    </el-row>
</template>

<script setup lang="ts">
import { OikeusRequest } from "@/api/models/oikeus-request"
import { CascaderProps, ElMessage, ElMessageBox } from "element-plus"
import { ref, watch } from "vue"
import ComPaakayttajat from "./ComPaakayttajat.vue"
import ComSuccessButton from "../../common/buttons/ComSuccessButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { OikeusProperties } from "@/api/models/oikeus-properties"
import ComOikeusButtons from "./ComOikeusButtons.vue"
import ComEditButton from "@/components/common/buttons/ComEditButton.vue"
import { hankePage } from "@/views/hanke"
import { useOikeudetComponent } from "./oikeudet"

const { id: hankeId, selectedVastuuyksikot } = hankePage()
const { paakayttajat, filteredKayttajat, addOikeudetResponse, addPaakaytajaResonse, addOikeudet, addPaakayttajat, editKayttaja } = useOikeudetComponent()
const valittuKayttaja = ref<KayttajaResponse>()
const selected = ref<
    {
        0: number
    }[]
>([])
const oikeudet = ref<OikeusProperties>({
    luku: true,
    kirjoitus: false,
    raportointi: false,
})
const props = {
    label: "etunimi",
    value: "id",
    multiple: true,
} as CascaderProps

const oikeusClick = (oikeus: OikeusProperties) => {
    oikeudet.value = oikeus
}

const addOikeudetClick = () => {
    if (selectedVastuuyksikot.value?.length == 0) {
        ElMessage.warning("Valitse vähintään yksi vastuuyksikkö!")
    } else {
        if (selected.value.length == 0) {
            ElMessage.warning("Valitse vähintään yksi käyttäjä!")
        } else {
            const requests: OikeusRequest[] = []
            selectedVastuuyksikot.value?.forEach((v) => {
                selected.value.forEach((k) => {
                    const request: OikeusRequest = {
                        luku: true,
                        kirjoitus: oikeudet.value.kirjoitus,
                        raportointi: oikeudet.value.raportointi,
                        kayttaja: {
                            id: k[0],
                        },
                        vastuuyksikko: {
                            id: v.id,
                        },
                    }
                    requests.push(request)
                })
            })
            addOikeudet(requests)
        }
    }
}

const addPaakayttajaClick = () => {
    if (selected.value.length == 0) {
        ElMessage.warning("Valitse vähintään yksi käyttäjä!")
    } else {
        ElMessageBox.confirm("Haluatko varmasti lisätä valitut käyttäjät hankkeen pääkäyttäjiksi?", "", {
            type: "warning",
        }).then(() => {
            const kayttajaIds = selected.value.map((s) => s[0])

            if (paakayttajat.value?.some((p) => kayttajaIds.includes(p.kayttaja.id))) {
                ElMessage.error("Käyttäjällä on jo pääkäyttäjäoikeudet!")
            } else {
                addPaakayttajat(hankeId.value, kayttajaIds)
            }
        })
    }
}

watch(addOikeudetResponse, (response) => {
    if (response) {
        ElMessage.success("Oikeudet lisätty!")
        selected.value = []
    }
})

watch(addPaakaytajaResonse, (response) => {
    if (response) {
        ElMessage.success("Pääkäyttäjäoikeudet lisätty!")
        selected.value = []
    }
})

</script>
<style scoped>
.kayttaja-oikeudet-cascader .cascader-valinta {
    float: left;
    width: 100%;
}
.kayttaja-oikeudet-cascader .cascader-valinta-nimi {
    float: left;
}
.kayttaja-oikeudet-cascader .cascader-valinta-napit {
    float: right;
}
.kayttaja-oikeudet-cascader :deep(.el-cascader-menu) {
    width: 100%;
}
</style>
