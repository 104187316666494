import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }

import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComEditor from "@/components/common/ComEditor.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import sanitize from "sanitize-html"
import { PropType, ref, watch, watchEffect } from "vue"
import { useHanketiedotComponent } from "./hanketiedot"
import { useUpdateHanke } from "@/methods/hankeComposables"
import { HankeRequest } from "@/api/models/hanke-request"
import { ElMessage } from "element-plus"
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"
import { HankeVaatimusRequestPut } from "@/api/models/hanke-vaatimus-request-put"
import { HankeVaatimusRequest } from "@/api/models/hanke-vaatimus-request"
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { useAddHankeVaatimus, useUpdateHankeVaatimus } from "@/methods/hankeVaatimusComposables"
import { useUpdateVastuuyksikko } from "@/methods/vastuuyksikkoComposables"
import VaatimusType from "@/types/VaatimusEnum"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHanketiedotVaatimuksetField',
  props: {
    vaatimus: {
        type: String,
        required: false,
        default: "",
    },
    vastuuyksikko: {
        type: Object as PropType<VastuuyksikkoResponse>,
        required: false,
    },
    kategoriaId: {
        type: Number,
        required: false,
    },
    type: {
        type: String as PropType<VaatimusType>,
        required: true,
    },
},
  setup(__props) {

const props = __props
const { hanke, editable, vastuuyksikot } = useHanketiedotComponent()
const {  error: hankeUpdateError, loading: hankeUpdateLoading, response: hankeUpdateResponse, updateHanke } = useUpdateHanke()
const { updateVastuuyksikko, loading: vastuuyksikkoUpdateLoading, error: vastuuyksikkoUpdateError, response: vastuuyksikkoUpdateResponse  } = useUpdateVastuuyksikko()
const { updateHankeVaatimus, loading: hankeVaatimusUpdateLoading, error: hankeVaatimusUpdateError, response: hankeVaatimusUpdateResponse } = useUpdateHankeVaatimus()
const { addHankeVaatimus, loading: hankeVaatimusAddLoading, error: hankeVaatimusAddError, response: hankeVaatimusAddResponse } = useAddHankeVaatimus()
const edit = ref(false)
const vaatimusEditable = ref(props.vaatimus)

const saveVaatimusClick = async () => {
    if(props.type === VaatimusType.Hanke && hanke.value) {
        const request : HankeRequest = Object.assign({} as HankeRequest, hanke.value, { vaatimukset: vaatimusEditable.value })
        
        await updateHanke(hanke.value.id, request)
    } else if(props.type === VaatimusType.Vastuuyksikko && props.vastuuyksikko) {
        const request = Object.assign({} as VastuuyksikkoRequest, props.vastuuyksikko, { vaatimukset: vaatimusEditable.value })

        await updateVastuuyksikko(props.vastuuyksikko.id, request)
    } else if(props.type === VaatimusType.Kategorisoidut && props.kategoriaId && hanke.value) {
        const hankeVaatimusId = hanke.value?.kategorisoidutVaatimukset?.find(k => k.kategoria?.id === props.kategoriaId)?.id

        if(hankeVaatimusId) {
            const request: HankeVaatimusRequestPut = {}

            request.vaatimus = vaatimusEditable.value

            await updateHankeVaatimus(hankeVaatimusId, request)
        } else {
            const request: HankeVaatimusRequest = {}

            request.vaatimus = vaatimusEditable.value
            request.hanke = { id: hanke.value?.id }
            request.kategoria = { id: props.kategoriaId }

            await addHankeVaatimus(request)
        }
    }

    edit.value = false
}

watch(
    () => vastuuyksikot,
    () => {
        edit.value = false
    }
)
watch(
    () => props.vaatimus,
    () => {
        vaatimusEditable.value = props.vaatimus
})
watchEffect(() => {
    if(!hankeUpdateLoading.value || !vastuuyksikkoUpdateLoading.value || !hankeVaatimusUpdateLoading.value || !hankeVaatimusAddLoading.value) {
        if(hankeUpdateResponse.value || vastuuyksikkoUpdateResponse.value || hankeVaatimusUpdateResponse.value || hankeVaatimusAddResponse.value) {
            ElMessage.success("Vaatimukset tallennettu!")
        }
    }
})
watchEffect(() => {
    if(hankeUpdateError.value || hankeVaatimusUpdateError.value || hankeVaatimusAddError.value || vastuuyksikkoUpdateError.value) {
        ElMessage.error("Vaatimuksien tallennus epäonnistui")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_space = _resolveComponent("el-space")!

  return (!edit.value)
    ? (_openBlock(), _createBlock(_component_el_space, {
        key: 0,
        direction: "vertical",
        fill: "",
        style: {"display":"flex"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { style: {"width":"100%"} }, {
            default: _withCtx(() => [
              (!__props.vaatimus)
                ? (_openBlock(), _createBlock(ComEmpty, { key: 0 }))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _unref(sanitize)(__props.vaatimus)
                  }, null, 8, _hoisted_1))
            ]),
            _: 1
          }),
          (_unref(editable))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(ComSuccessButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (edit.value = true))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Muokkaa")
                  ])),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_el_space, {
        key: 1,
        direction: "vertical",
        fill: "",
        style: {"display":"flex"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(ComEditor, {
              disabled: false,
              modelValue: vaatimusEditable.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((vaatimusEditable).value = $event)),
              initial: ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_space, null, {
              default: _withCtx(() => [
                _createVNode(ComSuccessButton, { onClick: saveVaatimusClick }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Tallenna")
                  ])),
                  _: 1
                }),
                _createVNode(ComCancelButton, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (edit.value = false))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Peruuta")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }))
}
}

})