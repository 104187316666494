import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"float":"right"} }

import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComToimintatiedot from "@/components/huonekortti/ComToimintatiedot.vue"
import ComSuunnittelutiedot from "@/components/huonekortti/ComSuunnittelutiedot.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComHistoria from "@/components/huonekortti/historia/ComHistoria.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiInfoDropDownButton from "@/components/huonekortti/ComHuonekorttiInfoDropDownButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComLiitteet from "@/components/liitteet/ComLiitteet.vue"
import ComCard from "@/components/common/ComCard.vue"
import { huonekorttiPage } from "./huonekortti"


const __default__ = {
    name: "HuonekorttiView",
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const {
    huonekortti,
    showMuutokset,
    headerTitle,
    headerSubtitle,
    liitteetCount,
    kommentitCount,
    showHanketiedot,
    hanke,
    loading,
    paakayttaja
} = huonekorttiPage()

return (_ctx: any,_cache: any) => {
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_unref(huonekortti))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(ComPageHeader, {
          contentTitle: _unref(headerTitle),
          contentSubtitle: _unref(headerSubtitle)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_badge, {
                    value: _unref(liitteetCount),
                    hidden: !_unref(liitteetCount)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(ComLiitteet, {
                        viite: _unref(huonekortti).id,
                        tyyppi: 1
                      }, null, 8, ["viite"])
                    ]),
                    _: 1
                  }, 8, ["value", "hidden"]),
                  _createVNode(_component_el_badge, {
                    value: _unref(kommentitCount),
                    hidden: !_unref(kommentitCount)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(ComKommentointi, {
                        paakayttaja: _unref(paakayttaja) ?? false,
                        viite: _unref(huonekortti).id,
                        tyyppi: 1
                      }, null, 8, ["paakayttaja", "viite"])
                    ]),
                    _: 1
                  }, 8, ["value", "hidden"]),
                  _createVNode(ComHuonekorttiInfoDropDownButton)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["contentTitle", "contentSubtitle"]),
        _createVNode(ComToimintatiedot, {
          data: _unref(huonekortti),
          class: "card-margin-bottom"
        }, null, 8, ["data"]),
        _createVNode(ComSuunnittelutiedot),
        (_unref(showHanketiedot))
          ? (_openBlock(), _createBlock(_component_el_drawer, {
              key: 0,
              modelValue: _unref(showHanketiedot),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(showHanketiedot) ? (showHanketiedot).value = $event : null)),
              class: "hanketiedotDrawer",
              "append-to-body": "",
              size: "50%",
              title: "Tiedot",
              "destroy-on-close": ""
            }, {
              default: _withCtx(() => [
                _createVNode(ComHanketiedot, {
                  huonekorttiId: _unref(huonekortti).id,
                  hankeId: _unref(hanke)?.id,
                  editable: false
                }, null, 8, ["huonekorttiId", "hankeId"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        (_unref(showMuutokset))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_unref(huonekortti) && _unref(huonekortti).status == 'Hyvaksytty')
                ? (_openBlock(), _createBlock(_component_el_drawer, {
                    key: 0,
                    modelValue: _unref(showMuutokset),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(showMuutokset) ? (showMuutokset).value = $event : null)),
                    size: "50%",
                    title: "Historia",
                    "destroy-on-close": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(ComHistoria, { huonekortti: _unref(huonekortti) }, null, 8, ["huonekortti"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ], 64))
    : (_unref(loading))
      ? (_openBlock(), _createBlock(ComCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(ComLoading)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}
}

})