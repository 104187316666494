import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComYllapitoHankeVastuuyksikko from "./ComYllapitoHankeVastuuyksikko.vue"
import { useYllapitoComponent } from "./yllapito"
import { useCloned } from "@vueuse/core"
import ComYllapitoHankeVastuuyksikkoAdd from "./ComYllapitoHankeVastuuyksikkoAdd.vue"
import ComYllapitoHankePaakayttajat from "./ComYllapitoHankePaakayttajat.vue"
import { useSairaalat } from "@/methods/sairaalaComposables"
import { ElMessage, ElMessageBox } from "element-plus"
import { HankeResponse } from "@/api/models/hanke-response"
import { useUpdateHanke } from "@/methods/hankeComposables"
import { watch } from "vue"
import { HankeRequest } from "@/api/models/hanke-request"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeEdit',
  setup(__props) {

const { sairaalat } = useSairaalat()
const { updateHanke, error } = useUpdateHanke()
const { selectedHanke } = useYllapitoComponent()
const { cloned: data } = useCloned(selectedHanke)

const updateHankeClick = async (hanke: HankeResponse) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti tallentaa muutokset?", "Tallenna muutokset", {
        confirmButtonText: "Tallenna",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (!confirm) {
        return
    } else {
        const request = hanke as HankeRequest

        updateHanke(hanke.id, request)
    }
}

watch(error, () => {
    if (error.value) {
        ElMessage.error("Hankkeen päivitys epäonnistui!")
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_unref(data))
    ? (_openBlock(), _createBlock(_component_el_tabs, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, { label: "Hanke" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { label: "Hanke" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        size: "small",
                        modelValue: _unref(data).nimi,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(data).nimi) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "Työnumero" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        size: "small",
                        modelValue: _unref(data).tyonumero,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(data).tyonumero) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "Hankenumero" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        size: "small",
                        modelValue: _unref(data).hankenumero,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(data).hankenumero) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_form_item, { label: "Arkistoitu" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_switch, {
                        size: "small",
                        modelValue: _unref(data).arkistoitu,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(data).arkistoitu) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  (_unref(data).sairaala)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "Sairaala"
                      }, {
                        default: _withCtx(() => [
                          (_unref(sairaalat))
                            ? (_openBlock(), _createBlock(_component_el_select, {
                                key: 0,
                                size: "small",
                                modelValue: _unref(data).sairaala.id,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(data).sairaala.id) = $event)),
                                placeholder: "Valitse sairaala"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sairaalat), (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.id,
                                      label: item.nimi,
                                      value: item.id
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(ComSuccessButton, {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(data) ? updateHankeClick(_unref(data)) : ''))
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Tallenna")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { label: "Vastuuyksiköt" }, {
            default: _withCtx(() => [
              (_unref(data).vastuuyksikot)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(data).vastuuyksikot, (vastuuyksikko, i) => {
                    return (_openBlock(), _createBlock(ComYllapitoHankeVastuuyksikko, {
                      key: vastuuyksikko.id,
                      modelValue: _unref(data).vastuuyksikot[i],
                      "onUpdate:modelValue": ($event: any) => ((_unref(data).vastuuyksikot[i]) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  }), 128))
                : _createCommentVNode("", true),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(ComYllapitoHankeVastuuyksikkoAdd)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { label: "Pääkäyttäjät" }, {
            default: _withCtx(() => [
              _createVNode(ComYllapitoHankePaakayttajat)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})