import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 2 }

import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComDateTimePicker from "@/components/common/ComDateTimePicker.vue"
import { ref, watch } from "vue"
import { huonekorttiListing } from "../huonekortit/huonekortit"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiTableWithFilterAndPagination from "../huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComMuutoslogiRaportti',
  emits: ["range"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const { huonekortit, loading, status } = huonekorttiListing()
const range = ref<Date[]>()

watch(range, () => {
    emit("range", range.value)
})

status.value = [HuonekorttiStatusResponse.Hyvaksytty]


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (!_unref(huonekortit))
    ? (_openBlock(), _createBlock(ComEmpty, { key: 0 }))
    : (_unref(loading))
      ? (_openBlock(), _createBlock(ComLoading, { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("label", { class: "el-form-item__label" }, "Raportoitava aikaväli", -1)),
                  _createVNode(ComDateTimePicker, {
                    modelValue: range.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((range).value = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider),
          _createVNode(ComHuonekorttiTableWithFilterAndPagination, {
            status: _unref(status),
            selection: true
          }, null, 8, ["status"])
        ]))
}
}

})