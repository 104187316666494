<template>
    <div>
        <el-row>
            <el-col :span="24">Olet tuomassa {{ maara }} huonekorttia vastuuyksikköön {{ vastuuyksikkoNimi }}</el-col>
        </el-row>
        <el-row justify="end">
            <el-col :push="18">
                <el-button size="small" type="danger" class="button" @click="peruuta">Peruuta</el-button>
                <el-button size="small" type="success" class="button" @click="hyvaksy">Tuo huonekortit</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useComTuonti } from "./tuonti"

const { huonekortit, vastuuyksikko, vastuuyksikot, peruuta, hyvaksy } = useComTuonti()
const maara = computed(() => huonekortit.value.length)
const vastuuyksikkoNimi = computed(() => vastuuyksikot.value?.find((v) => v.id === vastuuyksikko.value)?.nimi)

</script>

<style scoped>
.button {
    position: relative;
    top: 34px;
    z-index: 1000000;
}
</style>
