import http from "@/http-common"
import { useQuery } from "@tanstack/vue-query"

const url = "sairaalat"

export function useSairaalat() {
    const key = [url]

    const fetcher = async () => {
        const response = await http.get(url)
        
        return response.data
    }

    const { 
        data: sairaalat,
        error, 
        isLoading: loading 
    } = useQuery({ queryKey: key, queryFn: () => fetcher() })

    return {
        sairaalat,
        error,
        loading,
    }
}