import { useLiitteet } from "@/methods/liiteComposables"
import kommenttiTyyppi from "@/types/Kommentti"
import { computed } from "vue"

interface state {
    tyyppi: kommenttiTyyppi | undefined,
    viite: number | undefined
}

const state = {} as state

export function useLiiteComponent() {
    const viite = computed({
        get: () => state.viite,
        set: (value : number) => {
            state.viite = value 
        }
    })
    const tyyppi = computed({
        get: () => state.tyyppi,
        set: (value : kommenttiTyyppi) => {
            state.tyyppi = value 
        }
    })

    const { liitteet } = useLiitteet(viite, tyyppi)

    return {
        liitteet,
        viite,
        tyyppi
    }
}
