import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createSlots as _createSlots, isRef as _isRef } from "vue"

const _hoisted_1 = { style: {"width":"60px"} }

import ComCancelIconButton from "@/components/common/buttons/ComCancelIconButton.vue"
import ComMinusButton from "@/components/common/buttons/ComMinusButton.vue"
import ComPlusButton from "@/components/common/buttons/ComPlusButton.vue"
import ComSaveButton from "@/components/common/buttons/ComSaveButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComHuonekorttiDeleteButton from "./ComHuonekorttiDeleteButton.vue"
import ComStatus from "../ComStatus.vue"
import { Paperclip } from "@element-plus/icons-vue"
import ComMoreButton from "@/components/common/buttons/ComMoreButton.vue"
import ComMallihuonekorttiEditToimintaDialog from "@/components/hanke/huonekortit/table/ComMallihuonekorttiEditToimintaDialog.vue"
import { huonekorttiTableEditable } from "./huonekorttiTableEditable"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiTableEditable',
  setup(__props) {

const {
    huonekortit,
    mallihuonekortti,
    mallihuonekorttiDialog,
    tilanimikkeet,
    edit,
    editedHuonekortti,
    paakayttaja,
    writableVastuuyksikot,
    more,
    tableRef,
    sortChanged,
    removeTila,
    addTila,
    cancelEdit,
    saveEdit,
    formatTilat,
    editHuonekortti,
    addMallihuonekorttiClick,
    checkPoistoOikeus,
} = huonekorttiTableEditable()

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_table, {
      data: _unref(huonekortit),
      onSortChange: _unref(sortChanged),
      ref_key: "tableRef",
      ref: tableRef,
      "scrollbar-always-on": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "Tilanimike",
          width: "180",
          sortable: "custom",
          prop: "tilanimike"
        }, {
          default: _withCtx((scope) => [
            (_unref(edit) === scope.row.id)
              ? (_openBlock(), _createBlock(_component_el_select, {
                  key: 0,
                  size: "small",
                  modelValue: _unref(editedHuonekortti).tilanimike.id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(editedHuonekortti).tilanimike.id) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tilanimikkeet), (tilanimike) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: tilanimike.id,
                        label: tilanimike.nimi,
                        value: tilanimike.id
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_router_link, {
                    to: { name: 'Huonekortti', params: { huonekorttiId: scope.row.id } },
                    custom: ""
                  }, {
                    default: _withCtx(({ href, navigate }) => [
                      _createVNode(_component_el_link, {
                        type: "primary",
                        href: href,
                        onClick: navigate
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(scope.row.tilanimike.nimi), 1)
                        ]),
                        _: 2
                      }, 1032, ["href", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _withDirectives(_createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Paperclip))
                    ]),
                    _: 2
                  }, 1536), [
                    [_vShow, scope.row.liitteet.length]
                  ])
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          "min-width": "250px",
          label: "Täsmennys",
          "show-overflow-tooltip": "",
          sortable: "custom",
          prop: "tasmennys"
        }, {
          default: _withCtx((scope) => [
            (_unref(edit) === scope.row.id)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  size: "small",
                  modelValue: _unref(editedHuonekortti).toiminta,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(editedHuonekortti).toiminta) = $event))
                }, null, 8, ["modelValue"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(scope.row.toiminta), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "Status",
          width: "120",
          sortable: "custom",
          prop: "status"
        }, {
          default: _withCtx((scope) => [
            _createVNode(ComStatus, {
              huonekortti: scope.row,
              modelValue: scope.row.status,
              "onUpdate:modelValue": ($event: any) => ((scope.row.status) = $event)
            }, null, 8, ["huonekortti", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          "show-overflow-tooltip": "",
          label: "Vastuuyksikkö",
          sortable: "custom",
          prop: "vastuuyksikko",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            (_unref(edit) === scope.row.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(writableVastuuyksikot))
                    ? (_openBlock(), _createBlock(_component_el_select, {
                        key: 0,
                        size: "small",
                        modelValue: _unref(editedHuonekortti).vastuuyksikko.id,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(editedHuonekortti).vastuuyksikko.id) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(writableVastuuyksikot), (vastuuyksikko) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: vastuuyksikko.id,
                              label: vastuuyksikko.nimi,
                              value: vastuuyksikko.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(scope.row.vastuuyksikko.nimi), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "tilat",
          label: "Tilat",
          "show-overflow-tooltip": "",
          "min-width": "300"
        }, {
          default: _withCtx((scope) => [
            (_unref(edit) === scope.row.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_space, { size: 40 }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("label", null, "Tilatunnus", -1),
                          _createElementVNode("label", null, "Pinta-ala", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_space, { direction: "vertical" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(editedHuonekortti).tilat, (tila, index) => {
                        return (_openBlock(), _createBlock(_component_el_row, {
                          key: tila.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_space, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  modelValue: tila.tilatunnus,
                                  "onUpdate:modelValue": ($event: any) => ((tila.tilatunnus) = $event),
                                  size: "small"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_el_input, {
                                  modelValue: tila.pintaAla,
                                  "onUpdate:modelValue": ($event: any) => ((tila.pintaAla) = $event),
                                  size: "small",
                                  type: "number",
                                  placeholder: "m2"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                _createElementVNode("div", _hoisted_1, [
                                  _createVNode(ComMinusButton, {
                                    onClick: ($event: any) => (_unref(removeTila)(_unref(editedHuonekortti), index))
                                  }, null, 8, ["onClick"]),
                                  (index + 1 === _unref(editedHuonekortti).tilat?.length)
                                    ? (_openBlock(), _createBlock(ComPlusButton, {
                                        key: 0,
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(addTila)(_unref(editedHuonekortti))))
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_unref(formatTilat)(scope.row.tilat)), 1)
                ], 64))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "lkm",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.tilat.length) + " kpl", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          align: "right",
          width: "100",
          fixed: "right"
        }, {
          default: _withCtx((scope) => [
            (_unref(edit) === scope.row.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(ComCancelIconButton, {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(cancelEdit)()))
                  }),
                  _createVNode(ComSaveButton, {
                    onClick: ($event: any) => (_unref(saveEdit)(scope.row))
                  }, null, 8, ["onClick"])
                ], 64))
              : _createCommentVNode("", true),
            (!_unref(edit))
              ? (_openBlock(), _createBlock(_component_el_badge, {
                  key: 1,
                  "is-dot": "",
                  hidden: !scope.row.kommentit.length,
                  class: "badge"
                }, {
                  default: _withCtx(() => [
                    _createVNode(ComKommentointi, {
                      paakayttaja: _unref(paakayttaja),
                      viite: scope.row.id,
                      tyyppi: 1
                    }, null, 8, ["paakayttaja", "viite"])
                  ]),
                  _: 2
                }, 1032, ["hidden"]))
              : _createCommentVNode("", true),
            (!_unref(edit) && _unref(writableVastuuyksikot)?.some((v) => v.id === scope.row.vastuuyksikko.id))
              ? (_openBlock(), _createBlock(_component_el_dropdown, { key: 2 }, _createSlots({
                  default: _withCtx(() => [
                    _createVNode(ComMoreButton, {
                      onClick: ($event: any) => (more.value = scope.row.id)
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, [
                  (_unref(more) == scope.row.id)
                    ? {
                        name: "dropdown",
                        fn: _withCtx(() => [
                          _createVNode(_component_el_dropdown_item, {
                            onClick: ($event: any) => (_unref(editHuonekortti)(scope.row))
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Muokkaa")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          (_unref(checkPoistoOikeus)(scope.row))
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(ComHuonekorttiDeleteButton, {
                                    huonekortti: scope.row
                                  }, null, 8, ["huonekortti"])
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          (_unref(paakayttaja))
                            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: 1,
                                onClick: ($event: any) => (_unref(addMallihuonekorttiClick)(scope.row))
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Luo mallihuonekortti ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1024))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data", "onSortChange"]),
    (_unref(mallihuonekortti))
      ? (_openBlock(), _createBlock(ComMallihuonekorttiEditToimintaDialog, {
          key: 0,
          dialog: _unref(mallihuonekorttiDialog),
          "onUpdate:dialog": _cache[5] || (_cache[5] = ($event: any) => (_isRef(mallihuonekorttiDialog) ? (mallihuonekorttiDialog).value = $event : null)),
          huonekortti: _unref(mallihuonekortti)
        }, null, 8, ["dialog", "huonekortti"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})