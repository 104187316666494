import { HankeVaatimusRequest } from "@/api/models/hanke-vaatimus-request"
import { HankeVaatimusRequestPut } from "@/api/models/hanke-vaatimus-request-put"
import { HankeVaatimusResponse } from "@/api/models/hanke-vaatimus-response"
import { HankeVaatimuskategoriaResponse } from "@/api/models/hanke-vaatimuskategoria-response"
import http from "@/http-common"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"

const url = "/hankkeet/vaatimukset"
const kategoriatUrl = url + "/kategoriat"

export function useHankeVaatimusKategoriat() {
    const queryKey = [kategoriatUrl]

    const fetcher = async () : Promise<HankeVaatimuskategoriaResponse[]> => {
        return (await http.get(kategoriatUrl)).data
    }
    const { data: hankeVaatimuskategoriat, error, isLoading: loading } = useQuery({ queryKey: queryKey, queryFn: () => fetcher() })

    return {
        hankeVaatimuskategoriat,
        error,
        loading
    }
}

export function useAddHankeVaatimus() {
    const queryClient = useQueryClient()
    const { data : response, error, isPending : loading, mutate } = useMutation({
        mutationFn: (request : HankeVaatimusRequest) => http.post(url, request),
        onSuccess: (data, request) => {
            queryClient.invalidateQueries({queryKey: ['hankkeet', request.hanke?.id]})
        }
    })

    const addHankeVaatimus = (request : HankeVaatimusRequest) => {
        return mutate(request)
    }

    return {
        response,
        error,
        loading,
        addHankeVaatimus
    }
}

export function useUpdateHankeVaatimus() {
    interface request {
        id: number
        request: HankeVaatimusRequestPut
    }

    const queryClient = useQueryClient()
    const { data : response, error, isPending : loading, mutate } = useMutation({
        mutationFn: (request : request) : Promise<HankeVaatimusResponse> => http.put(url + '/' + request.id, request.request),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['hankkeet']})
    })

    const updateHankeVaatimus = (id: number, request:  HankeVaatimusRequestPut) => {
        return mutate({id, request})
    }

    return {
        response,
        error,
        loading,
        updateHankeVaatimus
    }
}