import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-margin-bottom" }

import ComHuonekorttiTableWithFilterAndPagination from "@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"
import { ref, watch } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComTilaRaportti',
  emits: ["huonekorteittain"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const huonekorteittain = ref(false)

watch(huonekorteittain, () => {
    emit("huonekorteittain", huonekorteittain.value)
})


return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_checkbox, {
              label: "Valitse erikseen raportoitavat huonekortit",
              modelValue: huonekorteittain.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((huonekorteittain).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (huonekorteittain.value)
      ? (_openBlock(), _createBlock(_component_el_divider, { key: 0 }))
      : _createCommentVNode("", true),
    (huonekorteittain.value)
      ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(ComHuonekorttiTableWithFilterAndPagination)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})