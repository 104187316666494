import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ComHuonekorttiValinta from "./ComHuonekorttiValinta.vue"
import ComVastuuyksikkoValinta from "./ComVastuuyksikkoValinta.vue"
import ComVarmistus from "./ComVarmistus.vue"
import { ElMessage } from "element-plus"
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue"
import ComImportButton from "@/components/common/buttons/ComImportButton.vue"
import { useComTuonti } from "./tuonti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComTuoHuonekortteja',
  setup(__props) {

const header = [
    "Valitse tuotavat huonekortit",
    "Valitse vastuuyksikkö mihin huonekortit tuodaan",
    "Hyväksy tuonti",
]
const { huonekortit, active, visible, vastuuyksikko } = useComTuonti()

const seuraava = () => {
    if (active.value === 0) {
        if (huonekortit.value.length === 0) {
            ElMessage.error("Valitse ainakin yksi huonekortti.")

            return
        }
    }
    if (active.value === 1) {
        if (!vastuuyksikko.value) {
            ElMessage.error("Valitse vastuuyksikkö.")

            return
        }
    }

    active.value++
}


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComImportButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = true))
    }),
    (_unref(visible))
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          title: header[_unref(active)],
          modelValue: _unref(visible),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visible) ? (visible).value = $event : null)),
          "destroy-on-close": "",
          "append-to-body": ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(ComHuonekorttiValinta, { class: "card-margin-bottom" }, null, 512), [
              [_vShow, _unref(active) === 0]
            ]),
            _withDirectives(_createVNode(ComVastuuyksikkoValinta, { class: "card-margin-bottom" }, null, 512), [
              [_vShow, _unref(active) === 1]
            ]),
            _withDirectives(_createVNode(ComVarmistus, { class: "card-margin-bottom" }, null, 512), [
              [_vShow, _unref(active) === 2]
            ]),
            _createVNode(_component_el_row, { class: "card-margin-bottom" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button_group, null, {
                      default: _withCtx(() => [
                        (_unref(active) > 0)
                          ? (_openBlock(), _createBlock(_component_el_button, {
                              key: 0,
                              size: "small",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (active.value--))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(ArrowLeft))
                                  ]),
                                  _: 1
                                }),
                                _cache[3] || (_cache[3] = _createTextVNode(" Edellinen "))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_unref(active) < 2)
                          ? (_openBlock(), _createBlock(_component_el_button, {
                              key: 1,
                              size: "small",
                              onClick: seuraava
                            }, {
                              default: _withCtx(() => [
                                _cache[4] || (_cache[4] = _createTextVNode(" Seuraava ")),
                                _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(ArrowRight))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_steps, { active: _unref(active) }, {
              default: _withCtx(() => [
                _createVNode(_component_el_step, {
                  title: "Vaihe 1",
                  description: "Valitse kopioitavat huonekortit"
                }),
                _createVNode(_component_el_step, {
                  title: "Vaihe 2",
                  description: "Valitse kopioinnin kohde"
                }),
                _createVNode(_component_el_step, {
                  title: "Vaihe 3",
                  description: "Varmistus"
                })
              ]),
              _: 1
            }, 8, ["active"])
          ]),
          _: 1
        }, 8, ["title", "modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})