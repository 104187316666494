<template>
    <el-button size="small" circle>
        <el-icon style="cursor: pointer">
            <More />
        </el-icon>
    </el-button>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { More } from "@element-plus/icons-vue"

export default defineComponent({
    components: {
        More,
    },
})
</script>
