import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

import { OikeusProperties } from "@/api/models/oikeus-properties"
import { ref, watchEffect } from "vue"
import ComOikeusButtons from "../ComOikeusButtons.vue"
import ComSelect from "@/components/common/ComSelect.vue"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { hankePage } from "@/views/hanke"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLisaaKayttajaOikeudet',
  emits: ["oikeudetChange"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const { hankeVastuuyksikot } = hankePage()
const vastuuyksikkoSelected = ref<number[]>()
const oikeudet = ref<OikeusProperties>({
    luku: true,
    kirjoitus: false,
    raportointi: false,
})

const valitseKaikkiclick = () => {
    vastuuyksikkoSelected.value = []

    vastuuyksikkoSelected.value = hankeVastuuyksikot.value?.map((v) => v.id)
}

watchEffect(() => {
    if (vastuuyksikkoSelected.value) {
        const response: OikeusRequest[] = []

        vastuuyksikkoSelected.value.forEach((a) => {
            const oikeus: OikeusRequest = {}
            oikeus.luku = oikeudet.value.luku
            oikeus.kirjoitus = oikeudet.value.kirjoitus
            oikeus.raportointi = oikeudet.value.raportointi
            oikeus.vastuuyksikko = { id: a }

            response.push(oikeus)
        })

        emit("oikeudetChange", response)
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_openBlock(), _createBlock(_component_el_space, { wrap: "" }, {
    default: _withCtx(() => [
      _createVNode(ComSelect, {
        modelValue: vastuuyksikkoSelected.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vastuuyksikkoSelected).value = $event)),
        multiple: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hankeVastuuyksikot), (vastuuyksikko, index) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: index,
              label: vastuuyksikko.nimi,
              value: vastuuyksikko.id
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_link, {
        type: "primary",
        onClick: valitseKaikkiclick
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("valitse kaikki")
        ])),
        _: 1
      }),
      _createVNode(ComOikeusButtons, {
        oikeudet: oikeudet.value,
        onOikeusChange: _cache[1] || (_cache[1] = (val) => (oikeudet.value = val))
      }, null, 8, ["oikeudet"])
    ]),
    _: 1
  }))
}
}

})