<template>
    <el-timeline>
        <template v-for="muutos in historia" :key="muutos.id">
            <ComTilaHistoriaItem :previousData="muutos" :data="getCurrentData(muutos)" />
        </template>
    </el-timeline>
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { TilaHistoriaResponse } from "@/api/models/tila-historia-response"
import { computed } from "vue"
import ComTilaHistoriaItem from "./ComTilaHistoriaItem.vue"
import { huonekorttiPage } from "@/views/huonekortti"

const props = defineProps<{
    data: TilaHistoriaResponse[]
}>()

const { huonekortti } = huonekorttiPage()
const tilatCurrent = computed(() => huonekortti?.value?.tilat)
const historia = computed(() => {
    const temp = props.data

    return temp.sort((a, b) => a.id - b.id).reverse()
})

const getCurrentData = (val: TilaHistoriaResponse) => {
    if (val.tyyppi != HuonekorttiHistoriaTyyppi.Muutos) {
        return undefined
    }

    const i = historia.value.findIndex((h) => h.id == val.id)
    const next = historia.value[i - 1]

    if (next?.tila?.id != val.tila?.id) {
        return tilatCurrent.value?.find((t) => t.id == val.tila?.id)
    } else {
        return next
    }
}
</script>
