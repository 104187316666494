import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, watch } from "vue"
import { useYllapitoComponent } from "./yllapito"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { ElMessage, ElMessageBox } from "element-plus"
import ComSelect from "../common/ComSelect.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { useDeletePaakayttaja, useKayttajat } from "@/methods/paakayttajaComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankePaakayttajat',
  setup(__props) {

const searhString = ref("")
const { deletePaakayttaja, error: deleteError } = useDeletePaakayttaja()
const { paakayttajat, addPaakayttaja } = useYllapitoComponent()
const { kayttajat, loading } = useKayttajat(searhString)
const selectedKayttaja = ref<KayttajaResponse>()

const fetchKayttajat = (val: string) => {
    searhString.value = val
}

const addPaakayttajaClick = () => {
    if (selectedKayttaja.value) {
        addPaakayttaja(selectedKayttaja.value.id)

        selectedKayttaja.value = undefined
    } else {
        ElMessage.warning("Valitse käyttäjä")
    }
}

const deletePaakayttajaClick = async (id: number) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti poistaa pääkäyttäjän?", "Varoitus", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (confirm) {
        deletePaakayttaja(id)
    }
}

watch(deleteError, (value) => {
    if (value) {
        ElMessage.error("Pääkäyttäjän poisto epäonnistui")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_unref(paakayttajat))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paakayttajat), (paakayttaja) => {
          return (_openBlock(), _createBlock(_component_el_row, {
            key: paakayttaja.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 10 }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(paakayttaja.kayttaja.etunimi) + " " + _toDisplayString(paakayttaja.kayttaja.sukunimi), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_col, {
                span: 12,
                offset: 2
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(ComDeleteButton, {
                        onClick: ($event: any) => (deletePaakayttajaClick(paakayttaja.id))
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Poista")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { label: "Lisää pääkäyttäjä" }, {
                  default: _withCtx(() => [
                    _createVNode(ComSelect, {
                      modelValue: selectedKayttaja.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedKayttaja).value = $event)),
                      filterable: "",
                      remote: "",
                      "reserve-keyword": "",
                      "value-key": "email",
                      placeholder: "Kirjoita ja valitse",
                      loading: _unref(loading),
                      "remote-method": fetchKayttajat,
                      style: {"min-width":"300px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(kayttajat), (kayttaja) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: kayttaja,
                            label: kayttaja.email,
                            value: kayttaja
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "loading"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(ComSuccessButton, { onClick: addPaakayttajaClick }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Lisää")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 64))
    : _createCommentVNode("", true)
}
}

})