import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowUp = _resolveComponent("ArrowUp")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.h)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          class: "expandButton",
          onClick: _ctx.expand,
          type: "info",
          plain: "",
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            (_ctx.way == 'vertical')
              ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowUp)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowLeft)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (!_ctx.h)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          class: "expandButton",
          onClick: _ctx.expand,
          type: "info",
          plain: "",
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            (_ctx.way == 'vertical')
              ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowDown)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowRight)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}