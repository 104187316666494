<template>
    <ComLoading v-if="!huonekortit" />
    <ComEmpty v-else-if="huonekortit.length == 0" />
    <template v-else>
        <el-row>
            <el-col :span="24">
                <div style="float: right">
                    <ComPagination :total="total" @page-change="pageChangeClicked" />
                </div>
            </el-col>
        </el-row>
        <el-table :data="huonekortit">
            <el-table-column label="Tilanimike" prop="tilanimike.nimi" />
            <el-table-column label="Tilanimikeen täsmennys" prop="toiminta" />
            <el-table-column label="Vastuuyksikkö" prop="vastuuyksikko.nimi" />
            <el-table-column width="100px">
                <template #default="scope">
                    <el-link type="primary" @click="palautaButtonClicked(scope.row.id)">Palauta</el-link>
                </template>
            </el-table-column>
        </el-table>
    </template>
</template>

<script setup lang="ts">
import ComPagination from "@/components/common/ComPagination.vue"
import { useRoskakori } from "./roskakori";
import ComLoading from "@/components/common/ComLoading.vue";
import ComEmpty from "@/components/common/ComEmpty.vue";

const { huonekortit, total, palautaButtonClicked, pageChangeClicked } = useRoskakori()
</script>
