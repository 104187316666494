<template>
    <el-table :data="huonekortit" ref="multipleTableRef">
        <el-table-column v-if="selection" width="100px">
            <template #header>
                <el-button-group>
                    <ComPlusButton @click="selectAll" />
                    <ComMinusButton @click="unselectAll"></ComMinusButton>
                </el-button-group>
            </template>
            <template #default="scope">
                <input type="checkbox" :id="scope.row.id" :value="scope.row.id" v-model="huonekorttiIdsSelected" />
            </template>
        </el-table-column>
        <el-table-column label="Tilanimike" prop="tilanimike.nimi"></el-table-column>
        <el-table-column label="Täsmennys" prop="toiminta" show-overflow-tooltip></el-table-column>
        <el-table-column label="Status" prop="status">
            <template #default="scope">
                <ComStatusTag :status="scope.row.status"></ComStatusTag>
            </template>
        </el-table-column>
        <el-table-column label="Vastuuyksikkö" prop="vastuuyksikko.nimi"></el-table-column>
        <el-table-column label="Tilat" show-overflow-tooltip>
            <template #default="scope">
                {{ formatTilat(scope.row.tilat) }}
            </template>
        </el-table-column>
    </el-table>
</template>

<script lang="ts">
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { useHuonekorttiTable } from "./huonekorttiTable"
import huonekorttiTableStore from "./huonekorttiTableStore"
import { defineComponent, PropType, ref } from "vue"
import { ElTable } from "element-plus"
import ComPlusButton from "@/components/common/buttons/ComPlusButton.vue"
import ComMinusButton from "@/components/common/buttons/ComMinusButton.vue"
import ComStatusTag from "@/components/common/ComStatusTag.vue"

export default defineComponent({
    components: {
        ComPlusButton,
        ComMinusButton,
        ComStatusTag,
    },
    props: {
        huonekortit: {
            type: Object as PropType<HuonekorttiResponse[]> | undefined,
        },
        selection: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { formatTilat, huonekorttiIdsSelected } = useHuonekorttiTable()
        const setSelected = huonekorttiTableStore.methods.setSelected
        const getSelected = huonekorttiTableStore.getters.getSelected
        const multipleTableRef = ref<InstanceType<typeof ElTable>>()

        const getAllIdsFromPage = () => {
            return multipleTableRef.value?.data.map((d) => parseInt(d.id))
        }

        const selectAll = () => {
            const pIds = getAllIdsFromPage()
            const oIds = getSelected()

            if (pIds) {
                const nIds = oIds.concat(pIds)
                const set = new Set(nIds)
                setSelected([...set])
            }
        }

        const unselectAll = () => {
            const pIds = getAllIdsFromPage()
            const oIds = getSelected()

            if (pIds) {
                setSelected(oIds.filter((i) => (pIds.indexOf(i) != -1 ? false : true)))
            }
        }

        return {
            huonekorttiIdsSelected,
            multipleTableRef,
            selectAll,
            formatTilat,
            unselectAll,
        }
    },
})
</script>
