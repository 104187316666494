import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-margin-bottom" }

import { HuonekorttiRequest } from "@/api/models/huonekortti-request"
import { TilaResponse } from "@/api/models/tila-response"
import { ref, reactive, watchEffect, watch } from "vue"
import ComPlusButton from "../../common/buttons/ComPlusButton.vue"
import ComMinusButton from "../../common/buttons/ComMinusButton.vue"
import { ElMessage, FormValidateCallback, type ElForm } from "element-plus"
import ComMallihuonekortitSelect from "@/components/mallihuonekortit/ComMallihuonekortitSelect.vue"
import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"
import { hankePage } from "@/views/hanke"
import { huonekorttiListing } from "./huonekortit"
import { useTilanimikeet } from "@/methods/tilanimikeComposables"
import { useAddHuonekortti, useAddHuonekorttiFromMallihuonekortti } from "@/methods/huonekorttiComposablses"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLisaaHuonekortti',
  setup(__props) {

const { writableVastuuyksikot, hanke } = hankePage()
const { addTila, removeTila } = huonekorttiListing()
const {
    addHuonekortti,
    error: addHuonekorttiError,
    loading: addHuonekorttiLoading,
    response: addHuonekorttiResponse,
} = useAddHuonekortti()
const {
    addHuonekorttiFromMallihuonekortti,
    error: addHuonekorttiFromMallihuonekorttiError,
    loading: addHuonekorttiFromMallihuonekorttiLoading,
    response: addHuonekorttiFromMallihuonekorttiResponse,
} = useAddHuonekorttiFromMallihuonekortti()
const { tilanimikkeet } = useTilanimikeet()
const addHuonekorttiRef = ref<typeof ElForm>()
const mallihuonekortti = ref<MallihuonekorttiResponse | undefined>()
const huonekortti = reactive({
    toiminta: "",
    vastuuyksikko: {
        id: undefined,
    },
    tilanimike: {
        id: undefined,
    },
    tilat: [] as TilaResponse[],
}) as HuonekorttiRequest
const rules = ref({
    tilanimike: [
        {
            required: true,
            trigger: "change",
            type: "object",
            fields: {
                id: {
                    type: "number",
                    required: true,
                    message: "Valitse tilanimike!",
                },
            },
        },
    ],
    toiminta: [
        {
            required: false,
            max: 100,
        },
    ],
    vastuuyksikko: [
        {
            required: true,
            trigger: "change",
            type: "object",
            fields: {
                id: {
                    type: "number",
                    required: true,
                    message: "Valitse vastuuyksikkö!",
                },
            },
        },
    ],
})

const validateAndAddHuonekortti = async () => {
    addHuonekorttiRef.value?.validate(async (valid: FormValidateCallback) => {
        if (!valid) {
            return false
        } else {
            huonekortti.tilat?.forEach((t) => (t.vastuuyksikko = { id: huonekortti.vastuuyksikko.id }))

            if (!mallihuonekortti.value) {
                addHuonekortti(huonekortti)
            } else {
                addHuonekorttiFromMallihuonekortti(
                    huonekortti.vastuuyksikko.id,
                    mallihuonekortti.value.id,
                    huonekortti.tilat
                )
            }
        }
    })
}
const resetFields = () => {
    if (addHuonekorttiRef.value) {
        addHuonekorttiRef.value.resetFields()

        huonekortti.toiminta = ""
        huonekortti.vastuuyksikko = { id: undefined }
        huonekortti.tilanimike = { id: undefined }
        mallihuonekortti.value = undefined

        huonekortti.tilat = []

        addTila(huonekortti)
    }
}

const mallihuonekorttiSelected = (val: MallihuonekorttiResponse | undefined) => {
    mallihuonekortti.value = val
    huonekortti.toiminta = val ? val.toiminta : ""
}

watchEffect(() => {
    if (hanke.value && huonekortti.tilat?.length === 0) {
        addTila(huonekortti)
    }
})
watch(addHuonekorttiLoading, (val) => {
    if (!val && addHuonekorttiResponse.value) {
        ElMessage.success("Huonekortti lisätty")

        resetFields()
    }
})
watch(addHuonekorttiError, (val) => {
    if (val) {
        ElMessage.error("Huonekortin lisäys epäonnistui")
    }
})
watch(addHuonekorttiFromMallihuonekorttiLoading, (val) => {
    if (!val && addHuonekorttiFromMallihuonekorttiResponse.value) {
        ElMessage.success("Huonekortti lisätty")

        resetFields()
    }
})
watch(addHuonekorttiFromMallihuonekorttiError, (val) => {
    if (val) {
        ElMessage.error("Huonekortin lisäys epäonnistui")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      inline: "",
      "label-position": "top",
      rules: rules.value,
      ref_key: "addHuonekorttiRef",
      ref: addHuonekorttiRef,
      model: huonekortti
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          lg: 12,
          sm: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  lg: 8,
                  sm: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Tilanimike",
                      prop: "tilanimike"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          size: "small",
                          modelValue: huonekortti.tilanimike.id,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((huonekortti.tilanimike.id) = $event)),
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tilanimikkeet), (tilanimike) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: tilanimike.id,
                                label: tilanimike.nimi,
                                value: tilanimike.id
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  lg: 8,
                  sm: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Täsmennys",
                      prop: "toiminta"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          size: "small",
                          modelValue: huonekortti.toiminta,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((huonekortti.toiminta) = $event)),
                          maxlength: 100,
                          disabled: mallihuonekortti.value != undefined
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  lg: 8,
                  sm: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Vastuuyksikkö",
                      prop: "vastuuyksikko"
                    }, {
                      default: _withCtx(() => [
                        (_unref(writableVastuuyksikot))
                          ? (_openBlock(), _createBlock(_component_el_select, {
                              key: 0,
                              size: "small",
                              modelValue: huonekortti.vastuuyksikko.id,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((huonekortti.vastuuyksikko.id) = $event)),
                              style: {"width":"100%"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(writableVastuuyksikot), (vastuuyksikko) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: vastuuyksikko.id,
                                    value: vastuuyksikko.id,
                                    label: vastuuyksikko.nimi
                                  }, null, 8, ["value", "label"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_unref(hanke)?.sairaala?.id && huonekortti.tilanimike.id)
                  ? (_openBlock(), _createBlock(ComMallihuonekortitSelect, {
                      key: 0,
                      tilanimike: huonekortti.tilanimike.id,
                      sairaala: _unref(hanke).sairaala.id,
                      onMallihuonekorttiSelected: mallihuonekorttiSelected
                    }, null, 8, ["tilanimike", "sairaala"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 10,
          sm: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "tilat" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(huonekortti.tilat, (tila, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    _createVNode(_component_el_col, {
                      span: 3,
                      style: {"text-align":"center"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: index == 0 ? 'Tilat' : ''
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(index + 1), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: index == 0 ? 'Tilatunnus' : ''
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: tila.tilatunnus,
                              "onUpdate:modelValue": ($event: any) => ((tila.tilatunnus) = $event),
                              size: "small",
                              placeholder: "Tilatunnus"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 7,
                      offset: 1
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: index == 0 ? 'Pinta-ala m2' : ''
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: tila.pintaAla,
                              "onUpdate:modelValue": ($event: any) => ((tila.pintaAla) = $event),
                              size: "small",
                              placeholder: "Pinta-ala m2",
                              precision: 2,
                              step: 0.1,
                              type: "number"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 4,
                      offset: 1
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: index == 0 ? ' ' : ''
                        }, {
                          default: _withCtx(() => [
                            (index + 1 === huonekortti.tilat?.length)
                              ? (_openBlock(), _createBlock(ComPlusButton, {
                                  key: 0,
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(addTila)(huonekortti)))
                                }))
                              : _createCommentVNode("", true),
                            (index + 1 !== huonekortti.tilat?.length)
                              ? (_openBlock(), _createBlock(ComMinusButton, {
                                  key: 1,
                                  onClick: ($event: any) => (_unref(removeTila)(huonekortti, index))
                                }, null, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]),
                      _: 2
                    }, 1024)
                  ], 64))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          lg: 2,
          sm: 24
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: " " }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "success",
                  size: "small",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (validateAndAddHuonekortti()))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Lisää")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rules", "model"])
  ]))
}
}

})