<template>
    <el-popover :title="title" trigger="click" width="30%">
        <slot></slot>
        <template #reference>
            <ComInfoButton />
        </template>
    </el-popover>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ComInfoButton from "../common/buttons/ComInfoButton.vue"

export default defineComponent({
    components: {
        ComInfoButton,
    },
    props: {
        title: {
            type: String,
            required: false,
        },
    },
    setup() {
        return {}
    },
})
</script>
