<template>
    <ComFilterInput v-model="filter"></ComFilterInput>
    <ComLisaaOikeus />
    <el-row class="row-margin">
        <el-col>
            <ComSuccessButton @click="lisaaKayttajaModal = true">Lisää uusi käyttäjä</ComSuccessButton>
        </el-col>
    </el-row>
    <el-drawer
        :title="editedKayttaja ? 'Muokkaa käyttäjän tietoja' : 'Lisää käyttäjä'"
        v-model="lisaaKayttajaModal"
        destroy-on-close
        @close="kayttajaModalClose">
        <ComLisaaKayttaja/>
    </el-drawer>
    <el-divider />
    <template v-if="filteredOikeudet && selectedVastuuyksikot && selectedVastuuyksikot.length > 0">
        <el-row>
            <div class="oikeus-tag-wrapper">
                <el-tag class="tag" type="success">L</el-tag>
                = Lukuoikeus
            </div>
            <div class="oikeus-tag-wrapper">
                <el-tag class="tag" type="success">K</el-tag>
                = Kirjoitusoikeus
            </div>
            <div>
                <el-tag class="tag" type="success">R</el-tag>
                = Raportointioikeus
            </div>
        </el-row>
        <table class="el-table">
            <thead>
                <tr class="el-table__row">
                    <th class="el-table__cell name_coll" style="min-width: 200px">Nimi</th>
                    <th
                        class="el-table__cell"
                        style="
                            max-width: 200px;
                            min-width: 200px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            padding-right: 20px;
                        "
                        v-for="(vastuuyksikko, index) in selectedVastuuyksikot"
                        :key="index">
                        {{ vastuuyksikko.nimi }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="filteredOikeudet">
                <tr class="el-table__row" v-for="(kayttaja, index) in filteredOikeudet" :key="index" >
                    <td class="el-table__cell name_coll">
                        {{ kayttaja.etunimi }} {{ kayttaja.sukunimi }}
                        <ComDeleteButton @click="deleteOikeudetClick(kayttaja)" />
                    </td>
                    <td class="el-table__cell" v-for="(vastuuyksikko, index) in selectedVastuuyksikot" :key="index">
                        <ComOikeusTableButtons
                            :kayttaja="kayttaja.id"
                            :vastuuyksikko="vastuuyksikko.id"
                            :oikeus="getVastuuyksikkoOikeus(kayttaja.oikeudet, vastuuyksikko.id)"
                            @update-oikeus="updateOikeus"
                            @add-oikeus="addOikeus"
                            @delete-oikeus="deleteOikeus" />
                    </td>
                </tr>
            </tbody>
        </table>
    </template>
</template>

<script setup lang="ts">
import ComOikeusTableButtons from "./ComOikeusTableButtons.vue"
import ComDeleteButton from "@/components/common/buttons/ComDeleteButton.vue"
import ComLisaaOikeus from "./ComLisaaOikeus.vue"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComLisaaKayttaja from "./kayttaja/ComLisaaKayttaja.vue"
import { useOikeudetComponent } from "./oikeudet"
import { KayttajaOikeusResponse } from "@/api/models/kayttaja-oikeus-response"

const {filter, editedKayttaja, filteredOikeudet, selectedVastuuyksikot, lisaaKayttajaModal, deleteOikeus, deleteOikeudet, getVastuuyksikkoOikeus, addOikeus, updateOikeus, kayttajaModalClose} = useOikeudetComponent()

const deleteOikeudetClick = (kayttaja: KayttajaOikeusResponse) => {
    const ids = kayttaja.oikeudet?.map(o => o.id)
    
    if(ids) {
        deleteOikeudet(ids)
    }
}

const ddd = () => {
    console.log("ddd")

}


</script>

<style scoped>
thead th {
    position: sticky !important;
    top: 0px;
    z-index: 2 !important;
}
table {
    max-height: 800px;
    overflow: auto;
    display: block;
}
.name_coll {
    position: sticky !important;
    left: 0px;
    z-index: 2 !important;
    background-color: white;
}

th.name_coll {
    z-index: 3 !important;
}
</style>
