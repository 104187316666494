import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { useHuonekorttiHistoria, useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import HistoriaNimike from "@/types/HistoriaNimike"
import Orientation from "@/types/OrientationEnum"
import { huonekorttiPage } from "@/views/huonekortti"
import { computed, ref, watch } from "vue"

interface state {
    edited: number[]
}

const state = ref<state>({
    edited: [],
})

export function useSuunnittelutiedot() {
    const { huonekortti, kirjoitus, nimikeKategoriat, setEdited } = huonekorttiPage()
    const muutoksetActive = ref(false)
    const { historia } = useHuonekorttiHistoria(
        computed(() => (!muutoksetActive.value ? undefined : huonekortti.value?.id))
    )
    const { diffHistoriaNimike } = useHuonekorttiHistoriaMethods()
    const o: Orientation = Orientation.vertical
    const active = ref<number[]>([])
    const hyvaksytty = computed(() => huonekortti?.value?.status === HuonekorttiStatusResponse.Hyvaksytty)
    const kategoriat = computed(() => nimikeKategoriat.value ?? [])
    const nimikkeet = ref<HuonekorttiNimikeResponse[] >([])
    const nimikkeetHistoria = computed(() => {
        if (historia.value) {
            const historiaNimikkeet = historia.value.nimikkeet
            const result = [] as (HistoriaNimike | HuonekorttiNimikeResponse)[]

            nimikkeet?.value.forEach((n) => {
                const hs = historiaNimikkeet?.find((h) => h.huonekorttiNimikeId == n.id)

                if (hs) {
                    result.push(diffHistoriaNimike(hs, n))
                } else {
                    result.push(n)
                }
            })

            historiaNimikkeet
                ?.filter((h) => h.tyyppi == HuonekorttiHistoriaTyyppi.Poisto)
                .forEach((p) => {
                    result.push(diffHistoriaNimike(p))
                })

            return result
        }
        return []
    })

    const hasPaakategoriaItems = (id: number) => {
        const n = muutoksetActive.value ? nimikkeetHistoria.value : nimikkeet.value

        return (
            kategoriat.value
                .find((k) => k.id == id)
                ?.children?.some((a) => n.some((i) => i.nimike?.kategoria?.id === a.id)) ?? false
        )
    }
    const hasAlikategoriaItems = (id: number) => {
        const n = muutoksetActive.value ? nimikkeetHistoria.value : nimikkeet.value

        return n.some((i) => i.nimike?.kategoria?.id === id) ?? false
    }
    const togleAll = () => {
        if (active.value.length > 0) {
            active.value = []
        } else {
            active.value = kategoriat.value.map((k) => k.id)
        }
    }
    const setEdit = (id: number) => {
        if (!state.value.edited.includes(id)) {
            state.value.edited.push(id)
        }
        if(state.value.edited.length > 0) {
            setEdited("suunnittelutiedot", true)
        }
    }
    const cancelEdit = (id: number)  => {
        if(state.value.edited.includes(id)) {
            state.value.edited = state.value.edited.filter((i) => i !== id)
        }
        if(state.value.edited.length == 0) {
            setEdited("suunnittelutiedot", false)
        }
    }

    watch(huonekortti, (val) => {
        nimikkeet.value = val?.nimikkeet ?? []
    }, { deep: true, immediate: true })

    return {
        o,
        active,
        nimikkeet,
        kirjoitus,
        hyvaksytty,
        muutoksetActive,
        nimikkeetHistoria,
        kategoriat,
        historia,
        hasPaakategoriaItems,
        hasAlikategoriaItems,
        togleAll,
        setEdit,
        cancelEdit
    }
}