<template>
    <el-tag :type="type">
        {{ label }}
    </el-tag>
</template>

<script lang="ts">
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import HuonekorttiStatusEnum from "@/types/HuonekorttiStatusEnum"

import { computed, defineComponent, PropType } from "vue"

export default defineComponent({
    props: {
        status: {
            type: String as PropType<HuonekorttiStatusResponse>,
            required: true,
        },
    },
    setup(props) {
        const type = computed(() => {
            if (props.status == HuonekorttiStatusResponse.Tyo) {
                return "info"
            }
            if(props.status == HuonekorttiStatusResponse.Julkaistu) {
                return "primary"
            }
            if (props.status == HuonekorttiStatusResponse.Odottaa) {
                return "warning"
            }
            if (props.status == HuonekorttiStatusResponse.Hyvaksytty) {
                return "success"
            }
            else {
                return "primary"
            }
        })

        const label = computed(() => {
            if (props.status == HuonekorttiStatusResponse.Tyo) {
                return HuonekorttiStatusEnum.Tyo
            }
            if (props.status == HuonekorttiStatusResponse.Julkaistu) {
                return HuonekorttiStatusEnum.Julkaistu
            }
            if (props.status == HuonekorttiStatusResponse.Odottaa) {
                return HuonekorttiStatusEnum.Odottaa
            }
            if (props.status == HuonekorttiStatusResponse.Hyvaksytty) {
                return HuonekorttiStatusEnum.Hyvaksytty
            } else {
                return ""
            }
        })

        return {
            type,
            label,
        }
    },
})
</script>
