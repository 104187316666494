<template>
    <Editor :init="disabled ? nonEditable : editable" :disabled="disabled" v-model="value" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import 'tinymce'
import Editor from "@tinymce/tinymce-vue"

/* Required TinyMCE components */
import 'tinymce/icons/default/icons.min.js';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/models/dom/model.min.js';
/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.js';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/skins/ui/oxide/content.css';
/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';

/* content UI CSS is required */
import 'tinymce/skins/ui/oxide/content.js';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import 'tinymce/skins/content/default/content.js';

export default defineComponent({
    components: {
        Editor,
    },
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const editable = {
            license_key: 'gpl',
            toolbar: 'undo redo | blocks formatselect | ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
            menubar: false,
            contextmenu: false,
        }
        const nonEditable = {
            license_key: 'gpl',
            toolbar: false,
            menubar: false,
            content_style: "",
        }
        const value = computed({
            get: () => props.modelValue,
            set: (val: string) => emit("update:modelValue", val),
        })

        return {
            nonEditable,
            editable,
            value,
        }
    },
})
</script>
