import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_More = _resolveComponent("More")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    size: "small",
    circle: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_icon, { style: {"cursor":"pointer"} }, {
        default: _withCtx(() => [
          _createVNode(_component_More)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}