<template>
    <ComKayttoEhdotConsest />
</template>

<script lang="ts">
import ComKayttoEhdotConsest from "@/components/kayttoehdot/ComKayttoehdotConsest.vue"
import { defineComponent } from "vue"

export default defineComponent({
    name: "KayttoehdotView",
    components: {
        ComKayttoEhdotConsest,
    },
    setup() {
        return {}
    },
})
</script>
