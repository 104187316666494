import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }

import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { PropType, ref } from "vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComEditButton from "../common/buttons/ComEditButton.vue"
import ComCancelButton from "../common/buttons/ComCancelButton.vue"
import { useDeleteVastuuyksikko, useUpdateVastuuyksikko } from "@/methods/vastuuyksikkoComposables"
import { ElMessageBox } from "element-plus"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeVastuuyksikko',
  props: {
    "modelValue": {type: Object as PropType<VastuuyksikkoResponse>, required: true},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

const vastuuyksikko = _useModel(__props, "modelValue")
const { updateVastuuyksikko, error } = useUpdateVastuuyksikko()
const { deleteVastuuyksikko } = useDeleteVastuuyksikko()
const edit = ref(false)

const updateVastuuyksikkoClick = async () => {
    updateVastuuyksikko(vastuuyksikko.value.id, vastuuyksikko.value)
}

const deleteVaastuuyksikkoClick = async (vastuuyksikkoId: number) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti poistaa vastuuyksikön?", "Vahvista poisto", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (confirm) {
        deleteVastuuyksikko(vastuuyksikkoId)
    }
}



return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_row = _resolveComponent("el-row")!

  return (vastuuyksikko.value)
    ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 10 }, {
            default: _withCtx(() => [
              (edit.value)
                ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        size: "small",
                        modelValue: vastuuyksikko.value.nimi,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vastuuyksikko.value.nimi) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(vastuuyksikko.value.nimi), 1))
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 12,
            offset: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_space, null, {
                    default: _withCtx(() => [
                      (!edit.value)
                        ? (_openBlock(), _createBlock(ComDeleteButton, {
                            key: 0,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (deleteVaastuuyksikkoClick(vastuuyksikko.value.id)))
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Poista")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!edit.value)
                        ? (_openBlock(), _createBlock(ComEditButton, {
                            key: 1,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (edit.value = true))
                          }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("Muokkaa")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (edit.value)
                        ? (_openBlock(), _createBlock(ComCancelButton, {
                            key: 2,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (edit.value = false))
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode("Peruuta")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (edit.value)
                        ? (_openBlock(), _createBlock(ComSuccessButton, {
                            key: 3,
                            onClick: updateVastuuyksikkoClick
                          }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Tallenna")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})