<template>
    <template v-if="historia">
        <el-tabs>
            <el-tab-pane label="Toimintatiedot">
                <template v-if="historia.huonekortti && historia.huonekortti.length > 0">
                    <ComHuonekorttiHistoria :data="historia.huonekortti" />
                </template>
                <ComEmpty v-else />
            </el-tab-pane>
            <el-tab-pane label="Suunnittelutiedot">
                <template v-if="historia.nimikkeet && historia.nimikkeet.length > 0">
                    <ComHuonekorttiNimikeHistoria :data="historia.nimikkeet" />
                </template>
                <ComEmpty v-else />
            </el-tab-pane>
            <el-tab-pane label="Tilat">
                <template v-if="historia.tilat && historia.tilat.length > 0">
                    <ComTilaHistoria :data="historia.tilat" />
                </template>
                <ComEmpty v-else />
            </el-tab-pane>
        </el-tabs>
    </template>
    <com-loading v-else />
</template>

<script setup lang="ts">
import ComLoading from "@/components/common/ComLoading.vue"
import { computed } from "vue"
import ComHuonekorttiNimikeHistoria from "./suunnittuelutiedot/ComHuonekorttiNimikeHistoria.vue"
import ComHuonekorttiHistoria from "./toimintatiedot/ComHuonekorttiHistoria.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComTilaHistoria from "./tilat/ComTilaHistoria.vue"
import { huonekorttiPage } from "@/views/huonekortti"
import { useHuonekorttiHistoria } from "@/methods/huonekorttiHistoriaComposables"

const { huonekortti } = huonekorttiPage()
const id = computed(() => huonekortti.value?.id)
const { historia } = useHuonekorttiHistoria(id)

</script>
