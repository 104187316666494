import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"
import { ref } from "vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { FormInstance } from "element-plus"
import { useYllapitoComponent } from "./yllapito";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeVastuuyksikkoAdd',
  setup(__props) {

const { addVastuuyksikko } = useYllapitoComponent()
const vastuuyksikko = ref({} as VastuuyksikkoRequest)
const form = ref<FormInstance>()
const rules = {
    nimi: [{ required: true, message: "Vastuuyksikkö on pakollinen", trigger: "blur" }],
}

const addVastuuyksikkoClick = async () => {
    const valid = await form.value?.validate()

    if (valid) {
        addVastuuyksikko(vastuuyksikko.value)

        vastuuyksikko.value = {}
    } else {
        return false
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    model: vastuuyksikko.value,
    rules: rules,
    ref_key: "form",
    ref: form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Vastuuyksikkö",
        prop: "nimi"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                size: "small",
                modelValue: vastuuyksikko.value.nimi,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vastuuyksikko.value.nimi) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(ComSuccessButton, { onClick: addVastuuyksikkoClick }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Lisää")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})