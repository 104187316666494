<template>
    <el-form :model="vastuuyksikko" :rules="rules" ref="form">
        <el-form-item label="Vastuuyksikkö" prop="nimi">
            <el-space>
                <el-input size="small" v-model="vastuuyksikko.nimi" />
                <ComSuccessButton @click="addVastuuyksikkoClick">Lisää</ComSuccessButton>
            </el-space>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"
import { ref } from "vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { FormInstance } from "element-plus"
import { useYllapitoComponent } from "./yllapito";

const { addVastuuyksikko } = useYllapitoComponent()
const vastuuyksikko = ref({} as VastuuyksikkoRequest)
const form = ref<FormInstance>()
const rules = {
    nimi: [{ required: true, message: "Vastuuyksikkö on pakollinen", trigger: "blur" }],
}

const addVastuuyksikkoClick = async () => {
    const valid = await form.value?.validate()

    if (valid) {
        addVastuuyksikko(vastuuyksikko.value)

        vastuuyksikko.value = {}
    } else {
        return false
    }
}
</script>
