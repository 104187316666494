import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card-margin-bottom"
}

import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiTableWithFilterAndPagination from "@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"
import { setNodes, useNimikeKategoriaData } from "@/methods/nimikkeetComposables";
import { ref, toRef, watch, watchEffect } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeRaportti',
  emits: ["huonekorteittain", "nimikkeet"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const { nimikeKategoriat } = useNimikeKategoriaData(toRef(true))
const nimikkeet = ref()
const valitutNimikkeet = ref<[number[]]>()
const huonekorteittain = ref(false)

watch(valitutNimikkeet, () => {
    let n: number[] = []

    if (valitutNimikkeet.value) {
        n = valitutNimikkeet.value?.map((v: number[]) => {
            return v[2]
        })
    }

    emit("nimikkeet", n)
})

watch(huonekorteittain, () => {
    emit("huonekorteittain", huonekorteittain.value)
})

watchEffect(() => {
    nimikkeet.value = setNodes(nimikeKategoriat.value ?? [], true)
})


return (_ctx: any,_cache: any) => {
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_divider = _resolveComponent("el-divider")!

  return (!nimikkeet.value)
    ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader_panel, {
                  options: nimikkeet.value,
                  props: {
                    disabled: false,
                    multiple: true,
                },
                  modelValue: valitutNimikkeet.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valitutNimikkeet).value = $event))
                }, null, 8, ["options", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  label: "Valitse erikseen raportoitavat huonekortit",
                  modelValue: huonekorteittain.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((huonekorteittain).value = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (huonekorteittain.value)
          ? (_openBlock(), _createBlock(_component_el_divider, { key: 0 }))
          : _createCommentVNode("", true),
        (huonekorteittain.value)
          ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(ComHuonekorttiTableWithFilterAndPagination, { selection: true })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
}
}

})