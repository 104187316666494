import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import ComLoading from "@/components/common/ComLoading.vue"
import { computed } from "vue"
import ComHuonekorttiNimikeHistoria from "./suunnittuelutiedot/ComHuonekorttiNimikeHistoria.vue"
import ComHuonekorttiHistoria from "./toimintatiedot/ComHuonekorttiHistoria.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComTilaHistoria from "./tilat/ComTilaHistoria.vue"
import { huonekorttiPage } from "@/views/huonekortti"
import { useHuonekorttiHistoria } from "@/methods/huonekorttiHistoriaComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHistoria',
  setup(__props) {

const { huonekortti } = huonekorttiPage()
const id = computed(() => huonekortti.value?.id)
const { historia } = useHuonekorttiHistoria(id)


return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_unref(historia))
    ? (_openBlock(), _createBlock(_component_el_tabs, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, { label: "Toimintatiedot" }, {
            default: _withCtx(() => [
              (_unref(historia).huonekortti && _unref(historia).huonekortti.length > 0)
                ? (_openBlock(), _createBlock(ComHuonekorttiHistoria, {
                    key: 0,
                    data: _unref(historia).huonekortti
                  }, null, 8, ["data"]))
                : (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { label: "Suunnittelutiedot" }, {
            default: _withCtx(() => [
              (_unref(historia).nimikkeet && _unref(historia).nimikkeet.length > 0)
                ? (_openBlock(), _createBlock(ComHuonekorttiNimikeHistoria, {
                    key: 0,
                    data: _unref(historia).nimikkeet
                  }, null, 8, ["data"]))
                : (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { label: "Tilat" }, {
            default: _withCtx(() => [
              (_unref(historia).tilat && _unref(historia).tilat.length > 0)
                ? (_openBlock(), _createBlock(ComTilaHistoria, {
                    key: 0,
                    data: _unref(historia).tilat
                  }, null, 8, ["data"]))
                : (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ComLoading, { key: 1 }))
}
}

})