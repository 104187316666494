<template>
    <div
        element-loading-background="transparent"
        style="background-color: transparent; min-height: 50px">
        <template v-if="loading">
            <div style="padding-top: 100px;" v-loading="true" />
        </template>
        <template v-else-if="huonekortit && huonekortit.length === 0">
            <ComEmpty />
        </template>
        <template v-else-if="checkbox && huonekortit">
            <el-checkbox-group v-model="checked">
                <div v-for="huonekortti in huonekortit" :key="huonekortti.id" class="side-menu-huonekortti">
                    <el-checkbox :value="huonekortti.id">
                        <ComTooltip :content="formatLinkWithTilat(huonekortti)">
                            <div>{{ formatLinkWithTilat(huonekortti) }}</div>
                        </ComTooltip>
                        <div style="display: inline-block">
                            <div class="side-menu-huonekortti-subtext">{{ huonekortti.toiminta }}</div>
                        </div>
                    </el-checkbox>
                </div>
            </el-checkbox-group>
        </template>
        <template v-else-if="huonekortit">
            <div v-for="huonekortti in huonekortit" :key="huonekortti.id" class="side-menu-huonekortti">
                <el-icon size="36px" class="side-menu-huonekortti-icon">
                    <Document></Document>
                </el-icon>
                <div>
                    <router-link
                        :to="{ name: 'Huonekortti', params: { huonekorttiId: huonekortti.id } }"
                        v-slot="{ href, navigate }"
                        custom>
                        <el-link type="primary" :href="href" @click="navigate" :underline="false">
                            <ComTooltip :content="formatLinkWithTilat(huonekortti)">
                                <div>{{ formatLinkWithTilat(huonekortti) }}</div>
                            </ComTooltip>
                        </el-link>
                    </router-link>
                    <div style="display: inline-block">
                        <div class="side-menu-huonekortti-subtext">{{ huonekortti.toiminta }}</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script setup lang="ts">
import { ref, watch, watchEffect } from "vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { Document } from "@element-plus/icons-vue"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { useHuonekorttiFormatLinkWithTilat } from "@/methods/huonekorttiComposablses"
import { useComSideMenu } from "./sideMenu"

const props = defineProps<{
    id: number
    page: number
    filter: string
    checkbox: boolean
}>()
const emit = defineEmits(["totalCount"])
const { huonekortit, setAllFilters, paramsObject, total, loading } = useHuonekortit()
const { formatLinkWithTilat } = useHuonekorttiFormatLinkWithTilat()
const checked = ref([])
const { add, remove  } = useComSideMenu()

watchEffect(() => {
    setAllFilters(props.filter)
    paramsObject.value.vastuuyksikot = [props.id]
    paramsObject.value.paging = { currentPage: props.page, pageSize: 30 }

    emit("totalCount", total.value, paramsObject.value.paging.currentPage)
})

watch(checked, (n, o) => {
    if (n.length > o.length) {
        add(n.filter((n) => !o.includes(n))[0])
    } else if (o.length > n.length) {
        remove(o.filter((o) => !n.includes(o))[0])
    }
})

</script>
