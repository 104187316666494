<template>
    <el-row>
        <el-col :span="24">
            <el-descriptions :title="title" v-if="!edit" direction="horizontal" :column="2" border>
                <template #extra>
                    <el-button v-if="editable && !edit" type="success" size="small" @click="edit=true">Muokkaa</el-button>
                </template>
                <el-descriptions-item label="Hankkeen nimi">{{ hanke?.nimi }}</el-descriptions-item>
                <el-descriptions-item label="Hankenumero">{{ hanke?.hankenumero }}</el-descriptions-item>
                <el-descriptions-item label="Hankkeen työnumero">{{ hanke?.tyonumero }}</el-descriptions-item>
                <el-descriptions-item v-if="vastuuyksikko" label="Vastuuyksikkö">
                    {{ vastuuyksikko.nimi }}
                </el-descriptions-item>
                <el-descriptions-item label="Organisaatio">{{ hanke?.sairaala?.nimi }}</el-descriptions-item>
                <template v-if="huonekortti">
                    <el-descriptions-item label="Tilanimike">{{ huonekortti.tilanimike?.nimi }}</el-descriptions-item>
                    <el-descriptions-item label="Tilanimikkeen täsmennys">{{ huonekortti.toiminta }}</el-descriptions-item>
                    <el-descriptions-item label="Status"><ComStatusTag :status="huonekortti.status" /></el-descriptions-item>
                    <el-descriptions-item label="Koskee tiloja">{{ tilat }}</el-descriptions-item>
                    <el-descriptions-item label="Luotu">{{ huonekortti.lisaysAika }}</el-descriptions-item>
                </template>
            </el-descriptions>
            <ComHanketiedotHankeEdit v-model="edit" v-else />
        </el-col>
    </el-row>
    <div>
        <ComHanketiedotVaatimukset />
    </div>
</template>

<script setup lang="ts">
import ComHanketiedotVaatimukset from "./ComHanketiedotVaatimukset.vue"
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response";
import { computed, PropType, ref, watchEffect, } from "vue";
import { useHanketiedotComponent } from "./hanketiedot";
import ComStatusTag from "@/components/common/ComStatusTag.vue";
import ComHanketiedotHankeEdit from "./ComHanketiedotHankeEdit.vue";

const props = defineProps({
    editable: {
        type: Boolean,
        required: true,
    },
    huonekorttiId: {
        type: Number,
        required: false,
    },
    hankeId: {
        type: Number,
        required: false,
    },
    vastuuyksikot: {
        type: Object as PropType<VastuuyksikkoResponse[]>,
        required: false,
    }
})

const { editable: _editable, hankeId: _hankeId, huonekorttiId: _huonekorttiId, vastuuyksikot: _vastuuyksikot, vastuuyksikko, huonekortti, hanke, tilat } = useHanketiedotComponent()
const edit = ref(false)
const title = computed(() => "Hanketiedot" + (huonekortti.value?.tilanimike ? ` ja ${huonekortti.value.tilanimike?.nimi?.toLowerCase()}n tiedot` : ""))

watchEffect(() => {
    _hankeId.value = props.hankeId
    _huonekorttiId.value = props.huonekorttiId
    _editable.value = props.editable
    _vastuuyksikot.value = props.vastuuyksikot
})

</script>
