<template>
    <el-tree :data="hankkeet" node-key="id" class="side-menu-tree" :highlight-current="false">
        <template #default="{ node, data }">
            <template v-if="node.level === 2">
                <span :class="{ 'arkistoitu-text': node.parent.data.arkistoitu }" style="overflow: hidden">
                    <ComSideMenuVastuuyksikko :data="data" :checkbox="true" />
                </span>
            </template>
            <template v-else>
                <div class="side-menu-hanke" :class="{ 'arkistoitu-text': data.arkistoitu }">
                    <ComTooltip :content="data.nimi">
                        <span class="node_title">{{ data.nimi }}</span>
                    </ComTooltip>
                </div>
            </template>
        </template>
    </el-tree>
</template>

<script setup lang="ts">
import { computed, toRef } from "vue"
import ComSideMenuVastuuyksikko from "@/components/layout/SideMenu/ComSideMenuVastuuyksikko.vue"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHankkeet } from "@/methods/hankeComposables"

const {hankkeet : data} = useHankkeet(toRef(true))
const hankkeet = computed(() => {
    return data.value?.map((h) => {
        return {
            id: h.id,
            nimi: h.nimi,
            children: h.vastuuyksikot?.map((v) => {
                return {
                    id: v.id,
                    nimi: v.nimi,
                }
            }),
        }
    })
})

</script>
<style scoped>
.side-menu-tree >>> .el-tree-node__content {
    height: auto !important;
    align-items: flex-start;
    flex-wrap: wrap;
}
.break {
    flex-basis: 100%;
    height: 0;
}
.side-menu-tree >>> .el-tree-node__expand-icon.is-leaf {
    display: none;
}
.side-menu-logo {
    width: 150px;
    margin: 0px 10px;
}
.side-menu-container {
    padding: 0px 10px;
}
.side-menu-header {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.side-menu-expand {
    margin-left: auto;
    order: 2;
}
</style>
