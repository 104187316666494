import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from "vue"
import { LiiteRequest } from "@/api/models/liite-request"
import { ElMessage, UploadUserFile } from "element-plus"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComUploadFile from "../common/ComUploadFile.vue"
import { useAddLiite } from "@/methods/liiteComposables"
import { useLiiteComponent } from "./liite"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLiiteAdd',
  setup(__props) {

const { addLiite, loading, error, response } = useAddLiite()
const { tyyppi, viite, liitteet } = useLiiteComponent()
const liite = ref<LiiteRequest>({} as LiiteRequest)
const file = ref<UploadUserFile[]>([])

const addFile = async () => {
    if (liitteet.value && liitteet.value.length > 4) {
        ElMessage.error("Maksimissaan 5 liitettä per huonekortti!")
    } else if (!file.value[0]) {
        ElMessage.error("Valitse tiedosto")
    } else if (file.value[0].size && file.value[0].size > 5000000){
        ElMessage.error("Tiedosto on liian suuri")
    } else if (viite.value && tyyppi.value && file.value[0].raw) {
        liite.value.tyyppi = { id: tyyppi.value }
        liite.value.viite = { id: viite.value }

        addLiite(file.value[0].raw, liite.value)
    }
}

watch(loading, () => {
    if (response.value) {
        ElMessage.success("Liite lisätty")

        liite.value = {}
        file.value = []
    }
})

watch(error, () => {
    if (error.value) {
        ElMessage.error("Liitteen lisäys epäonnistui")
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "80px" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Nimi" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: liite.value.nimi,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((liite.value.nimi) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Seloste" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: liite.value.seloste,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((liite.value.seloste) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Tiedosto" }, {
        default: _withCtx(() => [
          _createVNode(ComUploadFile, {
            style: {"width":"100%"},
            drag: "",
            modelValue: file.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((file).value = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(ComSuccessButton, { onClick: addFile }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Lisää liite")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})