<template>
    <el-timeline v-if="show">
        <template v-for="muutos in historia" :key="muutos.id">
            <ComHuonekorttiHistoriaItem :data="currentData(muutos.id!)" :previousData="muutos" />
        </template>
    </el-timeline>
    <ComEmpty v-else />
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaResponse } from "@/api/models/huonekortti-historia-response"
import { computed } from "vue"
import ComHuonekorttiHistoriaItem from "./ComHuonekorttiHistoriaItem.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { huonekorttiPage } from "@/views/huonekortti"

const props = defineProps<{
    data: HuonekorttiHistoriaResponse[]
}>()

const { huonekortti } = huonekorttiPage()
const show = computed(() => {
    return historia.value && historia.value.length > 0
})
const historia = computed(() => {
    const temp = props.data

    return temp.sort((a, b) => a.id! - b.id!).reverse()
})

const currentData = (id: number) => {
    let temp: HuonekorttiHistoriaResponse | undefined = undefined
    const i = historia.value.findIndex((h) => h.id == id)

    temp = historia.value[i - 1]

    return temp ?? huonekortti?.value
}
</script>
