<template>
    <el-icon el-icon :size="20" style="cursor: pointer"><InfoFilled /></el-icon>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { InfoFilled } from "@element-plus/icons-vue"

export default defineComponent({
    components: {
        InfoFilled,
    },
})
</script>
