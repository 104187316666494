import { auth0Client } from "@/auth/auth0-plugin"
import { computed, ref } from "vue"
import { useQuery } from "@tanstack/vue-query"
import http from "@/http-common"
import { PaakayttajaAndOikeusResponse } from "@/api/models/paakayttaja-and-oikeus-response"

const superUser = ref<boolean | undefined>(undefined)

export function useOikeudet() {
    const key = ["/kayttajat/oikeudet"]
    const { superUser } = useSuperUserOikeudet()

    const fetcher = async () : Promise<PaakayttajaAndOikeusResponse>  => {
        return (await http.get('/kayttajat/oikeudet')).data
    }

    const { data: oikeudet, error, isLoading: loading } = useQuery({ queryKey: key, queryFn: () => fetcher()})

    const paakayttajaHankkeet = computed(() => {
        return oikeudet?.value?.hankePaakayttaja?.map((v) => v.id) ?? []
    })
    const paakayttajaVastuuyksikot = computed(() => {
        return oikeudet?.value?.hankePaakayttaja?.flatMap((v) => v.vastuuyksikot?.map((v) => v.id)).filter(Boolean) ?? []
    })
    const writableVastuuyksikot = computed(() => {
        const w = oikeudet?.value?.vastuuyksikkoOikeudet?.filter((v) => v.kirjoitus).map((v) => v.vastuuyksikko?.id).filter(Boolean) ?? []

        return [...new Set([...w, ...paakayttajaVastuuyksikot.value])]
    })
    const readableVastuuyksikot = computed(() => {
        const r =  oikeudet?.value?.vastuuyksikkoOikeudet?.filter((v) => v.luku)?.map((v) => v.vastuuyksikko?.id) ?? []

        return [...new Set([...r, ...paakayttajaVastuuyksikot.value])]
    })
    const raportointiVastuuyksikot = computed(() => {
        const r = oikeudet?.value?.vastuuyksikkoOikeudet?.filter((v) => v.raportointi)?.map((v) => v.vastuuyksikko?.id) ?? []

        return [...new Set([...r, ...paakayttajaVastuuyksikot.value])]
    })

    return {
        oikeudet,
        error,
        loading,
        paakayttajaHankkeet,
        paakayttajaVastuuyksikot,
        writableVastuuyksikot,
        readableVastuuyksikot,
        raportointiVastuuyksikot,
        superUser
    }
}

export function useSuperUserOikeudet() {
    const get = async () => {
        const tokens = await auth0Client.value?.getIdTokenClaims()
        superUser.value = await (tokens?.["https://vahtihuonekortti.fi/roles"] as string[]).includes("Admin")
    }

    if (superUser.value === undefined) get()

    return {
        superUser,
    }
}
