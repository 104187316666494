import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "float-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowUp = _resolveComponent("ArrowUp")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "always",
    class: _normalizeClass(!_ctx.s ? _ctx.tiedotHideBody : '')
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.s)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                class: "expandButton",
                onClick: _ctx.expand,
                type: "info",
                plain: "",
                circle: "",
                size: "small"
              }, {
                default: _withCtx(() => [
                  (_ctx.way == 'vertical')
                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowUp)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowLeft)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (!_ctx.s)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                class: "expandButton",
                onClick: _ctx.expand,
                type: "info",
                plain: "",
                circle: "",
                size: "small"
              }, {
                default: _withCtx(() => [
                  (_ctx.way == 'vertical')
                    ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowDown)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ArrowRight)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}