import { KommenttiResponse } from "@/api/models/kommentti-response"
import kommenttiTyyppi from "@/types/Kommentti"
import { computed, ref, Ref } from "vue"
import http from "@/http-common"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { LiiteRequest } from "@/api/models/liite-request"
import { LiiteId } from "@/api/models/liite-id"
import { LiiteResponse } from "@/api/models/liite-response"
import { UploadRawFile } from "element-plus"
import { LiitetiedostoResponse } from "@/api/models/liitetiedosto-response"

const url = "liitteet"

export function useLiitteet(viite: Ref<number | undefined>, tyyppi: Ref<kommenttiTyyppi | undefined>) {
    const key = computed(() => [url, { viite: viite.value, tyyppi: tyyppi.value }]) 
    const enabled = computed(() => {
        if(viite.value && tyyppi.value) {
            return true
        } else {
            return false
        }
    })

    const fetcher = async (): Promise<KommenttiResponse[]> => {
        return (await http.get(url, { params: { viite: viite.value, tyyppi: tyyppi.value } })).data
    }

    const {
        data: liitteet,
        error,
        isLoading: loading,
    } = useQuery({ queryKey: key, queryFn: () => fetcher(), enabled: () => enabled.value })

    return {
        liitteet,
        error,
        loading,
    }
}

export async function useDownloadLiite(id: number) : Promise<LiitetiedostoResponse | undefined> {
    const reponse = await http.get(`${url}/${id}`)

    return reponse.data
}

export function useAddLiite() {
    const queryClient = useQueryClient()
    const _file = ref<UploadRawFile | null>(null)
    const error = computed(() => liiteIdError.value || liiteError.value)
    const loading = computed(() => liiteIdLoading.value || liiteLoading.value)
    const response = ref<LiiteResponse | undefined>(undefined)

    const { 
        data: liiteId,
        error: liiteIdError,
        isPending: liiteIdLoading, 
        mutate: mutateLiite
    } = useMutation({
        mutationFn: async(liite: LiiteRequest) : Promise<LiiteId> => {
            return (await http.post(url, liite)).data
        },
        onSuccess: (data) => {
            if(_file.value) {
                mutateLiiteFile({file: _file.value, id: data.id})
            }
        }
    })

    const {
        error: liiteError,
        isPending: liiteLoading,
        mutate: mutateLiiteFile
    } = useMutation({
        mutationFn: async (request: {file: UploadRawFile, id: number}) : Promise<LiiteResponse> => {
            return (await http.post(`${url}/${request.id}`, request.file, {
                headers: {
                    "content-type": request.file.type,
                    "Content-Disposition": `attachment; filename="${request.file.name}"`,
                },
            })).data
        },
        onSuccess: (data) => {
            response.value = data
            queryClient.invalidateQueries({queryKey: [url]})
            queryClient.invalidateQueries({queryKey: ["huonekortit"]})
        }
    })

    const addLiite = (file: UploadRawFile, request: LiiteRequest) => {
        mutateLiite(request)
        _file.value = file  
    }

    return {
        addLiite,
        response,
        error,
        loading,
    }
}

export function useDeleteLiite() {
    const queryClient = useQueryClient()

    const { 
        mutate,
        error,
        isPending: loading
    } = useMutation({
        mutationFn: (id: number) => http.delete(`${url}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [url]})
            queryClient.invalidateQueries({queryKey: ["huonekortit"]})
        }
    })

    const deleteLiite = (id: number) => {
        mutate(id)
    }

    return {
        deleteLiite,
        error,
        loading,
    }
}


