import { useComSideMenu } from "@/components/layout/SideMenu/sideMenu";
import { useTuoHuonekortit } from "@/methods/huonekorttiComposablses";
import { hankePage } from "@/views/hanke";
import { ElMessage } from "element-plus";
import { computed, ref, watch } from "vue";

const state = ref({
    vastuuyksikko: undefined as undefined | number,
    huonekortit: [] as number[],
    active: 0 as number,
    visible: false as boolean
})

export function useComTuonti() {
    const { huonekortit, clear: clearHuonekortit } = useComSideMenu()
    const { hankeVastuuyksikot: vastuuyksikot  } = hankePage()
    const { error, loading, response, importHuonekortit } = useTuoHuonekortit()
    const vastuuyksikko = computed({
        get: () => state.value.vastuuyksikko,
        set: (val: number | undefined) => {
            state.value.vastuuyksikko = val
        }
    })
    const active = computed({
        get: () => state.value.active,
        set: (val: number) => {
            state.value.active = val
        }
    })
    const visible = computed({
        get: () => state.value.visible,
        set: (val: boolean) => {
            state.value.visible = val
        }
    })

    const hyvaksy = () => {
        if(vastuuyksikko.value && huonekortit.value.length) {
            const _huonekortit = huonekortit.value.map((h) => {
                return { id: h }
            })
            importHuonekortit(vastuuyksikko.value,  _huonekortit)
        }
    }
    const peruuta = () => {
        active.value = 0
        vastuuyksikko.value = undefined
        clearHuonekortit()
    
        visible.value = false
    }

    watch(error, (val) => {
        if(val) {
            ElMessage.error("Tuonti epäonnistui!")
        }
    })
    watch(response, (val) => {
        if(!loading.value && val) {
            ElMessage.success("Tuonti onnistui!")
            peruuta()
        }
    })
    watch(huonekortit, (val) => {
        state.value.huonekortit = val
    })

    return {
        vastuuyksikot,
        vastuuyksikko,
        huonekortit,
        visible,
        active,
        clearHuonekortit,
        peruuta,
        hyvaksy
    }

}