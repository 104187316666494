<template>
    <el-form-item
        v-for="(vastuuyksikko, index) in hanke.vastuuyksikot"
        :key="index"
        :prop="'vastuuyksikko' + index"
        :label="'Vastuuyksikkö ' + (index + 1)">
        <el-space>
            <el-input size="small" placeholder="Nimi" v-model="vastuuyksikko.nimi" />
            <el-input size="small" placeholder="Vaatimukset" v-model="vastuuyksikko.vaatimukset" />
            <ComDeleteButton @Click="deleteVaastuuyksikko(index)" />
            <ComPlusButton v-if="index + 1 == hanke.vastuuyksikot.length" @Click="addVaastuuyksikko" />
        </el-space>
    </el-form-item>
</template>

<script setup lang="ts">
import { useYllapitoHankeComponent } from "./yllapitoHanke"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComPlusButton from "../common/buttons/ComPlusButton.vue"

const { hanke, addVaastuuyksikko, deleteVaastuuyksikko } = useYllapitoHankeComponent()
</script>
