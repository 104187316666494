import { DIFF_DELETE, DIFF_EQUAL, DIFF_INSERT, Diff, diff_match_patch } from "diff-match-patch"
import sanitize from "sanitize-html"

const diff_prettyHtml = (diffs: Diff[]) => {
    const html = []
    const pattern_amp = /&/g
    const pattern_lt = /</g
    const pattern_gt = />/g
    const pattern_para = /\n/g
    for (let x = 0; x < diffs.length; x++) {
        const op = diffs[x][0] // Operation (insert, delete, equal)
        const data = diffs[x][1] // Text of change.
        const text = data
            .replace(pattern_amp, "&amp;")
            .replace(pattern_lt, "&lt;")
            .replace(pattern_gt, "&gt;")
            .replace(pattern_para, "<br>")
        switch (op) {
            case DIFF_INSERT:
                html[x] = '<ins style="background:#e6ffe6;">' + text + "</ins>"
                break
            case DIFF_DELETE:
                html[x] = '<del style="background:#ffe6e6;">' + text + "</del>"
                break
            case DIFF_EQUAL:
                html[x] = "<span>" + text + "</span>"
                break
        }
    }
    return html.join("")
}

const diffAndSanitize = (a: string, b: string) => {
    const dmp = new diff_match_patch()
    const temp = dmp.diff_main(a, b)

    dmp.diff_cleanupSemantic(temp)

    return sanitize(diff_prettyHtml(temp), {
        allowedTags: ["del", "ins", "br"],
    })
}

export { diffAndSanitize }
