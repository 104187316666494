
import http from "@/http-common"
import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"

const url = "/vastuuyksikot"

export function useAddVastuuyksikko() {
    const queryClient = useQueryClient()

    const { data: response, error, isPending: loading, mutate } = useMutation({ 
        mutationFn: (vastuuyksikko: VastuuyksikkoRequest) => http.post(url, vastuuyksikko),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["hankkeet"]})
        }
    })

    const addVastuuyksikko = (vastuuyksikko: VastuuyksikkoRequest) => {
        mutate(vastuuyksikko)
    }

    return {
        response,
        error,
        loading,
        addVastuuyksikko
    }
}

export function useUpdateVastuuyksikko() {
    interface request {
        id: number,
        vastuuyksikko: VastuuyksikkoRequest
    }

    const queryClient = useQueryClient()

    const { data: response, error, isPending: loading, mutate } = useMutation({ 
        mutationFn: (request: request) => http.put(url + "/" + request.id, request.vastuuyksikko),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["hankkeet"]})
    })

    const updateVastuuyksikko = (id: number, vastuuyksikko: VastuuyksikkoRequest) => {
        mutate({ id, vastuuyksikko })
    }

    return {
        response,
        error,
        loading,
        updateVastuuyksikko
    }
}

export function useDeleteVastuuyksikko() {
    const queryClient = useQueryClient()

    const { data: response, error, isPending: loading, mutate } = useMutation({ 
        mutationFn: (id: number) => http.delete(url + "/" + id),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["hankkeet"]})
    })

    const deleteVastuuyksikko = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        deleteVastuuyksikko
    }
}
