<template>
    <ComCardExpand title="Toimintatiedot" :show="true" v-if="toimintatiedot">
        <el-row :gutter="10">
            <el-col :span="24" :lg="12">
                <label>Toiminnan kuvaus</label>
                <ComEditor v-model="toimintatiedot.toiminnanKuvaus" :disabled="!kirjoitus" />
            </el-col>
            <el-col :span="24" :lg="12">
                <label>Toiminnan tilalle asettamat vaatimukset</label>
                <ComEditor v-model="toimintatiedot.toiminnanVaatimukset" :disabled="!kirjoitus" />
            </el-col>
        </el-row>
        <el-form :inline="true" row class="row-margin">
            <el-form-item label="Henkilömäärä normaalisti">
                <el-input-number
                    class="number-input-mini"
                    :min="0"
                    size="small"
                    v-model="toimintatiedot.henkilomaaraNorm"
                    :disabled="!kirjoitus"></el-input-number>
            </el-form-item>
            <el-form-item label="Henkilömäärä maksimi">
                <el-input-number
                    class="number-input-mini"
                    :min="0"
                    size="small"
                    v-model="toimintatiedot.henkilomaaraMax"
                    :disabled="!kirjoitus"></el-input-number>
            </el-form-item>
            <el-form-item label="Sähköluokka">
                <el-space>
                    <el-radio-group
                        placeholder="Valitse"
                        size="small"
                        v-model="toimintatiedot.sahkoluokka"
                        :disabled="!kirjoitus">
                        <el-radio-button
                            v-for="item in sahkoluokka"
                            :key="item"
                            :label="item"
                            :value="item"></el-radio-button>
                    </el-radio-group>
                    <ComOhjeSahkoluokka />
                </el-space>
            </el-form-item>
            <el-form-item label="Toiminta-aika">
                <el-select
                    allow-create
                    filterable
                    placeholder="Valitse tai kirjoita"
                    size="small"
                    v-model="toimintatiedot.toimintaAika"
                    :disabled="!kirjoitus"
                    style="width: 240px;">
                    <el-option v-for="item in toimintaAika" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <el-button v-if="kirjoitus" type="success" @click="tallenna" size="small">Tallenna</el-button>
    </ComCardExpand>
</template>

<script setup lang="ts">
import ComCardExpand from "../common/ComCardExpand.vue"
import ComEditor from "../common/ComEditor.vue"
import ComOhjeSahkoluokka from "../ohjeet/ComOhjeSahkoluokka.vue"
import { useToimintatiedot } from "./toimintatiedot";

const { kirjoitus, sahkoluokka, toimintatiedot, toimintaAika, tallenna  } = useToimintatiedot()

</script>
