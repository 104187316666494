import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "node_title" }

import { computed, toRef } from "vue"
import ComSideMenuVastuuyksikko from "@/components/layout/SideMenu/ComSideMenuVastuuyksikko.vue"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHankkeet } from "@/methods/hankeComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiValinta',
  setup(__props) {

const {hankkeet : data} = useHankkeet(toRef(true))
const hankkeet = computed(() => {
    return data.value?.map((h) => {
        return {
            id: h.id,
            nimi: h.nimi,
            children: h.vastuuyksikot?.map((v) => {
                return {
                    id: v.id,
                    nimi: v.nimi,
                }
            }),
        }
    })
})


return (_ctx: any,_cache: any) => {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: hankkeet.value,
    "node-key": "id",
    class: "side-menu-tree",
    "highlight-current": false
  }, {
    default: _withCtx(({ node, data }) => [
      (node.level === 2)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass({ 'arkistoitu-text': node.parent.data.arkistoitu }),
            style: {"overflow":"hidden"}
          }, [
            _createVNode(ComSideMenuVastuuyksikko, {
              data: data,
              checkbox: true
            }, null, 8, ["data"])
          ], 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["side-menu-hanke", { 'arkistoitu-text': data.arkistoitu }])
          }, [
            _createVNode(ComTooltip, {
              content: data.nimi
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(data.nimi), 1)
              ]),
              _: 2
            }, 1032, ["content"])
          ], 2))
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})