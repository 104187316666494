<template>
    <el-form>
        <el-form-item label="Suodata">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner" :value="modelValue" @input="filterChange" />
                </div>
            </div>
        </el-form-item>
    </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        let timetOut = NaN

        const filterChange = (event: Event) => {
            const target = event.target as HTMLInputElement

            clearTimeout(timetOut)

            timetOut = window.setTimeout(() => {
                emit("update:modelValue", target.value)
            }, 1000)
        }

        return { filterChange }
    },
})
</script>
