<template>
    <ComDeleteButton v-if="hasRight" @click="deleteKommenttiClick"></ComDeleteButton>
</template>

<script setup lang="ts">
import { KommenttiResponse } from "@/api/models/kommentti-response"
import { computed,  PropType, watch } from "vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { useKayttaja } from "@/methods/kayttajaComposables"
import { useKommentitComponent } from "./kommentit"
import { useDeleteKommentti } from "@/methods/kommenttiComposables"
import { ElMessage, ElMessageBox } from "element-plus"

const props = defineProps({
    kommentti: {
        type: Object as PropType<KommenttiResponse>,
        required: true,
    },
})

const { isPaakayttaja } = useKommentitComponent()
const { deleteKommentti, error, response } = useDeleteKommentti()
const { kayttaja } = useKayttaja()
const hasRight = computed(() => {
    if (isPaakayttaja.value) {
        return true
    }
    if (kayttaja.value && kayttaja.value.id == props.kommentti.kayttaja?.id) {
        return true
    } else {
        return false
    }
})

const deleteKommenttiClick = () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa kommentin?", "Poista kommentti", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    }).then(() => {
        deleteKommentti(props.kommentti.id)
    })
}

watch(error, (val) => {
    if (val) {
        ElMessage.error("Kommentin poistaminen epäonnistui")
    }
})
watch(response, (val) => {
    if (val) {
        ElMessage.success("Kommentti poistettu")
    }
})

</script>
