import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import ComTrashButton from "@/components/common/buttons/ComTrashButton.vue"
import { ref } from "vue"
import ComRoskakoriContent from "./ComRoskakoriContent.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComRoskakori',
  setup(__props) {

const show = ref(false)

return (_ctx: any,_cache: any) => {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComTrashButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = true))
    }),
    (show.value)
      ? (_openBlock(), _createBlock(_component_el_drawer, {
          key: 0,
          modelValue: show.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((show).value = $event)),
          "append-to-body": "",
          title: "Roskakori",
          size: "50%",
          "destroy-on-close": ""
        }, {
          default: _withCtx(() => [
            _createVNode(ComRoskakoriContent)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})