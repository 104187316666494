import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"float":"right"} }

import ComPagination from "@/components/common/ComPagination.vue"
import { useRoskakori } from "./roskakori";
import ComLoading from "@/components/common/ComLoading.vue";
import ComEmpty from "@/components/common/ComEmpty.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComRoskakoriContent',
  setup(__props) {

const { huonekortit, total, palautaButtonClicked, pageChangeClicked } = useRoskakori()

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_table = _resolveComponent("el-table")!

  return (!_unref(huonekortit))
    ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
    : (_unref(huonekortit).length == 0)
      ? (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(ComPagination, {
                      total: _unref(total),
                      onPageChange: _unref(pageChangeClicked)
                    }, null, 8, ["total", "onPageChange"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_table, { data: _unref(huonekortit) }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: "Tilanimike",
                prop: "tilanimike.nimi"
              }),
              _createVNode(_component_el_table_column, {
                label: "Tilanimikeen täsmennys",
                prop: "toiminta"
              }),
              _createVNode(_component_el_table_column, {
                label: "Vastuuyksikkö",
                prop: "vastuuyksikko.nimi"
              }),
              _createVNode(_component_el_table_column, { width: "100px" }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_link, {
                    type: "primary",
                    onClick: ($event: any) => (_unref(palautaButtonClicked)(scope.row.id))
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Palauta")
                    ])),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ], 64))
}
}

})