<template>
    <KeepAlive>
        <ComLisaaHuonekortti :key="hankeId" v-if="writableVastuuyksikot?.length" />
    </KeepAlive>
    <el-divider></el-divider>
    <ComLoading v-show="loading" />
    <el-empty  v-show="!vastuuyksikkoSelected" description="Valitse vähintään yksi vastuuyksikkö!" />
    <div v-show="!loading && vastuuyksikkoSelected">
        <el-row>
            <el-col :span="6">
                <ComFilterInput v-model="filter" />
            </el-col>
            <el-col :span="18">
                <ComPagination
                    :total="total"
                    v-model:page-size="pageSize"
                    v-model:current-page="currentPage"
                    style="float: right" />
            </el-col>
        </el-row>
        <ComHuonekorttiTableEditable />
    </div>
</template>

<script setup lang="ts">
import ComLisaaHuonekortti from "./ComLisaaHuonekortti.vue"
import ComFilterInput from "../../common/ComFilterInput.vue"
import ComHuonekorttiTableEditable from "./table/ComHuonekorttiTableEditable.vue"
import { huonekorttiListing } from "./huonekortit"
import ComLoading from "@/components/common/ComLoading.vue"
import ComPagination from "@/components/common/ComPagination.vue"

const { writableVastuuyksikot, filter, currentPage, pageSize, total, loading, vastuuyksikkoSelected, hankeId } =
    huonekorttiListing()
</script>
