import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  "element-loading-background": "transparent",
  style: {"background-color":"transparent","min-height":"50px"}
}
const _hoisted_2 = {
  key: 0,
  style: {"padding-top":"100px"}
}
const _hoisted_3 = { style: {"display":"inline-block"} }
const _hoisted_4 = { class: "side-menu-huonekortti-subtext" }
const _hoisted_5 = { style: {"display":"inline-block"} }
const _hoisted_6 = { class: "side-menu-huonekortti-subtext" }

import { ref, watch, watchEffect } from "vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import { Document } from "@element-plus/icons-vue"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { useHuonekorttiFormatLinkWithTilat } from "@/methods/huonekorttiComposablses"
import { useComSideMenu } from "./sideMenu"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSideMenuHuonekortit',
  props: {
    id: {},
    page: {},
    filter: {},
    checkbox: { type: Boolean }
  },
  emits: ["totalCount"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const { huonekortit, setAllFilters, paramsObject, total, loading } = useHuonekortit()
const { formatLinkWithTilat } = useHuonekorttiFormatLinkWithTilat()
const checked = ref([])
const { add, remove  } = useComSideMenu()

watchEffect(() => {
    setAllFilters(props.filter)
    paramsObject.value.vastuuyksikot = [props.id]
    paramsObject.value.paging = { currentPage: props.page, pageSize: 30 }

    emit("totalCount", total.value, paramsObject.value.paging.currentPage)
})

watch(checked, (n, o) => {
    if (n.length > o.length) {
        add(n.filter((n) => !o.includes(n))[0])
    } else if (o.length > n.length) {
        remove(o.filter((o) => !n.includes(o))[0])
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loading))
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
          [_directive_loading, true]
        ])
      : (_unref(huonekortit) && _unref(huonekortit).length === 0)
        ? (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
        : (_ctx.checkbox && _unref(huonekortit))
          ? (_openBlock(), _createBlock(_component_el_checkbox_group, {
              key: 2,
              modelValue: checked.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked).value = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(huonekortit), (huonekortti) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: huonekortti.id,
                    class: "side-menu-huonekortti"
                  }, [
                    _createVNode(_component_el_checkbox, {
                      value: huonekortti.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(ComTooltip, {
                          content: _unref(formatLinkWithTilat)(huonekortti)
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(_unref(formatLinkWithTilat)(huonekortti)), 1)
                          ]),
                          _: 2
                        }, 1032, ["content"]),
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(huonekortti.toiminta), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["value"])
                  ]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : (_unref(huonekortit))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_unref(huonekortit), (huonekortti) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: huonekortti.id,
                  class: "side-menu-huonekortti"
                }, [
                  _createVNode(_component_el_icon, {
                    size: "36px",
                    class: "side-menu-huonekortti-icon"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Document))
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_router_link, {
                      to: { name: 'Huonekortti', params: { huonekorttiId: huonekortti.id } },
                      custom: ""
                    }, {
                      default: _withCtx(({ href, navigate }) => [
                        _createVNode(_component_el_link, {
                          type: "primary",
                          href: href,
                          onClick: navigate,
                          underline: false
                        }, {
                          default: _withCtx(() => [
                            _createVNode(ComTooltip, {
                              content: _unref(formatLinkWithTilat)(huonekortti)
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_unref(formatLinkWithTilat)(huonekortti)), 1)
                              ]),
                              _: 2
                            }, 1032, ["content"])
                          ]),
                          _: 2
                        }, 1032, ["href", "onClick"])
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(huonekortti.toiminta), 1)
                    ])
                  ])
                ]))
              }), 128))
            : _createCommentVNode("", true)
  ]))
}
}

})