import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

import { KayttajaRequest } from "@/api/models/kayttaja-request"
import { ElMessage, FormInstance, FormRules } from "element-plus"
import { computed, ref, watch, watchEffect } from "vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import ComLisaaKayttajaOikeudet from "./ComLisaaKayttajaOikeudet.vue"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { KayttajaRequestPut } from "@/api/models/kayttaja-request-put"
import { hankePage } from "@/views/hanke"
import { useOikeudetComponent } from "../oikeudet"
import { getKayttajat } from "@/methods/paakayttajaComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLisaaKayttaja',
  setup(__props) {

const { hanke } = hankePage()
const { addKayttajaResponse, updateKayttajaResponse, addOikeudetResponse, editedKayttaja, addKayttaja, updateKayttaja, addOikeudet, kayttajaModalClose } = useOikeudetComponent()
const kayttaja = ref<KayttajaRequest>({} as KayttajaRequest)
const oikeudet = ref<OikeusRequest[]>()
const suggestions = ref<KayttajaResponse[]>()
const selected = ref<KayttajaResponse>()
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const formRef = ref<FormInstance>()
const formRules = ref<FormRules>({
    email: [
        {
            required: true,
            message: "Täytä Sähköpostiosoite kenttä!",
        },
        {
            type: "email",
            pattern: emailRegex,
            message: "Epäkelpo sähköpostiosoite",
        },
    ],
    etunimi: [
        {
            required: true,
            message: "Täytä etunimi kenttä!",
        },
    ],
    sukunimi: [
        {
            required: true,
            message: "Täytä sukunimi kenttä!",
        },
    ],
})

const sairaala = computed(() => {
    return hanke?.value?.sairaala
})

const emailSuggestions = (query: string, callback: (val: KayttajaResponse[]) => void) => {
    selected.value = undefined

    if (query.length > 2) {
        getKayttajat(query).then((response) => {
            if (response) {
                const match = response.find((s) => s.email == kayttaja.value.email)

                if (match) {
                    suggestionSelected(match)

                    callback([])
                } else {
                    suggestions.value = response

                    callback(response)
                }
            }
        })
    }
}

const suggestionSelected = (val: KayttajaResponse) => {
    selected.value = val

    kayttaja.value.etunimi = val.etunimi
    kayttaja.value.sukunimi = val.sukunimi
}

const addKayttajaClick = () => {
    formRef.value?.validate().then((response) => {
        if (response && sairaala.value) {
            kayttaja.value.sairaalat = [
                {
                    sairaala: {
                        id: sairaala.value?.id,
                    },
                    suunnittelija: false,
                },
            ]

            addKayttaja(kayttaja.value)
        }
    }).catch(() => {
        return
    })
}

const updateKayttajaClick = async () => {
    if (selected.value) {
        let request = {} as KayttajaRequestPut

        if (selected.value.sairaalat?.some((s) => s.sairaala?.id == sairaala.value?.id)) {
            ElMessage.error("Käyttäjä on liitetty jo sairaalaan!")
        } else {
            if (sairaala.value) {
                request = Object.assign({} as KayttajaRequestPut, selected.value)

                request.sairaalat?.push({
                    sairaala: {
                        id: sairaala.value.id,
                    },
                    suunnittelija: false,
                })

                updateKayttaja(selected.value.id, request)
                setOikeudet(selected.value.id)
            }
        }
    } else if (editedKayttaja.value) {
        const edit: KayttajaResponse = JSON.parse(JSON.stringify(editedKayttaja.value))
        edit.email = kayttaja.value.email
        edit.etunimi = kayttaja.value.etunimi
        edit.sukunimi = kayttaja.value.sukunimi

        const request = Object.assign({} as KayttajaRequestPut, edit)

        updateKayttaja(editedKayttaja.value.id, request)
    }
}

const setOikeudet = (kayttajaId: number) => {
    if (oikeudet.value) {
        oikeudet.value.map((o) => (o.kayttaja = { id: kayttajaId }))

        addOikeudet(oikeudet.value)
    }
}

const resetFields = () => {
    if (formRef.value) {
        formRef.value.resetFields()
    }

    selected.value = undefined
}

watch(() => kayttaja.value.email, (n) => {
    if(!editedKayttaja.value) {
        formRef.value?.validateField("email").then((response : boolean) => {
            if(response && n) {
                const etunimi = n.substring(0, n.indexOf("."))
                const sukunimi = n.substring(n.indexOf(".") + 1, n.indexOf("@"))

                kayttaja.value.etunimi = etunimi.charAt(0).toUpperCase() + etunimi.slice(1)
                kayttaja.value.sukunimi =  sukunimi.charAt(0).toUpperCase() + sukunimi.slice(1)
            }
        }).catch(() => {
            return
        })
    }
})

watch(addKayttajaResponse, (response) => {
    if (response) {
        resetFields()
        setOikeudet(response.id)

        ElMessage.success("Käyttäjä lisätty!")
    }
})

watch(updateKayttajaResponse, (response) => {
    if (response) {
        resetFields()
        ElMessage.success("Käyttäjä päivitetty!")

        if(editedKayttaja.value) {
            kayttajaModalClose()
        }
    }
})

watch(addOikeudetResponse, (response) => {
    if (response) {
        ElMessage.success("Oikeudet lisätty!")
    }
})

watchEffect(() => {
    if (editedKayttaja.value) {
        kayttaja.value = JSON.parse(JSON.stringify(editedKayttaja.value))
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref_key: "formRef",
    ref: formRef,
    model: kayttaja.value,
    rules: formRules.value,
    "label-width": "120px",
    "inline-message": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Sähköpostiosoite",
        prop: "email"
      }, {
        default: _withCtx(() => [
          (!_unref(editedKayttaja))
            ? (_openBlock(), _createBlock(_component_el_autocomplete, {
                key: 0,
                modelValue: kayttaja.value.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((kayttaja.value.email) = $event)),
                "fetch-suggestions": emailSuggestions,
                onSelect: suggestionSelected,
                "value-key": "email",
                style: {"width":"100%"},
                size: "small"
              }, null, 8, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                modelValue: kayttaja.value.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((kayttaja.value.email) = $event)),
                style: {"width":"100%"},
                size: "small"
              }, null, 8, ["modelValue"]))
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "Etunimi",
        prop: "etunimi"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            size: "small",
            modelValue: kayttaja.value.etunimi,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((kayttaja.value.etunimi) = $event)),
            disabled: selected.value != undefined
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "Sukunimi",
        prop: "sukunimi"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            size: "small",
            modelValue: kayttaja.value.sukunimi,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((kayttaja.value.sukunimi) = $event)),
            disabled: selected.value != undefined
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }),
      (!_unref(editedKayttaja))
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "Lisää oikeudet"
          }, {
            default: _withCtx(() => [
              _createVNode(ComLisaaKayttajaOikeudet, {
                onOikeudetChange: _cache[4] || (_cache[4] = (val) => (oikeudet.value = val))
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_space, { wrap: "" }, {
            default: _withCtx(() => [
              (!selected.value && !_unref(editedKayttaja))
                ? (_openBlock(), _createBlock(ComSuccessButton, {
                    key: 0,
                    onClick: addKayttajaClick
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Tallenna ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(ComSuccessButton, {
                    key: 1,
                    onClick: updateKayttajaClick
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Päivitä")
                    ])),
                    _: 1
                  })),
              _createVNode(ComCancelButton, { onClick: _unref(kayttajaModalClose) }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Peruuta")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}
}

})