<template>
    <el-button class="expandButton" @click="expand" type="info" plain circle size="small" v-if="h">
        <el-icon v-if="way == 'vertical'"><ArrowUp></ArrowUp></el-icon>
        <el-icon v-else><ArrowLeft></ArrowLeft></el-icon>
    </el-button>
    <el-button class="expandButton" @click="expand" type="info" plain circle size="small" v-if="!h">
        <el-icon v-if="way == 'vertical'"><ArrowDown></ArrowDown></el-icon>
        <el-icon v-else><ArrowRight></ArrowRight></el-icon>
    </el-button>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import Orientation from "../../types/OrientationEnum"
import { ArrowDown, ArrowRight, ArrowLeft, ArrowUp } from "@element-plus/icons-vue"

export default defineComponent({
    components: {
        ArrowDown,
        ArrowRight,
        ArrowLeft,
        ArrowUp,
    },
    props: {
        show: {
            required: false,
            type: Boolean,
            default: false,
        },
        way: {
            required: true,
            type: String as PropType<Orientation>,
        },
    },
    emits: ["expand"],
    setup(props, context) {
        const h = ref<boolean>(props.show)
        const expand = () => {
            h.value = !h.value

            context.emit("expand", h.value)
        }

        return { h, expand }
    },
})
</script>
