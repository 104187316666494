import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, {
        span: 8,
        offset: 8,
        style: {"text-align":"center","margin-bottom":"40px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_image, {
            src: require('@/assets/vahtilogo.png')
          }, null, 8, ["src"]),
          _cache[0] || (_cache[0] = _createElementVNode("h1", { style: {"font-size":"24px"} }, "Huolto käynnissä!", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Vahti väliaikaisesti poissa käytöstä päivityksen takia.", -1)),
          _createVNode(_component_el_image, {
            src: require('@/assets/worker.gif')
          }, null, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}