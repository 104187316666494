import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "float-right-flex" }
const _hoisted_2 = ["onClick"]

import ComCardExpand from "@/components/common/ComCardExpand.vue"
import ComLoading from "../common/ComLoading.vue"
import { DocumentCopy } from "@element-plus/icons-vue"
import router from "@/router"
import ComCard from "../common/ComCard.vue"
import { useHankkeet } from "@/methods/hankeComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHankkeet',
  setup(__props) {

const { hankkeet, arkistoidut } = useHankkeet()
const props = {
    label: "nimi",
}
const hankeLink = (val: number) => {
    router.push({ name: "Hanke", params: { hankeId: val } })
}


return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_unref(hankkeet))
    ? (_openBlock(), _createBlock(ComCardExpand, {
        key: 0,
        title: "Hankkeet",
        show: true,
        class: "etusivu-hankkeet"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_checkbox, {
              label: "Arkistoidut",
              modelValue: _unref(arkistoidut),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(arkistoidut) ? (arkistoidut).value = $event : null)),
              border: "",
              "data-test-id": "etusivuArkistoidutCheckbox"
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_el_tree, {
            data: _unref(hankkeet),
            props: props
          }, {
            default: _withCtx(({ data }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (hankeLink(data.id)),
                class: _normalizeClass({ 'arkistoitu-text': data.arkistoitu })
              }, [
                _createVNode(_component_el_icon, { class: "hanke-icon" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DocumentCopy))
                  ]),
                  _: 1
                }),
                _createElementVNode("span", null, _toDisplayString(data.nimi), 1)
              ], 10, _hoisted_2)
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ComCard, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(ComLoading)
        ]),
        _: 1
      }))
}
}

})