import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"width":"15%"} }
const _hoisted_2 = { style: {"width":"5%"} }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { style: {"width":"35%"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { style: {"width":"35%"} }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { style: {"padding-right":"20px"} }
const _hoisted_9 = { style: {"width":"100px"} }
const _hoisted_10 = { key: 0 }

import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import HistoriaNimike from "@/types/HistoriaNimike"
import ComNimikeHistoriaPopover from "./historia/suunnittuelutiedot/ComNimikeHistoriaPopover.vue";
import ComTooltip from "../common/ComTooltip.vue";
import { Warning } from "@element-plus/icons-vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSuunnittelutiedotRowHistoria',
  props: {
    nimike: {}
  },
  setup(__props: any) {

const props = __props
const { tyyppiIsLisays, tyyppiIsPoisto } = useHuonekorttiHistoriaMethods()

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass({ poisto: _unref(tyyppiIsPoisto)(_ctx.nimike), lisays: _unref(tyyppiIsLisays)(_ctx.nimike) }),
    style: {"height":"40px"}
  }, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.nimike.nimike?.nimi), 1),
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("span", {
        innerHTML: _ctx.nimike.maara
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createElementVNode("span", {
        innerHTML: _ctx.nimike.tasmennys
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("td", _hoisted_6, [
      (_ctx.nimike.ominaisuudet)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.nimike.ominaisuudet, (ominaisuus, index) => {
            return (_openBlock(), _createBlock(_component_el_tag, {
              type: "info",
              size: "small",
              style: {"margin-right":"2px"},
              key: index
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: ominaisuus.nimi
                }, null, 8, _hoisted_7)
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("template", null, [
          (!_ctx.nimike.nimike?.kaytossa)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                text: "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                size: "small",
                circle: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(ComTooltip, { content: "Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella." }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { size: 20 }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Warning))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      ('tyyppi' in _ctx.nimike)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(ComNimikeHistoriaPopover, {
              id: _ctx.nimike.huonekorttiNimikeId
            }, null, 8, ["id"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})