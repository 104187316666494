<template>
    <ComFilterInput v-model="filter"></ComFilterInput>
    <el-timeline v-if="data">
        <el-timeline-item
            v-for="muutos in filteredData"
            :key="muutos.id"
            :timestamp="muutos.aika"
            placement="top"
            :type="type(muutos.tyyppi)"
            size="large">
            <ComHuonekorttiNimikeHistoriaItem :data="currentData(muutos)" />
        </el-timeline-item>
    </el-timeline>
    <ComEmpty v-else></ComEmpty>
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { HuonekorttiNimikeHistoriaResponse } from "@/api/models/huonekortti-nimike-historia-response"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import { computed, ref } from "vue"
import ComHuonekorttiNimikeHistoriaItem from "./ComHuonekorttiNimikeHistoriaItem.vue"
import { huonekorttiPage } from "@/views/huonekortti"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"

const props = defineProps<{
    data: HuonekorttiNimikeHistoriaResponse[]
}>()
const filter = ref("")
const { huonekortti } = huonekorttiPage()
const { diffHistoriaNimike, tyyppiColor } = useHuonekorttiHistoriaMethods()
const historia = computed(() => {
    return props.data
})
const filteredData = computed(() => {
    if (!filter.value) {
        return historia.value
    }
    if (historia.value) {
        return historia.value.filter((n) => {
            return (
                n.nimike!.nimi!.toLowerCase().indexOf(filter.value.toLowerCase()) > -1 ||
                n.tasmennys!.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            )
        })
    } else {
        return []
    }
})

const currentData = (muutos: HuonekorttiNimikeHistoriaResponse) => {
    if (muutos.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        const temp = historia.value.filter((n) => {
            return n.huonekorttiNimikeId == muutos.huonekorttiNimikeId
        })

        const i = temp.findIndex((h) => h.id == muutos.id)

        if (temp[i - 1]) {
            return diffHistoriaNimike(muutos, temp[i - 1])
        } else {
            const huonekorttiNimike = huonekortti?.value?.nimikkeet?.find(
                (n) => n.id == muutos.huonekorttiNimikeId
            )

            if (huonekorttiNimike) {
                return diffHistoriaNimike(muutos, huonekorttiNimike)
            }
        }
    } else {
        return diffHistoriaNimike(muutos)
    }
}

const type = (type: HuonekorttiHistoriaTyyppi | undefined) => {
    return tyyppiColor(type)
}
</script>
