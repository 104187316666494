import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ComTooltip = _resolveComponent("ComTooltip")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, {
    type: "danger",
    size: "small",
    circle: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ComTooltip, { content: "Roskakori" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Delete)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}