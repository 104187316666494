import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, computed } from "vue"
import ComHeader from "./components/layout/ComHeader.vue"
import ComSideMenu from "./components/layout/SideMenu/ComSideMenu.vue"
import { useAuth0 } from "@/auth/auth0-plugin"


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const auth0 = useAuth0()

onMounted(async () => {
    if (!auth0) {
        return
    }
    await auth0.createClient()
    await auth0.handleCallback()
    await auth0.checkToken()
})

const isAuthenticated = computed((): boolean | null => {
    if (!auth0) {
        return null
    }

    return auth0.isAuthenticated.value
})

return (_ctx: any,_cache: any) => {
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      (isAuthenticated.value)
        ? (_openBlock(), _createBlock(_component_el_aside, {
            key: 0,
            class: "sidebar"
          }, {
            default: _withCtx(() => [
              _createVNode(ComSideMenu)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          (isAuthenticated.value)
            ? (_openBlock(), _createBlock(_component_el_header, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(ComHeader)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_main, { class: "main" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})