import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "label" }

import ComLoading from "@/components/common/ComLoading.vue"
import { useHanketiedotComponent } from "./hanketiedot"
import { Folder, FolderOpened } from "@element-plus/icons-vue"
import { useHankeVaatimusKategoriat } from "@/methods/hankeVaatimusComposables";
import { ref } from "vue";
import ComHanketiedotVaatimuksetField from "./ComHanketiedotVaatimuksetField.vue";
import VaatimusType from "@/types/VaatimusEnum";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHanketiedotVaatimukset',
  setup(__props) {

const { hankeVaatimuskategoriat } = useHankeVaatimusKategoriat()
const { hanke, vastuuyksikot } = useHanketiedotComponent()
const vastuuyksikkoActive = ref<(string | number)[]>([])
const kategoriaActive = ref<number[]>([])

const getHankeVaatimus = (kategoria: number) => {
    return hanke?.value?.kategorisoidutVaatimukset?.find((k) => k.kategoria?.id == kategoria)?.vaatimus
}


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (!_unref(hankeVaatimuskategoriat))
    ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
    : (_unref(hanke))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Vaatimukset", -1)),
          _createVNode(_component_el_collapse, {
            modelValue: vastuuyksikkoActive.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vastuuyksikkoActive).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_collapse_item, {
                name: "hanke",
                title: ""
              }, {
                title: _withCtx(() => [
                  (!vastuuyksikkoActive.value.includes('hanke'))
                    ? (_openBlock(), _createBlock(_component_el_icon, {
                        key: 0,
                        class: "collapse-icon-right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Folder))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_el_icon, {
                        key: 1,
                        class: "collapse-icon-right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(FolderOpened))
                        ]),
                        _: 1
                      })),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, "Koko hanketta koskevat vaatimukset", -1))
                ]),
                default: _withCtx(() => [
                  _createVNode(ComHanketiedotVaatimuksetField, {
                    vaatimus: _unref(hanke).vaatimukset,
                    type: _unref(VaatimusType).Hanke
                  }, null, 8, ["vaatimus", "type"])
                ]),
                _: 1
              }),
              (_unref(vastuuyksikot))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(vastuuyksikot), (vastuuyksikko, index) => {
                    return (_openBlock(), _createBlock(_component_el_collapse_item, {
                      name: vastuuyksikko.id,
                      key: index
                    }, {
                      title: _withCtx(() => [
                        (!vastuuyksikkoActive.value.includes(vastuuyksikko.id))
                          ? (_openBlock(), _createBlock(_component_el_icon, {
                              key: 0,
                              class: "collapse-icon-right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Folder))
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_icon, {
                              key: 1,
                              class: "collapse-icon-right"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FolderOpened))
                              ]),
                              _: 1
                            })),
                        _createElementVNode("span", _hoisted_1, _toDisplayString('Vastuuyksikköä ' + vastuuyksikko.nimi + ' koskevat vaatimukset'), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(ComHanketiedotVaatimuksetField, {
                          vaatimus: vastuuyksikko.vaatimukset ?? '',
                          vastuuyksikko: vastuuyksikko,
                          type: _unref(VaatimusType).Vastuuyksikko
                        }, null, 8, ["vaatimus", "vastuuyksikko", "type"])
                      ]),
                      _: 2
                    }, 1032, ["name"]))
                  }), 128))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Kategorisoidut vaatimukset", -1)),
          _createVNode(_component_el_tabs, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hankeVaatimuskategoriat), (kategoria, index) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: index,
                  label: kategoria.nimi
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_collapse, {
                      modelValue: kategoriaActive.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((kategoriaActive).value = $event))
                    }, {
                      default: _withCtx(() => [
                        (kategoria.children)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(kategoria.children, (child, index) => {
                              return (_openBlock(), _createBlock(_component_el_collapse_item, {
                                name: child.id,
                                key: index,
                                title: child.nimi
                              }, {
                                title: _withCtx(() => [
                                  (!kategoriaActive.value.includes(child.id))
                                    ? (_openBlock(), _createBlock(_component_el_icon, {
                                        key: 0,
                                        class: "collapse-icon-right"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(Folder))
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_el_icon, {
                                        key: 1,
                                        class: "collapse-icon-right"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_unref(FolderOpened))
                                        ]),
                                        _: 1
                                      })),
                                  _createElementVNode("span", _hoisted_2, _toDisplayString(child.nimi), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(ComHanketiedotVaatimuksetField, {
                                    vaatimus: getHankeVaatimus(child.id),
                                    type: _unref(VaatimusType).Kategorisoidut,
                                    kategoriaId: child.id
                                  }, null, 8, ["vaatimus", "type", "kategoriaId"])
                                ]),
                                _: 2
                              }, 1032, ["name", "title"]))
                            }), 128))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
}
}

})