import Oikeudet from "@/types/OikeudetEnum"
import { Ref, computed, ref } from "vue"
import { OikeusResponse } from "@/api/models/oikeus-response"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { hankePage } from "@/views/hanke"
import { useAddKayttaja, useAddOikeus, useAddPaakayttaja, useDeleteOikeus, useDeletePaakayttaja, useHankePaakayttajat, useSairaalaKayttajatByHanke, useUpdateKayttaja, useUpdateOikeus, useVastuuyksikoidenOikeudet } from "@/methods/paakayttajaComposables"

interface state {
    filter: Ref<string>,
    lisaaKayttajaModal: Ref<boolean>,
    editedKayttaja: Ref<KayttajaResponse | undefined>
}

const state = ref<state>({
    filter: ref(""),
    lisaaKayttajaModal: ref(false),
    editedKayttaja: ref(undefined)
})

export function useOikeudetComponent() {
    const { selectedVastuuyksikot, selectedVastuuyksikkoIds, hankeVastuuyksikot, id: hankeId } = hankePage()
    const { oikeudet, loading: oikeudetLoading } = useVastuuyksikoidenOikeudet(selectedVastuuyksikkoIds)
    const { deleteOikeudet, deleteOikeus, loading: deleteOikeusLoading, response: deleteOikeusResponse } = useDeleteOikeus()
    const { addOikeus, addOikeudet, loading: addOikeudetLoading, response: addOikeudetResponse } = useAddOikeus()
    const { updateOikeus } = useUpdateOikeus()
    const { paakayttajat, loading: paakayttajatLoading } = useHankePaakayttajat(hankeId)
    const { addPaakayttajat, loading: addPaakayttajaLoading, response: addPaakaytajaResonse } = useAddPaakayttaja()
    const { deletePaakayttaja, loading: deletePaakayttajaLoading, response: deletePaakayttajaResponse } = useDeletePaakayttaja()
    const { kayttajat, loading: kayttajatLoading } = useSairaalaKayttajatByHanke(hankeId)
    const { addKayttaja, loading: addKayttajaLoading, response: addKayttajaResponse } = useAddKayttaja()
    const { updateKayttaja, loading: updateKayttajaLoading, response: updateKayttajaResponse } = useUpdateKayttaja()
    const oikeudetEnum = {} as Oikeudet
    const loading = computed(() => oikeudetLoading.value || deleteOikeusLoading.value || paakayttajatLoading.value || kayttajatLoading.value)
    const filter = computed({
        get: () => state.value.filter,
        set: (val) => {
        state.value.filter = val
        }
    })
    const filteredKayttajat = computed(() => {
        if (filter.value !== "") {
            const f = filter.value.toLowerCase()

            return kayttajat.value?.filter(
                (k) =>
                    k.email?.toLowerCase().includes(f) ||
                    k.etunimi?.toLowerCase().includes(f) ||
                    k.sukunimi?.toLowerCase().includes(f) ||
                    k.etunimi
                        ?.toLowerCase()
                        .concat(" " + k.sukunimi!.toLowerCase())
                        .includes(f)
            )
        }
        return kayttajat.value
    })
    const filteredOikeudet = computed(() => {
        if (filter.value !== "") {
            const f = filter.value.toLowerCase()

            return oikeudet.value?.filter(
                (k) =>
                    k.email?.toLowerCase().includes(f) ||
                    k.etunimi?.toLowerCase().includes(f) ||
                    k.sukunimi?.toLowerCase().includes(f) ||
                    k.etunimi
                        ?.toLowerCase()
                        .concat(" " + k.sukunimi!.toLowerCase())
                        .includes(f)
            )
        }
        return oikeudet.value
    })
    const lisaaKayttajaModal = computed({
        get: () => state.value.lisaaKayttajaModal,
        set: (val) => {
        state.value.lisaaKayttajaModal = val
        }
    })
    const editedKayttaja = computed({
        get: () => state.value.editedKayttaja,
        set: (val) => {
        state.value.editedKayttaja = val
        }
    })

    const getVastuuyksikkoOikeus = (oikeudet: OikeusResponse[] | undefined, vastuuyksikko: number) => {
        return oikeudet?.find((o) => o.vastuuyksikko?.id == vastuuyksikko)
    }

    const kayttajaUpdateClick = () => {
        lisaaKayttajaModal.value = false
    }

    const editKayttaja = (val: KayttajaResponse) => {
        editedKayttaja.value = val
        lisaaKayttajaModal.value = true
    }

    const kayttajaModalClose = () => {
        editedKayttaja.value = undefined
        lisaaKayttajaModal.value = false
    } 

    return {
        oikeudet,
        loading,
        deleteOikeusResponse,
        paakayttajat,
        kayttajat,
        oikeudetEnum,
        lisaaKayttajaModal,
        editedKayttaja,
        filter,
        filteredKayttajat,
        filteredOikeudet,
        selectedVastuuyksikot,
        hankeVastuuyksikot,
        deletePaakayttajaResponse,
        addPaakaytajaResonse,
        addOikeudetResponse,
        addKayttajaResponse,
        updateKayttajaResponse,
        getVastuuyksikkoOikeus,
        kayttajaUpdateClick,
        kayttajaModalClose,
        deleteOikeus,
        deleteOikeudet,
        addOikeus,
        addOikeudet,
        addPaakayttajat,
        deletePaakayttaja,
        addKayttaja,
        updateKayttaja,
        updateOikeus,
        editKayttaja
    }

}