import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  class: "float-right-flex",
  style: {"padding-bottom":"20px"}
}
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { colspan: "5" }

import ComCardExpand from "../common/ComCardExpand.vue"
import ComLisaaNimike from "./ComLisaaNimike.vue"
import ComEmpty from "../common/ComEmpty.vue"
import { Folder, FolderOpened } from "@element-plus/icons-vue"
import ComSuunnittelutiedotRow from "./ComSuunnittelutiedotRow.vue"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import ComSuunnittelutiedotRowHistoria from "./ComSuunnittelutiedotRowHistoria.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSuunnittelutiedot',
  setup(__props) {

const { kategoriat, kirjoitus, nimikkeet, o, hyvaksytty, muutoksetActive, active, nimikkeetHistoria, hasAlikategoriaItems, hasPaakategoriaItems, togleAll, } = useSuunnittelutiedot()


return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createBlock(ComCardExpand, {
    title: "Suunnittelutiedot",
    way: _unref(o),
    show: true
  }, {
    default: _withCtx(() => [
      (_unref(kirjoitus))
        ? (_openBlock(), _createBlock(ComLisaaNimike, {
            key: 0,
            nimikkeet: _unref(kategoriat)
          }, null, 8, ["nimikkeet"]))
        : _createCommentVNode("", true),
      (_unref(nimikkeet)?.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  (_unref(hyvaksytty))
                    ? (_openBlock(), _createBlock(_component_el_checkbox, {
                        key: 0,
                        label: "Muutokset",
                        modelValue: _unref(muutoksetActive),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(muutoksetActive) ? (muutoksetActive).value = $event : null)),
                        border: ""
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_button, { onClick: _unref(togleAll) }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Avaa/Sulje")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_el_collapse, {
              modelValue: _unref(active),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(active) ? (active).value = $event : null))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(kategoriat), (kategoria, index1) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index1 }, [
                    (_unref(hasPaakategoriaItems)(kategoria.id))
                      ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                          key: 0,
                          class: "suunnittelu-collapse-item",
                          name: kategoria.id
                        }, {
                          title: _withCtx(() => [
                            (!_unref(active).includes(kategoria.id))
                              ? (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 0,
                                  class: "collapse-icon-right"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Folder))
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 1,
                                  class: "collapse-icon-right"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(FolderOpened))
                                  ]),
                                  _: 1
                                })),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(kategoria.nimi), 1)
                          ]),
                          default: _withCtx(() => [
                            (kategoria.children)
                              ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(kategoria.children, (aliKategoria, index2) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: index2 }, [
                                        (_unref(hasAlikategoriaItems)(aliKategoria.id))
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createElementVNode("tr", null, [
                                                _createElementVNode("th", _hoisted_5, _toDisplayString(aliKategoria.nimi), 1)
                                              ]),
                                              (!_unref(muutoksetActive))
                                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(nimikkeet), (item, index3) => {
                                                    return (_openBlock(), _createElementBlock(_Fragment, { key: index3 }, [
                                                      (item.nimike?.kategoria?.id === aliKategoria.id)
                                                        ? (_openBlock(), _createBlock(ComSuunnittelutiedotRow, {
                                                            key: 0,
                                                            nimike: item
                                                          }, null, 8, ["nimike"]))
                                                        : _createCommentVNode("", true)
                                                    ], 64))
                                                  }), 128))
                                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(nimikkeetHistoria), (item, index3) => {
                                                    return (_openBlock(), _createElementBlock(_Fragment, { key: index3 }, [
                                                      (item.nimike?.kategoria?.id === aliKategoria.id)
                                                        ? (_openBlock(), _createBlock(ComSuunnittelutiedotRowHistoria, {
                                                            key: 0,
                                                            nimike: item
                                                          }, null, 8, ["nimike"]))
                                                        : _createCommentVNode("", true)
                                                    ], 64))
                                                  }), 128))
                                            ], 64))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128))
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["name"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
        : (_openBlock(), _createBlock(ComEmpty, { key: 2 }))
    ]),
    _: 1
  }, 8, ["way"]))
}
}

})