import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import raportointiService from "@/methods/raportointiService"
import ComMoreButton from "../common/buttons/ComMoreButton.vue"
import { huonekorttiPage } from "@/views/huonekortti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiInfoDropDownButton',
  setup(__props) {

const { isHyvaksytty, huonekortti, showMuutokset, showHanketiedot  } = huonekorttiPage()
const loadHuonekorttiRaportti = raportointiService.loadHuonekorttiRaportti

const tulostaClick = () => {
    if (huonekortti.value) {
        loadHuonekorttiRaportti(1, [huonekortti.value.id])
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, { class: "huonekortti-info-dropdown" }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, { onClick: tulostaClick }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Tulosta")
            ])),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showHanketiedot.value = true))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Tiedot")
            ])),
            _: 1
          }),
          (_unref(isHyvaksytty))
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (showMuutokset.value = true))
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Historia")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(ComMoreButton)
    ]),
    _: 1
  }))
}
}

})