import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { ref, watch } from "vue"
import ComSelect from "../common/ComSelect.vue"
import { useKayttajat } from "@/methods/paakayttajaComposables";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNotifyKommentti',
  emits: ["valitutKayttajat"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const searchStr = ref("")
const { kayttajat, loading } = useKayttajat(searchStr)
const valitutKayttajat = ref<KayttajaResponse[]>()

const loadKayttajat = async (val: string) => {
    if (val && val.length > 2) {
        searchStr.value = val
    }
}

watch(valitutKayttajat, () => {
    emit("valitutKayttajat", valitutKayttajat.value)
})


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!

  return (_openBlock(), _createBlock(ComSelect, {
    modelValue: valitutKayttajat.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valitutKayttajat).value = $event)),
    multiple: "",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "value-key": "email",
    placeholder: "Kirjoita ja valitse",
    loading: _unref(loading),
    "remote-method": loadKayttajat,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(kayttajat), (kayttaja) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: kayttaja,
          label: kayttaja.email,
          value: kayttaja
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "loading"]))
}
}

})