<template>
    <el-row>
        <el-col :span="8" :offset="8" style="text-align: center; margin-bottom: 40px">
            <el-image :src="require('@/assets/vahtilogo.png')"></el-image>
            <h1 style="font-size: 24px">Huolto käynnissä!</h1>
            <p>Vahti v&auml;liaikaisesti poissa k&auml;yt&ouml;st&auml; p&auml;ivityksen takia.</p>
            <el-image :src="require('@/assets/worker.gif')"></el-image>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "HuoltoView",
    setup() {
        return {}
    },
})
</script>
