<template>
    <el-tabs v-if="data">
        <el-tab-pane label="Hanke">
            <el-form>
                <el-form-item label="Hanke">
                    <el-input size="small" v-model="data.nimi" />
                </el-form-item>
                <el-form-item label="Työnumero">
                    <el-input size="small" v-model="data.tyonumero" />
                </el-form-item>
                <el-form-item label="Hankenumero">
                    <el-input size="small" v-model="data.hankenumero" />
                </el-form-item>
                <el-form-item label="Arkistoitu">
                    <el-switch size="small" v-model="data.arkistoitu" />
                </el-form-item>
                <el-form-item label="Sairaala" v-if="data.sairaala">
                    <el-select size="small" v-model="data.sairaala.id" placeholder="Valitse sairaala" v-if="sairaalat">
                        <el-option
                            v-for="item in sairaalat"
                            :key="item.id"
                            :label="item.nimi"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <ComSuccessButton @click="data ? updateHankeClick(data) : ''">Tallenna</ComSuccessButton>
                </el-form-item>
            </el-form>
        </el-tab-pane>
        <el-tab-pane label="Vastuuyksiköt">
            <template v-if="data.vastuuyksikot">
                <ComYllapitoHankeVastuuyksikko
                    v-for="(vastuuyksikko, i) in data.vastuuyksikot"
                    :key="vastuuyksikko.id"
                    v-model="data.vastuuyksikot[i]" />
            </template>
            <el-row>
                <el-col :span="24">
                    <ComYllapitoHankeVastuuyksikkoAdd />
                </el-col>
            </el-row>
        </el-tab-pane>
        <el-tab-pane label="Pääkäyttäjät">
            <ComYllapitoHankePaakayttajat />
        </el-tab-pane>
    </el-tabs>
</template>

<script setup lang="ts">
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComYllapitoHankeVastuuyksikko from "./ComYllapitoHankeVastuuyksikko.vue"
import { useYllapitoComponent } from "./yllapito"
import { useCloned } from "@vueuse/core"
import ComYllapitoHankeVastuuyksikkoAdd from "./ComYllapitoHankeVastuuyksikkoAdd.vue"
import ComYllapitoHankePaakayttajat from "./ComYllapitoHankePaakayttajat.vue"
import { useSairaalat } from "@/methods/sairaalaComposables"
import { ElMessage, ElMessageBox } from "element-plus"
import { HankeResponse } from "@/api/models/hanke-response"
import { useUpdateHanke } from "@/methods/hankeComposables"
import { watch } from "vue"
import { HankeRequest } from "@/api/models/hanke-request"

const { sairaalat } = useSairaalat()
const { updateHanke, error } = useUpdateHanke()
const { selectedHanke } = useYllapitoComponent()
const { cloned: data } = useCloned(selectedHanke)

const updateHankeClick = async (hanke: HankeResponse) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti tallentaa muutokset?", "Tallenna muutokset", {
        confirmButtonText: "Tallenna",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (!confirm) {
        return
    } else {
        const request = hanke as HankeRequest

        updateHanke(hanke.id, request)
    }
}

watch(error, () => {
    if (error.value) {
        ElMessage.error("Hankkeen päivitys epäonnistui!")
    }
})

</script>
