import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { ElMessageBox } from "element-plus"
import { huonekorttiListing } from "../huonekortit";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiDeleteButton',
  props: {
    huonekortti: {}
  },
  setup(__props: any) {

const props = __props

const { deleteHuonekortti } = huonekorttiListing()
const deleteHuonekorttiClick = () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa huonekortin: " + props.huonekortti.tilanimike?.nimi).then(
        () => {
            if (props.huonekortti.id) {
                deleteHuonekortti(props.huonekortti.id)
            }
        }
    )
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", { onClick: deleteHuonekorttiClick }, "Poista"))
}
}

})