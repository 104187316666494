import { KayttajaResponse } from "@/api/models/kayttaja-response"
import http from "@/http-common"
import { useMutation, useQuery } from "@tanstack/vue-query"
import { computed, Ref } from "vue"

const kirjautunutKayttajaUrl = "kayttajat/kirjautunut"
const kayttajaUrl = "kayttajat"

export function useKayttaja()  {
    const key = [kirjautunutKayttajaUrl]
    const kayttoehdot = computed(() => kayttaja.value?.ehdot)

    const fetcher = async () : Promise<KayttajaResponse> => {
        const response = await http.get(kirjautunutKayttajaUrl)
        return response.data
    }

    const { data: kayttaja, error, isLoading: loading} = useQuery({ queryKey: key, queryFn: () => fetcher() })

    return {
        kayttaja,
        kayttoehdot,
        error,
        loading,
    }
}

export function useKayttajat(email?: Ref<string>, page?: Ref<number>) {
    const key = [kayttajaUrl, { email: email?.value, page: page?.value }]
    const fetcher = async () => {
        const response = await http.get(kayttajaUrl, { params: { email: email?.value, page: page?.value } })
        return response.data
    }

    const { data: kayttajat, error, isLoading: loading } = useQuery({ queryKey: key, queryFn: () => fetcher() })

    return {
        kayttajat,
        error,
        loading,
    }
}

export function useConsestGDPR() {
    const { 
        error, 
        data: response, 
        isPending: 
        loading, 
        mutate} = useMutation({
        mutationFn: () => http.post("/kayttajat/hyvaksyEhdot"),
    })

    const consestGDPR = async () => {
        await mutate()
    }

    return {
        error,
        response,
        loading,
        consestGDPR,
    }
}

export function useResetPassword() {
    const { 
        error, 
        data: response, 
        isPending: 
        loading, 
        mutate} = useMutation({
        mutationFn: () => http.post("/kayttajat/vaihdaSalasana"),
    })

    const resetPassword = async () => {
        await mutate()
    }

    return {
        error,
        response,
        loading,
        resetPassword,
    }
}
