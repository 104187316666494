import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue"
import { useComTuonti } from "./tuonti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComVarmistus',
  setup(__props) {

const { huonekortit, vastuuyksikko, vastuuyksikot, peruuta, hyvaksy } = useComTuonti()
const maara = computed(() => huonekortit.value.length)
const vastuuyksikkoNimi = computed(() => vastuuyksikot.value?.find((v) => v.id === vastuuyksikko.value)?.nimi)


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createTextVNode("Olet tuomassa " + _toDisplayString(maara.value) + " huonekorttia vastuuyksikköön " + _toDisplayString(vastuuyksikkoNimi.value), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { justify: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { push: 18 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              class: "button",
              onClick: _unref(peruuta)
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Peruuta")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, {
              size: "small",
              type: "success",
              class: "button",
              onClick: _unref(hyvaksy)
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Tuo huonekortit")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})