<template>
    <template v-if="paakayttajat">
        <el-row v-for="paakayttaja in paakayttajat" :key="paakayttaja.id">
            <el-col :span="10">
                <span>{{ paakayttaja.kayttaja.etunimi }} {{ paakayttaja.kayttaja.sukunimi }}</span>
            </el-col>
            <el-col :span="12" :offset="2">
                <el-form-item>
                    <ComDeleteButton @click="deletePaakayttajaClick(paakayttaja.id)">Poista</ComDeleteButton>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-space>
                <el-form-item label="Lisää pääkäyttäjä">
                    <ComSelect
                        v-model="selectedKayttaja"
                        filterable
                        remote
                        reserve-keyword
                        value-key="email"
                        placeholder="Kirjoita ja valitse"
                        :loading="loading"
                        :remote-method="fetchKayttajat"
                        style="min-width: 300px">
                        <el-option
                            v-for="kayttaja in kayttajat"
                            :key="kayttaja"
                            :label="kayttaja.email"
                            :value="kayttaja" />
                    </ComSelect>
                </el-form-item>
                <el-form-item>
                    <ComSuccessButton @click="addPaakayttajaClick">Lisää</ComSuccessButton>
                </el-form-item>
            </el-space>
        </el-row>
    </template>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useYllapitoComponent } from "./yllapito"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { ElMessage, ElMessageBox } from "element-plus"
import ComSelect from "../common/ComSelect.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { useDeletePaakayttaja, useKayttajat } from "@/methods/paakayttajaComposables"

const searhString = ref("")
const { deletePaakayttaja, error: deleteError } = useDeletePaakayttaja()
const { paakayttajat, addPaakayttaja } = useYllapitoComponent()
const { kayttajat, loading } = useKayttajat(searhString)
const selectedKayttaja = ref<KayttajaResponse>()

const fetchKayttajat = (val: string) => {
    searhString.value = val
}

const addPaakayttajaClick = () => {
    if (selectedKayttaja.value) {
        addPaakayttaja(selectedKayttaja.value.id)

        selectedKayttaja.value = undefined
    } else {
        ElMessage.warning("Valitse käyttäjä")
    }
}

const deletePaakayttajaClick = async (id: number) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti poistaa pääkäyttäjän?", "Varoitus", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (confirm) {
        deletePaakayttaja(id)
    }
}

watch(deleteError, (value) => {
    if (value) {
        ElMessage.error("Pääkäyttäjän poisto epäonnistui")
    }
})
</script>
