import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { style: {"margin-left":"20px"} }

import { computed, ref } from "vue"
import ComLoading from "../common/ComLoading.vue"
import ComYllapitoHankeTableVastuuyksikot from "./ComYllapitoHankeTableVastuuyksikot.vue"
import ComCard from "../common/ComCard.vue"
import { HankeResponse } from "@/api/models/hanke-response"
import ComYllapitoHankeEdit from "./ComYllapitoHankeEdit.vue"
import { useYllapitoComponent } from "./yllapito"
import ComYllapitoHankeLisaa from "./ComYllapitoHankeLisaa.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { useHankkeet } from "@/methods/hankeComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComYllapitoHankeTable',
  setup(__props) {

const { hankkeet, arkistoidut } = useHankkeet()
const { selectedHanke, setSelectedHanke } = useYllapitoComponent()
const showEdit = ref(false)
const showAdd = ref(false)
const show = computed(() => showEdit.value || showAdd.value)

const handleEdit = (row: HankeResponse) => {
    setSelectedHanke(row.id)
    showEdit.value = true
}

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ComCard, { header: true }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("span", null, "Hankkeet", -1)),
          _createVNode(_component_el_switch, {
            style: {"float":"right"},
            modelValue: _unref(arkistoidut),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(arkistoidut) ? (arkistoidut).value = $event : null)),
            "active-text": "Arkistoidut",
            "inactive-text": "Aktiiviset"
          }, null, 8, ["modelValue"])
        ])
      ]),
      default: _withCtx(() => [
        (!_unref(hankkeet))
          ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
          : (_openBlock(), _createBlock(_component_el_table, {
              key: 1,
              data: _unref(hankkeet)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { type: "expand" }, {
                  default: _withCtx((props) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(ComYllapitoHankeTableVastuuyksikot, {
                        vastuuyksikot: props.row.vastuuyksikot
                      }, null, 8, ["vastuuyksikot"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "Hanke",
                  prop: "nimi"
                }),
                _createVNode(_component_el_table_column, {
                  label: "Hyvinvointialue",
                  prop: "sairaala.nimi"
                }),
                _createVNode(_component_el_table_column, null, {
                  default: _withCtx((props) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      onClick: ($event: any) => (handleEdit(props.row))
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Muokkaa")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"])),
        _createVNode(_component_el_divider, { "border-style": "none" }),
        _createVNode(ComSuccessButton, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (showAdd.value = true))
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Lisää hanke")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_drawer, {
      modelValue: show.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((show).value = $event)),
      onClose: _cache[3] || (_cache[3] = ($event: any) => {;(showEdit.value = false), (showAdd.value = false)}),
      "destroy-on-close": "",
      size: "60%",
      title: "Hanke"
    }, {
      default: _withCtx(() => [
        (showEdit.value)
          ? (_openBlock(), _createBlock(ComYllapitoHankeEdit, {
              key: 0,
              show: showEdit.value,
              hanke: _unref(selectedHanke)
            }, null, 8, ["show", "hanke"]))
          : _createCommentVNode("", true),
        (showAdd.value)
          ? (_openBlock(), _createBlock(ComYllapitoHankeLisaa, { key: 1 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})