<template>
    <el-timeline-item :timestamp="previousData.aika" placement="top" :type="timelineColor" size="large">
        <ComCard>
            <div class="card-header huonekortti-historia-item-header">{{ otsikko }}</div>
            <table style="width: 100%">
                <tbody>
                    <tr>
                        <td>
                            <label>Tekijä:</label>
                            {{ previousData.kayttaja?.etunimi }} {{ previousData.kayttaja?.sukunimi }}
                        </td>
                        <td>
                            <label>Aika:</label>
                            {{ previousData.aika }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Tilatunnus:</label>
                            <span v-html="tilatunnus" />
                        </td>
                        <td>
                            <label>Pinta-ala:</label>
                            <span v-html="pintaAla" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </ComCard>
    </el-timeline-item>
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { TilaHistoriaResponse } from "@/api/models/tila-historia-response"
import { TilaResponse } from "@/api/models/tila-response"
import ComCard from "@/components/common/ComCard.vue"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import { diffAndSanitize } from "@/utils/diff"
import { computed } from "vue"

const props = defineProps<{
    data: TilaHistoriaResponse | TilaResponse | undefined
    previousData: TilaHistoriaResponse
}>()

const { tyyppiColor } = useHuonekorttiHistoriaMethods()
const tyyppi = computed(() => props.previousData.tyyppi)
const timelineColor = computed(() => tyyppiColor(tyyppi.value))
const tilatunnus = computed(() => {
    const a = props.previousData.tilatunnus ?? ""
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = props.data.tilatunnus ?? ""
        return diffAndSanitize(a, b)
    } else {
        return a
    }
})
const pintaAla = computed(() => {
    const a = props.previousData.pintaAla ? props.previousData.pintaAla.toString() : ""
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos && props.data) {
        const b = props.data.pintaAla ? props.data.pintaAla.toString() : ""
        return diffAndSanitize(a, b)
    } else {
        return a
    }
})
const otsikko = computed(() => {
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Lisays) {
        return "Tila lisätty"
    }
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Muutos) {
        return "Tilaa muokattu"
    }
    if (tyyppi.value == HuonekorttiHistoriaTyyppi.Poisto) {
        return "Tila poistettu"
    } else {
        throw new Error("Virheellinen tyyppi!")
    }
})
</script>
