import { HankeRequest } from "@/api/models/hanke-request";
import { ref } from "vue";

const state = ref<HankeRequest>({
    nimi: "",
    vaatimukset: "",
    tyonumero: "",
    hankenumero: "",
    sairaala: { id: 0 },
    vastuuyksikot: [{ nimi: "", vaatimukset: "" }],
})

export function useYllapitoHankeComponent() {
    const formatHanke = () => {
        state.value.nimi = ""
        state.value.vaatimukset = ""
        state.value.tyonumero = ""
        state.value.hankenumero = ""
        state.value.sairaala = { id: 0 }
        state.value.vastuuyksikot = [{ nimi: "", vaatimukset: "" }]
    }

    const addVaastuuyksikko = () => {
        state.value.vastuuyksikot.push({ nimi: "", vaatimukset: "" })
    }

    const deleteVaastuuyksikko = (index: number) => {
        if (state.value.vastuuyksikot.length > 1) {
            state.value.vastuuyksikot.splice(index, 1)
        }
    }

    return {
        hanke: state,
        formatHanke,
        addVaastuuyksikko,
        deleteVaastuuyksikko,      
    }

}