import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createBlock(_component_el_icon, {
    "el-icon": "",
    size: 20,
    style: {"cursor":"pointer"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_InfoFilled)
    ]),
    _: 1
  }))
}