<template>
    <el-alert type="info" title="Huom! Mallihuonekortit ovat hyvinvointialue kohtaisia." />
    <template v-if="loading">
        <el-divider border-style="none" />
        <ComLoading />
    </template>

    <el-table v-else :data="mallihuonekortit">
        <el-table-column prop="tilanimike.nimi" label="Tilanimike" />
        <el-table-column prop="toiminta" label="Tilanimikkeen täsmennys" />
        <el-table-column>
            <template #default="scope">
                <ComDeleteButton @click="deleteClick(scope.row.id)" />
            </template>
        </el-table-column>
    </el-table>
</template>

<script setup lang="ts">
import { computed, watch } from "vue"
import { useDeleteMallihuonekortti, useMallihuonekortit } from "@/methods/mallihuonekortitComposables"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { ElMessage, ElMessageBox } from "element-plus"
import { hankePage } from "@/views/hanke"
import ComLoading from "../common/ComLoading.vue"

const { hanke } = hankePage()
const sairaala = computed(() => hanke?.value?.sairaala?.id)
const { mallihuonekortit, loading } = useMallihuonekortit(sairaala)
const { deleteMallihuonekortti, error, response } = useDeleteMallihuonekortti()

const deleteClick = async (id: number) => {
    ElMessageBox.confirm("Haluatko varmasti poistaa mallihuonekortin?").then(async () => {
        deleteMallihuonekortti(id)
    })
}

watch(response, (response) => {
    if(response) {
        ElMessage.success("Mallihuonekortti poistettu")
    }
})

watch(error, (error) => {
    if(error) {
        ElMessage.error("Mallihuonekortin poisto epäonnistui")
    }
})
</script>
