<template>
    <div>
        <ComAttatchmenButton @click="showLiitteet"></ComAttatchmenButton>
        <template v-if="show">
            <el-drawer v-model="show" append-to-body destroy-on-close title="Liitteet" size="50%">
                <ComLiiteList />
            </el-drawer>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import ComAttatchmenButton from "../common/buttons/ComAttatchmentButton.vue"
import ComLiiteList from "./ComLiiteList.vue"
import kommenttiTyyppi from "@/types/Kommentti";
import { useLiiteComponent } from "./liite";

const props = defineProps<{
    viite: number
    tyyppi: kommenttiTyyppi
}>()
const show = ref(false)
const { viite: _viite, tyyppi: _tyyppi } = useLiiteComponent()

const showLiitteet = () => {
    show.value = true
    _viite.value = props.viite
    _tyyppi.value = props.tyyppi
}

</script>
