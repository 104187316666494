<template>
    <tr :class="{ poisto: tyyppiIsPoisto(nimike), lisays: tyyppiIsLisays(nimike) }" style="height: 40px">
        <td style="width: 15%">{{ nimike.nimike?.nimi }}</td>
        <td style="width: 5%">
            <span v-html="nimike.maara" />
        </td>
        <td style="width: 35%">
            <span v-html="nimike.tasmennys" />
        </td>
        <td style="width: 35%">
            <template v-if="nimike.ominaisuudet">
                <el-tag
                    type="info"
                    size="small"
                    style="margin-right: 2px"
                    v-for="(ominaisuus, index) in nimike.ominaisuudet"
                    :key="index">
                    <span v-html="ominaisuus.nimi" />
                </el-tag>
            </template>
        </td>
        <td style="padding-right: 20px">
            <div style="width: 100px">
                <template>
                    <template v-if="!nimike.nimike?.kaytossa">
                        <el-button text @click.prevent="" size="small" circle>
                            <ComTooltip content="Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella.">
                                <el-icon :size="20">
                                    <Warning />
                                </el-icon>
                            </ComTooltip>
                        </el-button>
                    </template>
                </template>
            </div>
            <div v-if="'tyyppi' in nimike">
                <ComNimikeHistoriaPopover :id="nimike.huonekorttiNimikeId" />
            </div>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import HistoriaNimike from "@/types/HistoriaNimike"
import ComNimikeHistoriaPopover from "./historia/suunnittuelutiedot/ComNimikeHistoriaPopover.vue";
import ComTooltip from "../common/ComTooltip.vue";
import { Warning } from "@element-plus/icons-vue"

const props = defineProps<{ nimike: HistoriaNimike | HuonekorttiNimikeResponse }>()
const { tyyppiIsLisays, tyyppiIsPoisto } = useHuonekorttiHistoriaMethods()
</script>

<style scoped>
.lisays {
    background-color: rgb(195 255 225);
}
.poisto {
    background-color: rgb(255 195 195);
}
</style>
