import { ref, watch } from "vue"
import { HuonekorttiSahkoluokka } from "@/api/models/huonekortti-sahkoluokka"
import ToimintaAika from "@/types/ToimintaAikaEnum"
import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { useHuonekorttiUpdate } from "@/methods/huonekorttiComposablses"
import ToimintatiedotForm from "@/types/ToimintatiedotForm"
import { huonekorttiPage } from "@/views/huonekortti"
import { ElMessage } from "element-plus"

export function useToimintatiedot() {
    const { huonekortti, kirjoitus, setEdited } = huonekorttiPage()
    const { response, error, updateHuonekortti } = useHuonekorttiUpdate()
    const sahkoluokka = HuonekorttiSahkoluokka
    const toimintaAika = ToimintaAika
    const toimintatiedot = ref<ToimintatiedotForm | undefined>()
    const toimintatiedotOriginal = ref<ToimintatiedotForm | undefined>()

    const tallenna = async () => {
        if (!huonekortti.value || !toimintatiedot.value) {
            return
        }

        const huonekorttiRequest: HuonekorttiRequestPut = {}
        Object.assign(huonekorttiRequest, huonekortti.value)
        Object.assign(huonekorttiRequest, toimintatiedot.value)

       updateHuonekortti(huonekortti.value?.id, huonekorttiRequest) 
    }

    watch(huonekortti, () => {
        if(huonekortti.value) {
            toimintatiedot.value = {
                id: huonekortti.value.id,
                sahkoluokka: huonekortti.value?.sahkoluokka ?? null,
                toimintaAika: huonekortti.value?.toimintaAika ?? "",
                toiminnanKuvaus: huonekortti.value?.toiminnanKuvaus ?? "",
                toiminnanVaatimukset: huonekortti.value?.toiminnanVaatimukset ?? "",
                henkilomaaraNorm: huonekortti.value?.henkilomaaraNorm ?? NaN,
                henkilomaaraMax: huonekortti.value?.henkilomaaraMax ?? NaN,
            }
            toimintatiedotOriginal.value = JSON.parse(JSON.stringify(toimintatiedot.value))
        }
    },{immediate: true})

    watch(response, (val) => {
        if (val) {
            ElMessage.success("Toimintatiedot tallennettu")
        }
    })
    watch(error, (val) => {
        if (val) {
            ElMessage.error("Toimintatietojen tallennus epäonnistui")
        }
    })
    watch(
        () => toimintatiedot,
        () => {
            if (toimintatiedot.value) {
                if (JSON.stringify(toimintatiedot.value) != JSON.stringify(toimintatiedotOriginal.value)) {
                    setEdited("toimintatiedot", true)
                } else {
                    setEdited("toimintatiedot", false)
                }
            }
        },
        { deep: true })

    return {
        sahkoluokka,
        toimintaAika,
        toimintatiedot,
        kirjoitus,
        tallenna
    }
}