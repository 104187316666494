<template>
    <template v-if="!edit">
        <el-space direction="vertical" fill style="display: flex">
            <el-card style="width: 100%">
                <ComEmpty v-if="!vaatimus" />
                <span v-else v-html="sanitize(vaatimus)" />
            </el-card>
            <div v-if="editable">
                <ComSuccessButton @click="edit = true">Muokkaa</ComSuccessButton>
            </div>
        </el-space>
    </template>
    <template v-else>
        <el-space direction="vertical" fill style="display: flex">
            <div>
                <ComEditor :disabled="false" v-model="vaatimusEditable" initial />
            </div>
            <div>
                <el-space>
                    <ComSuccessButton @click="saveVaatimusClick">Tallenna</ComSuccessButton>
                    <ComCancelButton @click="edit = false">Peruuta</ComCancelButton>
                </el-space>
            </div>
        </el-space>
    </template>
</template>

<script setup lang="ts">
import ComCancelButton from "@/components/common/buttons/ComCancelButton.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComEditor from "@/components/common/ComEditor.vue"
import ComEmpty from "@/components/common/ComEmpty.vue"
import sanitize from "sanitize-html"
import { PropType, ref, watch, watchEffect } from "vue"
import { useHanketiedotComponent } from "./hanketiedot"
import { useUpdateHanke } from "@/methods/hankeComposables"
import { HankeRequest } from "@/api/models/hanke-request"
import { ElMessage } from "element-plus"
import { VastuuyksikkoRequest } from "@/api/models/vastuuyksikko-request"
import { HankeVaatimusRequestPut } from "@/api/models/hanke-vaatimus-request-put"
import { HankeVaatimusRequest } from "@/api/models/hanke-vaatimus-request"
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { useAddHankeVaatimus, useUpdateHankeVaatimus } from "@/methods/hankeVaatimusComposables"
import { useUpdateVastuuyksikko } from "@/methods/vastuuyksikkoComposables"
import VaatimusType from "@/types/VaatimusEnum"

const props = defineProps({
    vaatimus: {
        type: String,
        required: false,
        default: "",
    },
    vastuuyksikko: {
        type: Object as PropType<VastuuyksikkoResponse>,
        required: false,
    },
    kategoriaId: {
        type: Number,
        required: false,
    },
    type: {
        type: String as PropType<VaatimusType>,
        required: true,
    },
})
const { hanke, editable, vastuuyksikot } = useHanketiedotComponent()
const {  error: hankeUpdateError, loading: hankeUpdateLoading, response: hankeUpdateResponse, updateHanke } = useUpdateHanke()
const { updateVastuuyksikko, loading: vastuuyksikkoUpdateLoading, error: vastuuyksikkoUpdateError, response: vastuuyksikkoUpdateResponse  } = useUpdateVastuuyksikko()
const { updateHankeVaatimus, loading: hankeVaatimusUpdateLoading, error: hankeVaatimusUpdateError, response: hankeVaatimusUpdateResponse } = useUpdateHankeVaatimus()
const { addHankeVaatimus, loading: hankeVaatimusAddLoading, error: hankeVaatimusAddError, response: hankeVaatimusAddResponse } = useAddHankeVaatimus()
const edit = ref(false)
const vaatimusEditable = ref(props.vaatimus)

const saveVaatimusClick = async () => {
    if(props.type === VaatimusType.Hanke && hanke.value) {
        const request : HankeRequest = Object.assign({} as HankeRequest, hanke.value, { vaatimukset: vaatimusEditable.value })
        
        await updateHanke(hanke.value.id, request)
    } else if(props.type === VaatimusType.Vastuuyksikko && props.vastuuyksikko) {
        const request = Object.assign({} as VastuuyksikkoRequest, props.vastuuyksikko, { vaatimukset: vaatimusEditable.value })

        await updateVastuuyksikko(props.vastuuyksikko.id, request)
    } else if(props.type === VaatimusType.Kategorisoidut && props.kategoriaId && hanke.value) {
        const hankeVaatimusId = hanke.value?.kategorisoidutVaatimukset?.find(k => k.kategoria?.id === props.kategoriaId)?.id

        if(hankeVaatimusId) {
            const request: HankeVaatimusRequestPut = {}

            request.vaatimus = vaatimusEditable.value

            await updateHankeVaatimus(hankeVaatimusId, request)
        } else {
            const request: HankeVaatimusRequest = {}

            request.vaatimus = vaatimusEditable.value
            request.hanke = { id: hanke.value?.id }
            request.kategoria = { id: props.kategoriaId }

            await addHankeVaatimus(request)
        }
    }

    edit.value = false
}

watch(
    () => vastuuyksikot,
    () => {
        edit.value = false
    }
)
watch(
    () => props.vaatimus,
    () => {
        vaatimusEditable.value = props.vaatimus
})
watchEffect(() => {
    if(!hankeUpdateLoading.value || !vastuuyksikkoUpdateLoading.value || !hankeVaatimusUpdateLoading.value || !hankeVaatimusAddLoading.value) {
        if(hankeUpdateResponse.value || vastuuyksikkoUpdateResponse.value || hankeVaatimusUpdateResponse.value || hankeVaatimusAddResponse.value) {
            ElMessage.success("Vaatimukset tallennettu!")
        }
    }
})
watchEffect(() => {
    if(hankeUpdateError.value || hankeVaatimusUpdateError.value || hankeVaatimusAddError.value || vastuuyksikkoUpdateError.value) {
        ElMessage.error("Vaatimuksien tallennus epäonnistui")
    }
})
</script>
