<template>
    <div>
        <com-loading v-if="!sairaalat"></com-loading>
        <el-form ref="ruleFormRef" :model="hanke" :rules="rules" v-else>
            <el-form-item label="Nimi" prop="nimi">
                <el-input size="small" v-model="hanke.nimi"></el-input>
            </el-form-item>
            <el-form-item label="Vaatimukset" prop="vaatimukset">
                <el-input size="small" v-model="hanke.vaatimukset"></el-input>
            </el-form-item>
            <el-form-item label="Hankenumero" prop="hankenumero">
                <el-input size="small" v-model="hanke.hankenumero"></el-input>
            </el-form-item>
            <el-form-item label="Työnumero" prop="tyonumero">
                <el-input size="small" v-model="hanke.tyonumero"></el-input>
            </el-form-item>
            <el-form-item label="Sairaala" prop="sairaala">
                <el-select size="small" v-model="hanke.sairaala" value-key="id">
                    <el-option
                        v-for="sairaala in sairaalat"
                        :key="sairaala.id"
                        :label="sairaala.nimi"
                        :value="sairaala" />
                </el-select>
            </el-form-item>
            <ComYllapitoHankeLisaaVastuuyksikko />
            <el-form-item>
                <el-button type="primary" @click="addHankeClick">Lisää</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup lang="ts">
import { HankeRequest } from "@/api/models/hanke-request"
import { ElMessage, FormInstance, FormRules } from "element-plus"
import { ref, watch } from "vue"
import ComLoading from "../common/ComLoading.vue"
import ComYllapitoHankeLisaaVastuuyksikko from "./ComYllapitoHankeLisaaVastuuyksikko.vue"
import { useSairaalat } from "@/methods/sairaalaComposables"
import { useYllapitoHankeComponent } from "./yllapitoHanke"
import { useAddHanke } from "@/methods/hankeComposables"

const { sairaalat } = useSairaalat()
const { hanke, formatHanke } = useYllapitoHankeComponent()
const { addHanke, error, response } = useAddHanke()

const ruleFormRef = ref<FormInstance>()
const rules = ref<FormRules<HankeRequest>>({
    nimi: [{ required: true, message: "Nimi vaaditaan", trigger: "blur" }],
    sairaala: [{ required: true, message: "Sairaala vaaditaan", trigger: "blur" }],
})

const addHankeClick = async () => {
    await ruleFormRef.value?.validate((valid) => {
        if (!valid) {
            return
        } else {
            addHanke(hanke.value)
        }
    })
}

watch(error, () => {
    ElMessage.error("Hankeen lisäys epäonnistui")
})

watch(response, () => {
    if(response.value) {
        formatHanke()
    }
})
</script>
