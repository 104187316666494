<template>
    <ComLoading v-if="!kommentit"></ComLoading>
    <ComEmpty v-else-if="kommentit.length == 0"></ComEmpty>
    <template v-else>
        <div v-for="(kommentti, index) in kommentit" :key="index" class="card-margin-bottom">
            <ComCard header>
                <template #header>
                    <span style="margin-right: 5px">{{ kommentti.aika }}</span>
                    <label>{{ kommentti.kayttaja?.etunimi + " " + kommentti.kayttaja?.sukunimi }}</label>
                    <span style="float: right">
                        <ComDeleteKommentti :kommentti="kommentti"></ComDeleteKommentti>
                    </span>
                </template>
                {{ kommentti.kommentti }}
            </ComCard>
        </div>
    </template>
    <ComAddKommentti v-if="kommentit" :tyyppi="tyyppi" :viite="viite"></ComAddKommentti>
</template>

<script setup lang="ts">
import ComCard from "../common/ComCard.vue"
import ComDeleteKommentti from "./ComDeleteKommentti.vue"
import ComLoading from "../common/ComLoading.vue"
import ComEmpty from "../common/ComEmpty.vue"
import ComAddKommentti from "./ComAddKommentti.vue"
import { useKommentitComponent } from "./kommentit"

const { kommentit, tyyppi, viite } = useKommentitComponent()

</script>
