import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import ComPagination from '@/components/common/ComPagination.vue';
import { tilaListing } from './tilat';
import ComFilterInput from '@/components/common/ComFilterInput.vue';
import ComLoading from '@/components/common/ComLoading.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComTilat',
  setup(__props) {

const {tilat, filter, loading, pageTotal, currentPage, pageSize, tableRef, sortChanged, getSum, vastuuyksikko } = tilaListing();

return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_unref(loading))
    ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(ComFilterInput, {
                  modelValue: _unref(filter),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filter) ? (filter).value = $event : null))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 18 }, {
              default: _withCtx(() => [
                _createVNode(ComPagination, {
                  total: _unref(pageTotal),
                  "page-size": _unref(pageSize),
                  "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => (_isRef(pageSize) ? (pageSize).value = $event : null)),
                  "current-page": _unref(currentPage),
                  "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : null)),
                  style: {"float":"right"}
                }, null, 8, ["total", "page-size", "current-page"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_table, {
          data: _unref(tilat),
          "show-summary": "",
          onSortChange: _unref(sortChanged),
          "summary-method": _unref(getSum),
          ref_key: "tableRef",
          ref: tableRef
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "tilatunnus",
              label: "Tilatunnus",
              sortable: "custom",
              width: "120"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_router_link, {
                  to: { name: 'Huonekortti', params: { huonekorttiId: scope.row.huonekortti.id } },
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate }) => [
                    _createVNode(_component_el_link, {
                      type: "primary",
                      href: href,
                      onClick: navigate
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(scope.row.tilatunnus), 1)
                      ]),
                      _: 2
                    }, 1032, ["href", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "pintaAla",
              label: "Pinta-ala",
              sortable: "custom",
              width: "120"
            }),
            _createVNode(_component_el_table_column, {
              prop: "huonekortti.tilanimike.nimi",
              label: "Tilanimike",
              sortable: "custom",
              width: "200"
            }),
            _createVNode(_component_el_table_column, {
              prop: "vastuuyksikko.nimi",
              label: "Vastuuyksikkö",
              sortable: "custom",
              "show-overflow-tooltip": ""
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_unref(vastuuyksikko)(scope.row.vastuuyksikko.id)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "huonekortti.toiminta",
              label: "Täsmennys",
              sortable: "custom",
              "show-overflow-tooltip": ""
            })
          ]),
          _: 1
        }, 8, ["data", "onSortChange", "summary-method"])
      ]))
}
}

})