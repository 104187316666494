<template>
    <el-card shadow="always" :class="!s ? tiedotHideBody : ''">
        <template #header>
            <div class="card-header">
                {{ title }}
                <div class="float-right">
                    <el-button class="expandButton" @click="expand" type="info" plain circle size="small" v-if="s">
                        <el-icon v-if="way == 'vertical'"><ArrowUp></ArrowUp></el-icon>
                        <el-icon v-else><ArrowLeft></ArrowLeft></el-icon>
                    </el-button>
                    <el-button class="expandButton" @click="expand" type="info" plain circle size="small" v-if="!s">
                        <el-icon v-if="way == 'vertical'"><ArrowDown></ArrowDown></el-icon>
                        <el-icon v-else><ArrowRight></ArrowRight></el-icon>
                    </el-button>
                </div>
            </div>
        </template>
        <slot></slot>
    </el-card>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import Orientation from "../../types/OrientationEnum"
import { ArrowDown, ArrowRight, ArrowLeft, ArrowUp } from "@element-plus/icons-vue"

export default defineComponent({
    components: {
        ArrowDown,
        ArrowRight,
        ArrowLeft,
        ArrowUp,
    },
    props: {
        show: {
            required: false,
            type: Boolean,
            default: false,
        },
        title: {
            required: true,
            type: String,
        },
        way: {
            required: false,
            type: String as PropType<Orientation>,
            default: Orientation.vertical,
        },
    },
    emits: ["expand"],
    setup(props) {
        const s = ref<boolean>(props.show)
        const tiedotHideBody = ref<string>("tiedot-hide-body")

        const expand = () => {
            s.value = !s.value
        }

        return { s, expand, tiedotHideBody }
    },
})
</script>
<style scoped>
.tiedot-hide-body >>> .el-card__body {
    display: none !important;
}
</style>
