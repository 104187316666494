import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 3 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { colspan: "4" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { colspan: "4" }
const _hoisted_17 = ["innerHTML"]

import { HuonekorttiHistoriaResponse } from "@/api/models/huonekortti-historia-response"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { computed } from "vue"
import { htmlToText } from "html-to-text"
import { diffAndSanitize } from "@/utils/diff";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiHistoriaItem',
  props: {
    data: {},
    previousData: {}
  },
  setup(__props: any) {

const props = __props
    
const henkilomaaraNorm = computed(() => {
    const a = props.previousData.henkilomaaraNorm?.toString() ?? ""
    const b = props.data.henkilomaaraNorm?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const henkilomaaraMax = computed(() => {
    const a = props.previousData.henkilomaaraMax?.toString().trim() ?? ""
    const b = props.data.henkilomaaraMax?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const sahkoluokka = computed(() => {
    const a = props.previousData.sahkoluokka?.toString() ?? ""
    const b = props.data.sahkoluokka?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toimintaAika = computed(() => {
    const a = props.previousData.toimintaAika ?? ""
    const b = props.data.toimintaAika ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toiminnanKuvaus = computed(() => {
    const a = htmlToText(props.previousData.toiminnanKuvaus ?? "")
    const b = htmlToText(props.data.toiminnanKuvaus ?? "")
    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toiminnanVaatimukset = computed(() => {
    const a = htmlToText(props.previousData.toiminnanVaatimukset ?? "")
    const b = htmlToText(props.data.toiminnanVaatimukset ?? "")

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const tyhja = computed(() => {
    if (
        !henkilomaaraMax.value &&
        !henkilomaaraNorm.value &&
        !sahkoluokka.value &&
        !toimintaAika.value &&
        !toiminnanKuvaus.value &&
        !toiminnanVaatimukset.value
    ) {
        return false
    }

    return true
})

return (_ctx: any,_cache: any) => {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!

  return (tyhja.value)
    ? (_openBlock(), _createBlock(_component_el_timeline_item, {
        key: 0,
        timestamp: _ctx.previousData.aika,
        placement: "top",
        type: "primary",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, null, {
            default: _withCtx(() => [
              _createElementVNode("table", _hoisted_1, [
                _createElementVNode("tbody", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, [
                      _cache[0] || (_cache[0] = _createElementVNode("label", null, "Tekijä:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.previousData.kayttaja?.etunimi) + " " + _toDisplayString(_ctx.previousData.kayttaja?.sukunimi), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _cache[1] || (_cache[1] = _createElementVNode("label", null, "Aika:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.previousData.aika), 1)
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    (henkilomaaraNorm.value)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                          _cache[2] || (_cache[2] = _createElementVNode("label", null, "Henkilömäärä normaalisti:", -1)),
                          _createElementVNode("span", { innerHTML: henkilomaaraNorm.value }, null, 8, _hoisted_3)
                        ]))
                      : _createCommentVNode("", true),
                    (henkilomaaraMax.value)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                          _cache[3] || (_cache[3] = _createElementVNode("label", null, "Henkilömäärä maksimi:", -1)),
                          _createElementVNode("span", { innerHTML: henkilomaaraMax.value }, null, 8, _hoisted_5)
                        ]))
                      : _createCommentVNode("", true),
                    (sahkoluokka.value)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                          _cache[4] || (_cache[4] = _createElementVNode("label", null, "Sähköluokka:", -1)),
                          _createElementVNode("span", { innerHTML: sahkoluokka.value }, null, 8, _hoisted_7)
                        ]))
                      : _createCommentVNode("", true),
                    (toimintaAika.value)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                          _cache[5] || (_cache[5] = _createElementVNode("label", null, "Toiminta-aika:", -1)),
                          _createElementVNode("span", { innerHTML: toimintaAika.value }, null, 8, _hoisted_9)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (toiminnanKuvaus.value)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_10, _cache[6] || (_cache[6] = [
                        _createElementVNode("th", { colspan: "4" }, "Toiminnan kuvaus", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (toiminnanKuvaus.value)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                        _createElementVNode("td", _hoisted_12, [
                          _createElementVNode("span", { innerHTML: toiminnanKuvaus.value }, null, 8, _hoisted_13)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (toiminnanVaatimukset.value)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_14, _cache[7] || (_cache[7] = [
                        _createElementVNode("th", { colspan: "4" }, "Toiminnan tilalle asettamat vaatimukset", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (toiminnanVaatimukset.value)
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                        _createElementVNode("td", _hoisted_16, [
                          _createElementVNode("span", { innerHTML: toiminnanVaatimukset.value }, null, 8, _hoisted_17)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["timestamp"]))
    : _createCommentVNode("", true)
}
}

})