import { useKommentit } from "@/methods/kommenttiComposables"
import kommenttiTyyppi from "@/types/Kommentti"
import { computed } from "vue"

interface state {
    isPaakayttaja: boolean,
    tyyppi: kommenttiTyyppi | undefined,
    viite: number | undefined
}

const state = {} as state

export function useKommentitComponent() {
    const viite = computed({
        get: () => state.viite,
        set: (value : number) => {
            state.viite = value 
        }
    })
    const tyyppi = computed({
        get: () => state.tyyppi,
        set: (value : kommenttiTyyppi) => {
            state.tyyppi = value 
        }
    })
    const isPaakayttaja = computed({
        get: () => state.isPaakayttaja,
        set: (value : boolean) => {
            state.isPaakayttaja = value 
        }
    })
    const { kommentit } = useKommentit(viite, tyyppi)


    return {
        kommentit,
        viite,
        tyyppi,
        isPaakayttaja,
    }
}