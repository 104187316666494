import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ComAvatar = _resolveComponent("ComAvatar")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_el_popover, {
      width: 350,
      trigger: "click"
    }, {
      reference: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Avatar)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_ComAvatar)
      ]),
      _: 1
    })
  ]))
}