<template>
    <el-pagination
        v-if="total"
        layout="total, prev, pager, next, sizes"
        :total="total"
        v-model:page-size="pageSize"
        v-model:current-page="currentPage"
        :page-sizes="pageSizes"
        size="small"></el-pagination>
</template>

<script setup lang="ts">
import { ref, defineModel, watch } from "vue"
import { useLocalStorage } from "@vueuse/core"

defineProps({ total: Number })
const pageSizeStorage = useLocalStorage("huonekorttiPageSize", 30)
const currentPage = defineModel<number>("currentPage", { default: 1 })
const pageSize = defineModel<number>("pageSize")

if (pageSizeStorage.value) {
    pageSize.value = pageSizeStorage.value
}

watch(pageSize, () => {
    pageSizeStorage.value = pageSize.value
})

const pageSizes = ref([10, 30, 60, 100])
</script>
