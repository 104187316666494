<template>
    <el-upload :auto-upload="false" v-model:file-list="file" :limit="1">
        <el-icon class="el-icon--upload"><UploadFilled /></el-icon>
        <div class="el-upload__text">
            Vedä tiedosto tähän tai
            <em>klikkaa valitaksesi tiedosto.</em>
        </div>
        <template #tip>
            <div class="el-upload__tip">Tiedoston maksimi koko 5 Mt.</div>
        </template>
    </el-upload>
</template>

<script lang="ts">
import { UploadFilled } from "@element-plus/icons-vue"
import { UploadUserFile } from "element-plus"
import { computed, defineComponent, PropType } from "vue"

export default defineComponent({
    emits: ["fileRemoveClicked", "update:modelValue"],
    components: {
        UploadFilled,
    },
    props: {
        modelValue: {
            type: Object as PropType<UploadUserFile[]>,
        },
    },
    setup(props, { emit }) {
        const file = computed({
            get() {
                return props.modelValue
            },
            set(value) {
                emit("update:modelValue", value)
            },
        })

        return {
            file,
        }
    },
})
</script>
