import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

import ComHanketiedotVaatimukset from "./ComHanketiedotVaatimukset.vue"
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response";
import { computed, PropType, ref, watchEffect, } from "vue";
import { useHanketiedotComponent } from "./hanketiedot";
import ComStatusTag from "@/components/common/ComStatusTag.vue";
import ComHanketiedotHankeEdit from "./ComHanketiedotHankeEdit.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHanketiedot',
  props: {
    editable: {
        type: Boolean,
        required: true,
    },
    huonekorttiId: {
        type: Number,
        required: false,
    },
    hankeId: {
        type: Number,
        required: false,
    },
    vastuuyksikot: {
        type: Object as PropType<VastuuyksikkoResponse[]>,
        required: false,
    }
},
  setup(__props) {

const props = __props

const { editable: _editable, hankeId: _hankeId, huonekorttiId: _huonekorttiId, vastuuyksikot: _vastuuyksikot, vastuuyksikko, huonekortti, hanke, tilat } = useHanketiedotComponent()
const edit = ref(false)
const title = computed(() => "Hanketiedot" + (huonekortti.value?.tilanimike ? ` ja ${huonekortti.value.tilanimike?.nimi?.toLowerCase()}n tiedot` : ""))

watchEffect(() => {
    _hankeId.value = props.hankeId
    _huonekorttiId.value = props.huonekorttiId
    _editable.value = props.editable
    _vastuuyksikot.value = props.vastuuyksikot
})


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            (!edit.value)
              ? (_openBlock(), _createBlock(_component_el_descriptions, {
                  key: 0,
                  title: title.value,
                  direction: "horizontal",
                  column: 2,
                  border: ""
                }, {
                  extra: _withCtx(() => [
                    (__props.editable && !edit.value)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "success",
                          size: "small",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (edit.value=true))
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Muokkaa")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_descriptions_item, { label: "Hankkeen nimi" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(hanke)?.nimi), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, { label: "Hankenumero" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(hanke)?.hankenumero), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_descriptions_item, { label: "Hankkeen työnumero" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(hanke)?.tyonumero), 1)
                      ]),
                      _: 1
                    }),
                    (_unref(vastuuyksikko))
                      ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
                          key: 0,
                          label: "Vastuuyksikkö"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(vastuuyksikko).nimi), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_el_descriptions_item, { label: "Organisaatio" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(hanke)?.sairaala?.nimi), 1)
                      ]),
                      _: 1
                    }),
                    (_unref(huonekortti))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_el_descriptions_item, { label: "Tilanimike" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(huonekortti).tilanimike?.nimi), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_descriptions_item, { label: "Tilanimikkeen täsmennys" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(huonekortti).toiminta), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_descriptions_item, { label: "Status" }, {
                            default: _withCtx(() => [
                              _createVNode(ComStatusTag, {
                                status: _unref(huonekortti).status
                              }, null, 8, ["status"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_descriptions_item, { label: "Koskee tiloja" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(tilat)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_descriptions_item, { label: "Luotu" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(huonekortti).lisaysAika), 1)
                            ]),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : (_openBlock(), _createBlock(ComHanketiedotHankeEdit, {
                  key: 1,
                  modelValue: edit.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((edit).value = $event))
                }, null, 8, ["modelValue"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(ComHanketiedotVaatimukset)
    ])
  ], 64))
}
}

})