import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { OikeusRequest } from "@/api/models/oikeus-request"
import { OikeusResponse } from "@/api/models/oikeus-response"
import Oikeudet from "@/types/OikeudetEnum"
import { PropType } from "vue"



export default /*@__PURE__*/_defineComponent({
  __name: 'ComOikeusTableButtons',
  props: {
    oikeus: {
        type: Object as PropType<OikeusResponse | undefined>,
    },
    kayttaja: {
        type: Number,
        required: true,
    },
    vastuuyksikko: {
        type: Number,
        required: true,
    },

},
  emits: ["updateOikeus", "addOikeus", "deleteOikeus"],
  setup(__props, { emit: __emit }) {

const emit = __emit
const props = __props
const oikeudet = Oikeudet
const changeOikeus = (oikeus: OikeusResponse | undefined, type: Oikeudet) => {
    if (!oikeus) {
        const request: OikeusRequest = {
            kayttaja: { id: props.kayttaja },
            vastuuyksikko: { id: props.vastuuyksikko },
            luku: true,
            kirjoitus: type == Oikeudet.Kirjoitus ? true : false,
            raportointi: type == Oikeudet.Raportointi || Oikeudet.Kirjoitus ? true : false,
        }

        emit("addOikeus", request)
    } else {
        const oikeusUpdated = { ...oikeus }

        if (type == Oikeudet.Luku && oikeus.luku) {
            emit("deleteOikeus", oikeus.id)

            return
        }
        else if (type == Oikeudet.Kirjoitus) {
            oikeusUpdated.kirjoitus = !oikeus.kirjoitus

            if (!oikeus.kirjoitus) {
                oikeusUpdated.raportointi = true
            }
        }
        else if (type == Oikeudet.Raportointi) {
            oikeusUpdated.raportointi = !oikeus.raportointi
        }

        emit("updateOikeus", oikeus.id, oikeusUpdated)
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tag, {
      class: "oikeus-tag",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (changeOikeus(__props.oikeus, _unref(oikeudet).Luku))),
      size: "small",
      type: __props.oikeus && __props.oikeus.luku ? 'success' : 'danger',
      style: {"margin-right":"3px"}
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode(" L ")
      ])),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_el_tag, {
      class: "oikeus-tag",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (changeOikeus(__props.oikeus, _unref(oikeudet).Kirjoitus))),
      size: "small",
      type: __props.oikeus && __props.oikeus.kirjoitus ? 'success' : 'danger',
      style: {"margin-right":"3px"}
    }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" K ")
      ])),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_el_tag, {
      class: "oikeus-tag",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (changeOikeus(__props.oikeus, _unref(oikeudet).Raportointi))),
      size: "small",
      type: __props.oikeus && __props.oikeus.raportointi ? 'success' : 'danger'
    }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [
        _createTextVNode(" R ")
      ])),
      _: 1
    }, 8, ["type"])
  ], 64))
}
}

})