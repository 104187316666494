import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed, watch } from "vue"
import { useDeleteMallihuonekortti, useMallihuonekortit } from "@/methods/mallihuonekortitComposables"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import { ElMessage, ElMessageBox } from "element-plus"
import { hankePage } from "@/views/hanke"
import ComLoading from "../common/ComLoading.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComMallihuonekortit',
  setup(__props) {

const { hanke } = hankePage()
const sairaala = computed(() => hanke?.value?.sairaala?.id)
const { mallihuonekortit, loading } = useMallihuonekortit(sairaala)
const { deleteMallihuonekortti, error, response } = useDeleteMallihuonekortti()

const deleteClick = async (id: number) => {
    ElMessageBox.confirm("Haluatko varmasti poistaa mallihuonekortin?").then(async () => {
        deleteMallihuonekortti(id)
    })
}

watch(response, (response) => {
    if(response) {
        ElMessage.success("Mallihuonekortti poistettu")
    }
})

watch(error, (error) => {
    if(error) {
        ElMessage.error("Mallihuonekortin poisto epäonnistui")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_alert, {
      type: "info",
      title: "Huom! Mallihuonekortit ovat hyvinvointialue kohtaisia."
    }),
    (_unref(loading))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_el_divider, { "border-style": "none" }),
          _createVNode(ComLoading)
        ], 64))
      : (_openBlock(), _createBlock(_component_el_table, {
          key: 1,
          data: _unref(mallihuonekortit)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "tilanimike.nimi",
              label: "Tilanimike"
            }),
            _createVNode(_component_el_table_column, {
              prop: "toiminta",
              label: "Tilanimikkeen täsmennys"
            }),
            _createVNode(_component_el_table_column, null, {
              default: _withCtx((scope) => [
                _createVNode(ComDeleteButton, {
                  onClick: ($event: any) => (deleteClick(scope.row.id))
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]))
  ], 64))
}
}

})