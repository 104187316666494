<template>
    <comCard :header="true">
        <template #header>Hyväksy käyttöehdot</template>
        <div>
            Tiedoksesi, että kirjautumalla järjestelmään hyväksyt sen, että henkilötietosi tallentuvat SSTY:n (Suomen
            Sairaalatekniikan yhdistys) Vahti -vaatimustenhallintajärjestelmään. Rekisterin tietoja käytetään ainoastaan
            tässä järjestelmässä. Tietojasi ei käytetä tai myydä kaupalliseen tarkoitukseen. Tallennamme ainoastaa
            nimesi ja sähköpostiosoitteesi. Tarkemmat tiedot löytyvät rekisteriselosteesta.
            <br />
            <br />
            <a target="_blank" href="https://storage.googleapis.com/vahti_images/Rekisteriseloste.pdf">
                Rekisteriseloste
            </a>
            <br />
            <br />
            - Sinut kirjataan ulos jos et hyväksy käyttöehtoja.
            <br />
            - Mikäli, et halua tietojasi tallentuvan järjestelmään, ota yhteys hankkeesi vastuuhenkilöön.
            <el-divider />
            <div>
                <el-checkbox v-model="hyvaksy">Hyväksyn ehdot</el-checkbox>
            </div>
            <el-space>
                <ComSuccessButton @click="hyvaksyClick">Hyväksyn</ComSuccessButton>
                <ComCancelButton @click="hylkaaClick">En hyväksy</ComCancelButton>
            </el-space>
        </div>
    </comCard>
</template>

<script setup lang="ts">
import { useAuth0 } from "@/auth/auth0-plugin"
import { ElMessage } from "element-plus"
import { ref, watch } from "vue"
import ComCancelButton from "../common/buttons/ComCancelButton.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComCard from "../common/ComCard.vue"
import { useConsestGDPR } from "@/methods/kayttajaComposables"

const { consestGDPR, error, response } = useConsestGDPR()
const auth0 = useAuth0()
const hyvaksy = ref(false)
const logoutUrl = process.env.VUE_APP_AUTH0_LOGOUT_URL

const hyvaksyClick = async () => {
    if (!hyvaksy.value) {
        ElMessage.warning("Sinun tulee hyväksyä ehdot jatkaaksesi!")
    } else {
        consestGDPR()
    }
}

const hylkaaClick = () => {
    if (!auth0) {
        return
    }

    auth0.logout({
        logoutParams: {
            returnTo: logoutUrl,
        },
    })
}

watch(response, () => {
    if (response.value) {
        window.location.reload()
        ElMessage.success("Käyttöehdot hyväksytty!")
    }
})
watch(error, () => {
    if (error.value) {
        ElMessage.error("Käyttöehtojen hyväksyminen epäonnistui!")
    }
})

</script>
