<template>
    <el-tooltip 
        :content="content" 
        :show-after="500" 
        :teleported="false" 
        :placement="placement"
        :fallback-placements="['left','right','bottom']">
        <slot></slot>
    </el-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        content: {
            required: true,
            type: String,
        },
        placement: {
            required: false,
            type: String,
            default: "top",
        },
    },
})
</script>
