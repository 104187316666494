<template>
    <el-form>
        <el-form-item label="Pääkäyttäjät">
            <el-space>
                <ComSelect v-model="selected">
                    <el-option v-for="(paakayttaja, index) in paakayttajat" :key="index" :value="paakayttaja.id" :label="paakayttaja.kayttaja.etunimi + ' ' + paakayttaja.kayttaja.sukunimi"/>
                </ComSelect>
                <ComDeleteButton @click="deletePaakayttajaClick"/>
            </el-space>
        </el-form-item>
    </el-form>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import ComSelect from "@/components/common/ComSelect.vue"
import ComDeleteButton from "@/components/common/buttons/ComDeleteButton.vue"
import { ElMessage, ElMessageBox } from "element-plus"
import { useOikeudetComponent } from "./oikeudet"

const { paakayttajat, deletePaakayttajaResponse, deletePaakayttaja } = useOikeudetComponent()
const selected = ref<number>()

const deletePaakayttajaClick = () => {
    if (selected.value) {
        ElMessageBox.confirm("Haluatko varmasti poistaa käyttäjältä pääkäyttäjäoikeudet?", "", {
            type: "warning",
        }).then(() => {
            deletePaakayttaja(selected.value!)
        }).catch(() => {})
    } else {
        ElMessage.warning("Valitse poistettava pääkäyttäjä!")
    }
}

watch(deletePaakayttajaResponse, (response) => {
    if (response) {
        ElMessage.success("Pääkäyttäjäoikeudet poistettu!")

        selected.value = undefined
    }
})
</script>
