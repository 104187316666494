<template>
    <el-container>
        <el-aside v-if="isAuthenticated" class="sidebar">
            <com-side-menu />
        </el-aside>
        <el-container>
            <el-header v-if="isAuthenticated">
                <com-header />
            </el-header>
            <el-main class="main">
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue"
import ComHeader from "./components/layout/ComHeader.vue"
import ComSideMenu from "./components/layout/SideMenu/ComSideMenu.vue"
import { useAuth0 } from "@/auth/auth0-plugin"

const auth0 = useAuth0()

onMounted(async () => {
    if (!auth0) {
        return
    }
    await auth0.createClient()
    await auth0.handleCallback()
    await auth0.checkToken()
})

const isAuthenticated = computed((): boolean | null => {
    if (!auth0) {
        return null
    }

    return auth0.isAuthenticated.value
})
</script>

<style scoped>
.main {
    margin-top: 40px;
}
</style>
