import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { KommenttiRequest } from "@/api/models/kommentti-request"
import { ref, watch } from "vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComNotifyKommentti from "./ComNotifyKommentti.vue"
import { useAddKommentti } from "@/methods/kommenttiComposables"
import { useKommentitComponent } from "./kommentit"
import { ElMessage } from "element-plus"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComAddKommentti',
  setup(__props) {

const { addKommentti, error, response } = useAddKommentti()
const { tyyppi, viite } = useKommentitComponent()
const kommentti = ref<KommenttiRequest>({} as KommenttiRequest)

const addCommentClick = () => {
    if(tyyppi.value && viite.value) {
        kommentti.value.tyyppi = { id: tyyppi.value }
        kommentti.value.viite = { id: viite.value }

        if(!kommentti.value.huomio) {
            kommentti.value.huomio = []
        }

        addKommentti(kommentti.value)
    }
}

const valitutKayttajatChange = (val: KayttajaResponse[] | undefined) => {
    if (val) {
        kommentti.value.huomio = val.map((v) => {
            return { id: v.id }
        })
    }
}

watch(error, (val) => {
    if(val) {
        ElMessage.error("Kommentin lisääminen epäonnistui")
    }
})
watch(response, (val) => {
    if(val) {
        ElMessage.success("Kommentti lisätty")

        kommentti.value.kommentti = ""
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "100px" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Kommentti" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: kommentti.value.kommentti,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((kommentti.value.kommentti) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_space, {
        direction: "vertical",
        fill: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_alert, {
            type: "info",
            "show-icon": "",
            closable: false
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Alla olevasta kentästä voit lähettää valituille käyttäjille sähköpostiviestin kommentista. Hae ja valitse haluamasi käyttäjät sähköpostin perusteella. ")
            ])),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Käyttäjät" }, {
            default: _withCtx(() => [
              _createVNode(ComNotifyKommentti, { onValitutKayttajat: valitutKayttajatChange })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(ComSuccessButton, { onClick: addCommentClick }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Kommentoi")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})