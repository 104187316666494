<template>
    <ComLoading v-if="!liitteet" />
    <template v-else>
        <el-table :data="liitteet" style="padding-bottom: 40px">
            <el-table-column label="Nimi">
                <template #default="scope">
                    <el-space>
                        <span>
                            <el-icon><Document /></el-icon>
                        </span>
                        <span>{{ scope.row.nimi ? scope.row.nimi : scope.row.tiedosto }}</span>
                    </el-space>
                </template>
            </el-table-column>
            <el-table-column label="Seloste" prop="seloste" />
            <el-table-column width="85px">
                <template #default="scope">
                    <el-space style="float: right">
                        <ComDownloadButton @click="downloadLiiteClick(scope.row.id)" />
                        <ComDeleteButton v-if="kirjoitus" @click="deleteLiiteClick(scope.row)" />
                    </el-space>
                </template>
            </el-table-column>
        </el-table>
        <ComLiiteAdd v-if="kirjoitus" />
    </template>
</template>

<script setup lang="ts">
import { LiiteResponse } from "@/api/models/liite-response"
import { Document } from "@element-plus/icons-vue"
import { ElMessageBox } from "element-plus"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComDownloadButton from "../common/buttons/ComDownloadButton.vue"
import ComLoading from "../common/ComLoading.vue"
import ComLiiteAdd from "./ComLiiteAdd.vue"
import { useLiiteComponent } from "./liite"
import { useDeleteLiite, useDownloadLiite } from "@/methods/liiteComposables"
import { huonekorttiPage } from "@/views/huonekortti"

const { liitteet } = useLiiteComponent()
const { deleteLiite } = useDeleteLiite()
const { kirjoitus } = huonekorttiPage()

const deleteLiiteClick = async (liite: LiiteResponse) => {
    ElMessageBox.confirm("Haluatko varmasti poistaa liitteen " + liite.tiedosto).then(async () => {
        await deleteLiite(liite.id)
    })
}
const downloadLiiteClick = async (id : number) => {
    const response = await useDownloadLiite(id)

    if(response) {
        window.open(response.url, "_blank")
    }
}

</script>
