import { useRoute } from "vue-router"
import { computed, ref, watch } from "vue"
import { useHanke } from "@/methods/hankeComposables"
import { useOikeudet } from "@/methods/kayttajaOikeusComposables"
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { useTilanimikeet } from "@/methods/tilanimikeComposables"

interface state {
    id?: number
    vastuuyksikot?: number[]
    tab?: number
    tilanumero?: number
    writableVastuuyksikot?: VastuuyksikkoResponse[]
    paakayttaja?: boolean
}

const state = ref<state[]>([])

export function hankePage() {
    const route = useRoute()
    const id = computed(() => Number(route.params.hankeId))
    if (!id.value) {
        throw new Error("Hanke id:tä ei ole määritetty.")
    }
    const { hanke, hankeTilanumero, loading } = useHanke(id)
    const selectedVastuuyksikkoIds = computed({
        get: () => state.value?.find((h) => h.id === id.value)?.vastuuyksikot ?? [],
        set: (val) => {
            const currentState = getCurrentState()

            if (!currentState) {
                addState({ vastuuyksikot: val })
            } else {
                currentState.vastuuyksikot = val
            }
        },
    })
    const selectedTab = computed({
        get: () => state.value.find((h) => h.id === id.value)?.tab ?? 0,
        set: (val) => {
            const currentState = getCurrentState()

            if (!currentState) {
                addState({ tab: val })
            } else {
                currentState.tab = val
            }
        },
    })
    const { writableVastuuyksikot: hankeWritableVastuuyksikot, paakayttajaHankkeet, superUser} = useOikeudet()
    const { tilanimikkeet } = useTilanimikeet()
    const hankeVastuuyksikot = computed(() => hanke.value?.vastuuyksikot)
    const selectedVastuuyksikot = computed(() => hankeVastuuyksikot.value?.filter((v) => selectedVastuuyksikkoIds.value.includes(v.id)))
    const tilanumero = ref(0)
    const paakayttaja = computed(() => superUser.value || paakayttajaHankkeet.value?.some((h) => h === id.value))
    const writableVastuuyksikot = computed(() => hanke.value?.vastuuyksikot?.filter(v => superUser.value || hankeWritableVastuuyksikot.value.some(h => h === v.id)) ?? [])

    const getCurrentState = () => state.value.find((h) => h.id === id.value)
    const addState = (val: state) => {
        state.value.push({
            id: id.value,
            vastuuyksikot: val.vastuuyksikot ?? [],
            tab: val.tab ?? 0,
            tilanumero: val.tilanumero ?? 0,
            paakayttaja: val.paakayttaja ?? false,
            writableVastuuyksikot: val.writableVastuuyksikot ?? [],
        })
    }
    const selectAllVastuuyksikot = () =>
        (selectedVastuuyksikkoIds.value = hankeVastuuyksikot.value?.map((v) => v.id) ?? [])
    const increaseTilanumero = () => {
        const currentState = getCurrentState()

        if (currentState?.tilanumero) {
            currentState.tilanumero++
        }
    }
    const resetTilanumero = () => {
        const currentState = getCurrentState()

        if (currentState?.tilanumero) {
            currentState.tilanumero = hankeTilanumero.value
        }
    }

    watch(hanke, () => {
        if (hanke.value) {
            const currentState = getCurrentState() 
            
            if (!currentState) {
                addState({
                    id: id.value,
                    paakayttaja: paakayttaja.value,
                    tilanumero: hankeTilanumero.value,
                })
            }
        }
    }, { immediate: true })

    watch(state, (s) =>  {
        tilanumero.value = s.find((h) => h.id === id.value)?.tilanumero ?? 0
    }, { deep: true, immediate: true })

    return {
        id,
        selectedVastuuyksikkoIds,
        selectedVastuuyksikot,
        selectedTab,
        hanke,
        tilanumero,
        hankeVastuuyksikot,
        tilanimikkeet,
        paakayttaja,
        writableVastuuyksikot,
        loading,
        selectAllVastuuyksikot,
        increaseTilanumero,
        resetTilanumero,
    }
}
