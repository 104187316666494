<template>
    <el-tag
        class="oikeus-tag"
        @click="changeOikeus(oikeus, oikeudet.Luku)"
        size="small"
        :type="oikeus && oikeus.luku ? 'success' : 'danger'"
        style="margin-right: 3px">
        L
    </el-tag>
    <el-tag
        class="oikeus-tag"
        @click="changeOikeus(oikeus, oikeudet.Kirjoitus)"
        size="small"
        :type="oikeus && oikeus.kirjoitus ? 'success' : 'danger'"
        style="margin-right: 3px">
        K
    </el-tag>
    <el-tag
        class="oikeus-tag"
        @click="changeOikeus(oikeus, oikeudet.Raportointi)"
        size="small"
        :type="oikeus && oikeus.raportointi ? 'success' : 'danger'">
        R
    </el-tag>
</template>

<script setup lang="ts">
import { OikeusRequest } from "@/api/models/oikeus-request"
import { OikeusResponse } from "@/api/models/oikeus-response"
import Oikeudet from "@/types/OikeudetEnum"
import { PropType } from "vue"


const emit = defineEmits(["updateOikeus", "addOikeus", "deleteOikeus"])
const props = defineProps({
    oikeus: {
        type: Object as PropType<OikeusResponse | undefined>,
    },
    kayttaja: {
        type: Number,
        required: true,
    },
    vastuuyksikko: {
        type: Number,
        required: true,
    },

})
const oikeudet = Oikeudet
const changeOikeus = (oikeus: OikeusResponse | undefined, type: Oikeudet) => {
    if (!oikeus) {
        const request: OikeusRequest = {
            kayttaja: { id: props.kayttaja },
            vastuuyksikko: { id: props.vastuuyksikko },
            luku: true,
            kirjoitus: type == Oikeudet.Kirjoitus ? true : false,
            raportointi: type == Oikeudet.Raportointi || Oikeudet.Kirjoitus ? true : false,
        }

        emit("addOikeus", request)
    } else {
        const oikeusUpdated = { ...oikeus }

        if (type == Oikeudet.Luku && oikeus.luku) {
            emit("deleteOikeus", oikeus.id)

            return
        }
        else if (type == Oikeudet.Kirjoitus) {
            oikeusUpdated.kirjoitus = !oikeus.kirjoitus

            if (!oikeus.kirjoitus) {
                oikeusUpdated.raportointi = true
            }
        }
        else if (type == Oikeudet.Raportointi) {
            oikeusUpdated.raportointi = !oikeus.raportointi
        }

        emit("updateOikeus", oikeus.id, oikeusUpdated)
    }
}
</script>
