<template>
    <ComEmpty v-if="!huonekortit" />
    <ComLoading v-else-if="loading" />
    <div v-else>
        <el-row>
            <el-col>
                <label class="el-form-item__label">Raportoitava aikaväli</label>
                <ComDateTimePicker v-model="range" />
            </el-col>
        </el-row>
        <el-divider />
        <ComHuonekorttiTableWithFilterAndPagination :status="status" :selection="true" />
    </div>
</template>

<script setup lang="ts">
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import ComDateTimePicker from "@/components/common/ComDateTimePicker.vue"
import { ref, watch } from "vue"
import { huonekorttiListing } from "../huonekortit/huonekortit"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiTableWithFilterAndPagination from "../huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"

const emit = defineEmits(["range"])
const { huonekortit, loading, status } = huonekorttiListing()
const range = ref<Date[]>()

watch(range, () => {
    emit("range", range.value)
})

status.value = [HuonekorttiStatusResponse.Hyvaksytty]

</script>
