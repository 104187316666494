<template>
    <div>
        <ComCard :header="true">
            <template #header>
                <div class="card-header">
                    <span>Hankkeet</span>
                    <el-switch
                        style="float: right"
                        v-model="arkistoidut"
                        active-text="Arkistoidut"
                        inactive-text="Aktiiviset" />
                </div>
            </template>
            <ComLoading v-if="!hankkeet" />
            <el-table :data="hankkeet" v-else>
                <el-table-column type="expand">
                    <template #default="props">
                        <div style="margin-left: 20px">
                            <ComYllapitoHankeTableVastuuyksikot :vastuuyksikot="props.row.vastuuyksikot" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Hanke" prop="nimi" />
                <el-table-column label="Hyvinvointialue" prop="sairaala.nimi" />
                <el-table-column>
                    <template #default="props">
                        <el-button type="primary" size="small" @click="handleEdit(props.row)">Muokkaa</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-divider border-style="none" />
            <ComSuccessButton @click="showAdd = true">Lisää hanke</ComSuccessButton>
        </ComCard>
        <el-drawer
            v-model="show"
            @close=";(showEdit = false), (showAdd = false)"
            destroy-on-close
            size="60%"
            title="Hanke"
        >
            <ComYllapitoHankeEdit v-if="showEdit" :show="showEdit" :hanke="selectedHanke" />
            <ComYllapitoHankeLisaa v-if="showAdd" />
        </el-drawer>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import ComLoading from "../common/ComLoading.vue"
import ComYllapitoHankeTableVastuuyksikot from "./ComYllapitoHankeTableVastuuyksikot.vue"
import ComCard from "../common/ComCard.vue"
import { HankeResponse } from "@/api/models/hanke-response"
import ComYllapitoHankeEdit from "./ComYllapitoHankeEdit.vue"
import { useYllapitoComponent } from "./yllapito"
import ComYllapitoHankeLisaa from "./ComYllapitoHankeLisaa.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import { useHankkeet } from "@/methods/hankeComposables"

const { hankkeet, arkistoidut } = useHankkeet()
const { selectedHanke, setSelectedHanke } = useYllapitoComponent()
const showEdit = ref(false)
const showAdd = ref(false)
const show = computed(() => showEdit.value || showAdd.value)

const handleEdit = (row: HankeResponse) => {
    setSelectedHanke(row.id)
    showEdit.value = true
}
</script>
