import { HankeRequest } from "@/api/models/hanke-request"
import { HankeResponse } from "@/api/models/hanke-response"
import { computed, ref, watch, Ref } from "vue"
import http from "@/http-common"
import { AxiosResponseHeaders } from "axios"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"

const arkistoidut = ref(false)
const url = "hankkeet"

export function useHankkeet(_arkistoidut?: Ref<boolean>) {
    const arkistoidutIsLoaded = computed(() => _arkistoidut?.value || arkistoidut.value)
    const queryKey = computed(() => [url, { arkistoidut: arkistoidutIsLoaded.value }])

    const fetcher = async (): Promise<HankeResponse[]> => {
        return (await http.get(url, { params: { arkistoidut: arkistoidutIsLoaded.value } })).data
    }

    const {
        data: hankkeet,
        error,
        isLoading: loading,
        refetch: mutate,
    } = useQuery({ queryKey: queryKey, queryFn: () => fetcher() })

    return {
        hankkeet,
        error,
        loading,
        arkistoidut,
        mutate,
    }
}

export function useHanke(id: Ref<number | undefined>) {
    const hankeTilanumero = ref<number>()
    const hanke = ref<HankeResponse>()
    const key = computed(() => [url, id.value])

    const fetcher = async (): Promise<{ data: HankeResponse; headers: AxiosResponseHeaders }> => {
        const response = await http.get(url + "/" + id.value)

        return {
            data: response.data,
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const {
        data,
        error,
        isLoading: loading,
        refetch: mutate,
    } = useQuery({ 
        queryKey: key, 
        queryFn: () => fetcher(),
        enabled: () => id.value !== undefined
    })

    watch(data, () => {
        if (data) {
            hanke.value = data.value?.data
            hankeTilanumero.value = data.value?.headers["hanke-tilanumero"]
                ? parseInt(data.value?.headers["hanke-tilanumero"])
                : 0
        }
    })

    return {
        hanke,
        error,
        loading,
        hankeTilanumero,
        mutate,
    }
}

export function useHankeDataByVastuuyksikko(id: Ref<number | undefined>) {
    const hankeTilanumero = ref<undefined | number>()
    const hanke = ref<undefined | HankeResponse>()
    const key = computed(() => ['hankkeet/vastuuyksikkoid', id.value] )

    const fetcher = async (): Promise<{ data: HankeResponse; headers: AxiosResponseHeaders }> => {
        const response = await http.get('hankkeet/vastuuyksikkoid/' + id.value)

        return {
            data: response.data,
            headers: response.headers as AxiosResponseHeaders,
        }
    }

    const { 
        data, 
        error, 
        isLoading: loading,
        refetch: mutate,
    } = useQuery({
        queryKey: key, 
        queryFn: () => fetcher(), 
        enabled: () => id.value !== undefined
    })

    watch(data, () => {
        if (data) {
            hanke.value = data.value?.data
            hankeTilanumero.value = data.value?.headers["hanke-tilanumero"]
                ? parseInt(data.value?.headers["hanke-tilanumero"])
                : 0
        }
    })

    return {
        hanke,
        error,
        loading,
        hankeTilanumero,
        mutate,
    }
}

export function useAddHanke() {
    const queryClient = useQueryClient()
    const { data: response, error, isPending: loading, mutate } = useMutation({
        mutationFn: (request: HankeRequest) => http.post("/hankkeet", request),
        onSuccess: () => queryClient.invalidateQueries({queryKey: [url]}),
    })

    const addHanke = (request: HankeRequest) => {
        mutate(request)
    }

    return {
        response,
        loading,
        error,
        addHanke,
    }
}

export function useUpdateHanke() {
    interface request {
        id: number
        request: HankeRequest
    }

    const queryClient = useQueryClient()
    const { data: response, error, isPending: loading, mutate } = useMutation({
        mutationFn: (request: request) => http.put(`/hankkeet/${request.id}`, request.request),
        onSuccess: () => queryClient.invalidateQueries({queryKey: [url]}),
    })

    const updateHanke = (id: number, request: HankeRequest) => {
        mutate({id: id, request: request})
    }

    return {
        response,
        loading,
        error,
        updateHanke,
    }
}
