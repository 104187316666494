import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { HuonekorttiNimikeHistoriaResponse } from "@/api/models/huonekortti-nimike-historia-response"
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"
import HistoriaNimike from "@/types/HistoriaNimike"
import { diffAndSanitize } from "@/utils/diff"
import { useQuery } from "@tanstack/vue-query"
import { Ref, computed } from "vue"
import http from "@/http-common"
import { HuonekorttiAndNimikeHistoriaResponse } from "@/api/models/huonekortti-and-nimike-historia-response"

export function useHuonekorttiHistoria(id: Ref<number | undefined>) {
    const key = computed(() => [`huonekortit/${id.value}/historia`])
    
    const fetcher = async () : Promise<HuonekorttiAndNimikeHistoriaResponse> => {
        return (await http.get(`/huonekortit/${id.value}/historia`)).data
    }   

    const { 
        data: historia,
        isLoading: loading,
        error,
     } = useQuery({
        queryKey: key,
        queryFn: () => fetcher(),
        enabled: () => id.value !== undefined,
    })

    return {
        historia,
        loading,
        error,
    }   
}

export function useHuonekorttiHistoriaMethods() {
    const tyyppiIsLisays = (item: HuonekorttiNimikeHistoriaResponse | HistoriaNimike) => {
        if ("tyyppi" in item && item.tyyppi == HuonekorttiHistoriaTyyppi.Lisays) {
            return true
        }
        return false
    }
    const tyyppiIsPoisto = (item: HuonekorttiNimikeHistoriaResponse | HistoriaNimike) => {
        if ("tyyppi" in item && item.tyyppi == HuonekorttiHistoriaTyyppi.Poisto) {
            return true
        }
        return false
    }
    const tyyppiColor = (type: HuonekorttiHistoriaTyyppi | undefined) => {
        if (type == HuonekorttiHistoriaTyyppi.Lisays) {
            return "success"
        }
        if (type == HuonekorttiHistoriaTyyppi.Poisto) {
            return "danger"
        } else {
            return "primary"
        }
    }
    const diffHistoriaNimike = (
        historia: HuonekorttiNimikeHistoriaResponse,
        nimike?: HuonekorttiNimikeResponse | HuonekorttiNimikeHistoriaResponse
    ): HistoriaNimike => {
        const hs = {
            id: historia.id,
            maara: historia.maara?.toString(),
            tasmennys: historia.tasmennys,
            ominaisuudet: historia.ominaisuudet,
            huonekorttiNimikeId: historia.huonekorttiNimikeId,
            tyyppi: historia.tyyppi,
            nimike: historia.nimike,
            aika: historia.aika,
            kayttaja: historia.kayttaja,
        } as HistoriaNimike

        if (historia?.tyyppi == HuonekorttiHistoriaTyyppi.Muutos && nimike) {
            hs.maara = diffAndSanitize(historia.maara?.toString() ?? "", nimike.maara?.toString() ?? "")
            hs.tasmennys = diffAndSanitize(historia.tasmennys ?? "", nimike.tasmennys ?? "")

            const ominaisuudet = [] as OminaisuusResponse[]

            nimike.ominaisuudet?.forEach((o) => {
                const ominaisuus = { ...o }

                if (!historia.ominaisuudet?.some((ho) => ho.id == o.id)) {
                    ominaisuus.nimi = diffAndSanitize("", o.nimi ?? "")
                }

                ominaisuudet.push(ominaisuus)
            })

            historia.ominaisuudet?.forEach((o) => {
                const ominaisuus = { ...o }

                if (!ominaisuudet.some((no) => no.id == o.id)) {
                    ominaisuus.nimi = diffAndSanitize(o.nimi ?? "", "")

                    ominaisuudet.push(ominaisuus)
                }
            })

            hs.ominaisuudet = ominaisuudet
        }

        return hs
    }
    const diffAllHistoriaNimike = (
        nimikeHistoria: HuonekorttiNimikeHistoriaResponse[],
        id: number,
        nimike?: HuonekorttiNimikeResponse
    ): HistoriaNimike[] => {
        const nh = nimikeHistoria.filter((n) => n.huonekorttiNimikeId == id)
        const historiaNimikkeet: HistoriaNimike[] = []

        nh.forEach((n) => {
            if (tyyppiIsLisays(n) || tyyppiIsPoisto(n)) {
                historiaNimikkeet.push(diffHistoriaNimike(n))
            } else {
                const i = nh.findIndex((i) => i.id == n.id)

                if (i != -1 && nh[i - 1]) {
                    historiaNimikkeet.push(diffHistoriaNimike(n, nh[i - 1]))
                } else {
                    historiaNimikkeet.push(diffHistoriaNimike(n, nimike))
                }
            }
        })

        return historiaNimikkeet
    }

    return {
        tyyppiIsLisays,
        tyyppiIsPoisto,
        tyyppiColor,
        diffHistoriaNimike,
        diffAllHistoriaNimike,
    }
}
