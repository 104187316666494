<template>
    <template v-if="vastuuyksikko">
        <el-row>
            <el-col :span="10">
                <el-form-item v-if="edit">
                    <el-input size="small" v-model="vastuuyksikko.nimi" />
                </el-form-item>
                <span v-else>{{ vastuuyksikko.nimi }}</span>
            </el-col>
            <el-col :span="12" :offset="2">
                <el-form-item>
                    <el-space>
                        <ComDeleteButton v-if="!edit" @click="deleteVaastuuyksikkoClick(vastuuyksikko.id)">Poista</ComDeleteButton>
                        <ComEditButton v-if="!edit" @click="edit = true">Muokkaa</ComEditButton>
                        <ComCancelButton v-if="edit" @click="edit = false">Peruuta</ComCancelButton>
                        <ComSuccessButton v-if="edit" @click="updateVastuuyksikkoClick">Tallenna</ComSuccessButton>
                    </el-space>
                </el-form-item>
            </el-col>
        </el-row>
    </template>
</template>

<script setup lang="ts">
import { VastuuyksikkoResponse } from "@/api/models/vastuuyksikko-response"
import { PropType, ref } from "vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComSuccessButton from "../common/buttons/ComSuccessButton.vue"
import ComEditButton from "../common/buttons/ComEditButton.vue"
import ComCancelButton from "../common/buttons/ComCancelButton.vue"
import { useDeleteVastuuyksikko, useUpdateVastuuyksikko } from "@/methods/vastuuyksikkoComposables"
import { ElMessageBox } from "element-plus"

const vastuuyksikko = defineModel({type: Object as PropType<VastuuyksikkoResponse>, required: true})
const { updateVastuuyksikko, error } = useUpdateVastuuyksikko()
const { deleteVastuuyksikko } = useDeleteVastuuyksikko()
const edit = ref(false)

const updateVastuuyksikkoClick = async () => {
    updateVastuuyksikko(vastuuyksikko.value.id, vastuuyksikko.value)
}

const deleteVaastuuyksikkoClick = async (vastuuyksikkoId: number) => {
    const confirm = await ElMessageBox.confirm("Haluatko varmasti poistaa vastuuyksikön?", "Vahvista poisto", {
        confirmButtonText: "Kyllä",
        cancelButtonText: "Peruuta",
        type: "warning",
    })

    if (confirm) {
        deleteVastuuyksikko(vastuuyksikkoId)
    }
}


</script>
