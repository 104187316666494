import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue"
import ComAttatchmenButton from "../common/buttons/ComAttatchmentButton.vue"
import ComLiiteList from "./ComLiiteList.vue"
import kommenttiTyyppi from "@/types/Kommentti";
import { useLiiteComponent } from "./liite";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLiitteet',
  props: {
    viite: {},
    tyyppi: {}
  },
  setup(__props: any) {

const props = __props
const show = ref(false)
const { viite: _viite, tyyppi: _tyyppi } = useLiiteComponent()

const showLiitteet = () => {
    show.value = true
    _viite.value = props.viite
    _tyyppi.value = props.tyyppi
}


return (_ctx: any,_cache: any) => {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ComAttatchmenButton, { onClick: showLiitteet }),
    (show.value)
      ? (_openBlock(), _createBlock(_component_el_drawer, {
          key: 0,
          modelValue: show.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((show).value = $event)),
          "append-to-body": "",
          "destroy-on-close": "",
          title: "Liitteet",
          size: "50%"
        }, {
          default: _withCtx(() => [
            _createVNode(ComLiiteList)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})