import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"
import { ElMessage } from "element-plus"
import { computed, ref, watch } from "vue"
import { useMallihuonekortit } from "../../methods/mallihuonekortitComposables"
import ComSelect from "../common/ComSelect.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComMallihuonekortitSelect',
  props: {
    sairaala: {},
    tilanimike: {}
  },
  emits: ["mallihuonekorttiSelected"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit
    const sairaala = computed(() => props.sairaala)
    const tilanimike = computed(() => props.tilanimike)
    const { mallihuonekortit, error } = useMallihuonekortit(sairaala, tilanimike)
    const mallihuonekorttiSelected = ref<MallihuonekorttiResponse | undefined>()
    const mallihuonekorttiEnable = ref(false)

watch(() => props.tilanimike, () => {
    mallihuonekorttiEnable.value = false
    mallihuonekorttiSelected.value = undefined
})

watch(mallihuonekorttiSelected, () => {
    emits("mallihuonekorttiSelected", mallihuonekorttiSelected.value)
})

watch(mallihuonekorttiEnable, () => {
    if (!mallihuonekorttiEnable.value) {
        mallihuonekorttiSelected.value = undefined
    }
})

watch(error, () => {
    if (error) {
        ElMessage.error("Mallihuonekorttien lataus ei onnistunut!")
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_unref(mallihuonekortit) && _unref(mallihuonekortit).length > 0)
    ? (_openBlock(), _createBlock(_component_el_space, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: mallihuonekorttiEnable.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((mallihuonekorttiEnable).value = $event)),
            "active-text": "Käytä mallihuonekorttia"
          }, null, 8, ["modelValue"]),
          (mallihuonekorttiEnable.value)
            ? (_openBlock(), _createBlock(ComSelect, {
                key: 0,
                modelValue: mallihuonekorttiSelected.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((mallihuonekorttiSelected).value = $event)),
                "value-key": "id",
                size: "small"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mallihuonekortit), (mallihuonekortti, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: mallihuonekortti.toiminta,
                      value: mallihuonekortti
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})