<template>
    <template v-if="mallihuonekortit && mallihuonekortit.length > 0">
        <el-space>
            <el-switch v-model="mallihuonekorttiEnable" active-text="Käytä mallihuonekorttia" />
            <ComSelect v-if="mallihuonekorttiEnable" v-model="mallihuonekorttiSelected" value-key="id" size="small">
                <el-option
                    v-for="(mallihuonekortti, index) in mallihuonekortit"
                    :key="index"
                    :label="mallihuonekortti.toiminta"
                    :value="mallihuonekortti" />
            </ComSelect>
        </el-space>
    </template>
</template>

<script setup lang="ts">
import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"
import { ElMessage } from "element-plus"
import { computed, ref, watch } from "vue"
import { useMallihuonekortit } from "../../methods/mallihuonekortitComposables"
import ComSelect from "../common/ComSelect.vue";

const props = defineProps<{
    sairaala: number,
    tilanimike: number,
}>()
const emits = defineEmits(["mallihuonekorttiSelected"])
    const sairaala = computed(() => props.sairaala)
    const tilanimike = computed(() => props.tilanimike)
    const { mallihuonekortit, error } = useMallihuonekortit(sairaala, tilanimike)
    const mallihuonekorttiSelected = ref<MallihuonekorttiResponse | undefined>()
    const mallihuonekorttiEnable = ref(false)

watch(() => props.tilanimike, () => {
    mallihuonekorttiEnable.value = false
    mallihuonekorttiSelected.value = undefined
})

watch(mallihuonekorttiSelected, () => {
    emits("mallihuonekorttiSelected", mallihuonekorttiSelected.value)
})

watch(mallihuonekorttiEnable, () => {
    if (!mallihuonekorttiEnable.value) {
        mallihuonekorttiSelected.value = undefined
    }
})

watch(error, () => {
    if (error) {
        ElMessage.error("Mallihuonekorttien lataus ei onnistunut!")
    }
})
</script>
