import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { ref, watch } from "vue"
import ComSelect from "@/components/common/ComSelect.vue"
import ComDeleteButton from "@/components/common/buttons/ComDeleteButton.vue"
import { ElMessage, ElMessageBox } from "element-plus"
import { useOikeudetComponent } from "./oikeudet"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComPaakayttajat',
  setup(__props) {

const { paakayttajat, deletePaakayttajaResponse, deletePaakayttaja } = useOikeudetComponent()
const selected = ref<number>()

const deletePaakayttajaClick = () => {
    if (selected.value) {
        ElMessageBox.confirm("Haluatko varmasti poistaa käyttäjältä pääkäyttäjäoikeudet?", "", {
            type: "warning",
        }).then(() => {
            deletePaakayttaja(selected.value!)
        }).catch(() => {})
    } else {
        ElMessage.warning("Valitse poistettava pääkäyttäjä!")
    }
}

watch(deletePaakayttajaResponse, (response) => {
    if (response) {
        ElMessage.success("Pääkäyttäjäoikeudet poistettu!")

        selected.value = undefined
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Pääkäyttäjät" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_space, null, {
            default: _withCtx(() => [
              _createVNode(ComSelect, {
                modelValue: selected.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected).value = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paakayttajat), (paakayttaja, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      value: paakayttaja.id,
                      label: paakayttaja.kayttaja.etunimi + ' ' + paakayttaja.kayttaja.sukunimi
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(ComDeleteButton, { onClick: deletePaakayttajaClick })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})