import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onMouseover"]
const _hoisted_2 = { class: "cascader-valinta-nimi" }
const _hoisted_3 = { class: "cascader-valinta-napit" }

import { OikeusRequest } from "@/api/models/oikeus-request"
import { CascaderProps, ElMessage, ElMessageBox } from "element-plus"
import { ref, watch } from "vue"
import ComPaakayttajat from "./ComPaakayttajat.vue"
import ComSuccessButton from "../../common/buttons/ComSuccessButton.vue"
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { OikeusProperties } from "@/api/models/oikeus-properties"
import ComOikeusButtons from "./ComOikeusButtons.vue"
import ComEditButton from "@/components/common/buttons/ComEditButton.vue"
import { hankePage } from "@/views/hanke"
import { useOikeudetComponent } from "./oikeudet"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLisaaOikeus',
  setup(__props) {

const { id: hankeId, selectedVastuuyksikot } = hankePage()
const { paakayttajat, filteredKayttajat, addOikeudetResponse, addPaakaytajaResonse, addOikeudet, addPaakayttajat, editKayttaja } = useOikeudetComponent()
const valittuKayttaja = ref<KayttajaResponse>()
const selected = ref<
    {
        0: number
    }[]
>([])
const oikeudet = ref<OikeusProperties>({
    luku: true,
    kirjoitus: false,
    raportointi: false,
})
const props = {
    label: "etunimi",
    value: "id",
    multiple: true,
} as CascaderProps

const oikeusClick = (oikeus: OikeusProperties) => {
    oikeudet.value = oikeus
}

const addOikeudetClick = () => {
    if (selectedVastuuyksikot.value?.length == 0) {
        ElMessage.warning("Valitse vähintään yksi vastuuyksikkö!")
    } else {
        if (selected.value.length == 0) {
            ElMessage.warning("Valitse vähintään yksi käyttäjä!")
        } else {
            const requests: OikeusRequest[] = []
            selectedVastuuyksikot.value?.forEach((v) => {
                selected.value.forEach((k) => {
                    const request: OikeusRequest = {
                        luku: true,
                        kirjoitus: oikeudet.value.kirjoitus,
                        raportointi: oikeudet.value.raportointi,
                        kayttaja: {
                            id: k[0],
                        },
                        vastuuyksikko: {
                            id: v.id,
                        },
                    }
                    requests.push(request)
                })
            })
            addOikeudet(requests)
        }
    }
}

const addPaakayttajaClick = () => {
    if (selected.value.length == 0) {
        ElMessage.warning("Valitse vähintään yksi käyttäjä!")
    } else {
        ElMessageBox.confirm("Haluatko varmasti lisätä valitut käyttäjät hankkeen pääkäyttäjiksi?", "", {
            type: "warning",
        }).then(() => {
            const kayttajaIds = selected.value.map((s) => s[0])

            if (paakayttajat.value?.some((p) => kayttajaIds.includes(p.kayttaja.id))) {
                ElMessage.error("Käyttäjällä on jo pääkäyttäjäoikeudet!")
            } else {
                addPaakayttajat(hankeId.value, kayttajaIds)
            }
        })
    }
}

watch(addOikeudetResponse, (response) => {
    if (response) {
        ElMessage.success("Oikeudet lisätty!")
        selected.value = []
    }
})

watch(addPaakaytajaResonse, (response) => {
    if (response) {
        ElMessage.success("Pääkäyttäjäoikeudet lisätty!")
        selected.value = []
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 6 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_cascader_panel, {
            options: _unref(filteredKayttajat),
            props: props,
            modelValue: selected.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selected).value = $event)),
            class: "kayttaja-oikeudet-cascader"
          }, {
            default: _withCtx(({ data }) => [
              _createElementVNode("div", {
                onMouseover: ($event: any) => (valittuKayttaja.value = data),
                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (valittuKayttaja.value = undefined)),
                class: "cascader-valinta"
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(data.etunimi) + " " + _toDisplayString(data.sukunimi), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(ComEditButton, {
                    onClick: ($event: any) => (_unref(editKayttaja)(data))
                  }, null, 8, ["onClick"])
                ])
              ], 40, _hoisted_1)
            ]),
            _: 1
          }, 8, ["options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, {
        span: 8,
        offset: 1
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("label", null, "Nimi", -1)),
                  _createTextVNode(" " + _toDisplayString(valittuKayttaja.value ? valittuKayttaja.value.etunimi + " " + valittuKayttaja.value.sukunimi : ""), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("label", null, "Email", -1)),
                  _createTextVNode(" " + _toDisplayString(valittuKayttaja.value ? valittuKayttaja.value.email : ""), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("label", null, "Kirjautunut viimeksi", -1)),
                  _createTextVNode(" " + _toDisplayString(valittuKayttaja.value ? valittuKayttaja.value.kirjautunutViimeksi : ""), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "row-margin" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _createVNode(ComOikeusButtons, {
                    oikeudet: oikeudet.value,
                    onOikeusChange: oikeusClick
                  }, null, 8, ["oikeudet"]),
                  _createVNode(ComSuccessButton, { onClick: addOikeudetClick }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Lisää oikeudet")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { style: {"position":"absolute","bottom":"0"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _createVNode(ComSuccessButton, { onClick: addPaakayttajaClick }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Lisää pääkäyttäjäoikeudet")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 9 }, {
        default: _withCtx(() => [
          _createVNode(ComPaakayttajat)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})