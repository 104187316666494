<template>
    <el-button type="success" size="small" circle>
        <ComTooltip content="Tallenna">
            <el-icon>
                <Check></Check>
            </el-icon>
        </ComTooltip>
    </el-button>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { Check } from "@element-plus/icons-vue"
import ComTooltip from "../ComTooltip.vue"

export default defineComponent({
    components: {
        Check,
        ComTooltip,
    },
})
</script>
