import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComPlusButton = _resolveComponent("ComPlusButton")!
  const _component_ComMinusButton = _resolveComponent("ComMinusButton")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ComStatusTag = _resolveComponent("ComStatusTag")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.huonekortit,
    ref: "multipleTableRef"
  }, {
    default: _withCtx(() => [
      (_ctx.selection)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            width: "100px"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_el_button_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ComPlusButton, { onClick: _ctx.selectAll }, null, 8, ["onClick"]),
                  _createVNode(_component_ComMinusButton, { onClick: _ctx.unselectAll }, null, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            default: _withCtx((scope) => [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: scope.row.id,
                value: scope.row.id,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.huonekorttiIdsSelected) = $event))
              }, null, 8, _hoisted_1), [
                [_vModelCheckbox, _ctx.huonekorttiIdsSelected]
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, {
        label: "Tilanimike",
        prop: "tilanimike.nimi"
      }),
      _createVNode(_component_el_table_column, {
        label: "Täsmennys",
        prop: "toiminta",
        "show-overflow-tooltip": ""
      }),
      _createVNode(_component_el_table_column, {
        label: "Status",
        prop: "status"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_ComStatusTag, {
            status: scope.row.status
          }, null, 8, ["status"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Vastuuyksikkö",
        prop: "vastuuyksikko.nimi"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tilat",
        "show-overflow-tooltip": ""
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_ctx.formatTilat(scope.row.tilat)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}