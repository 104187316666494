import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_ComLoginButton = _resolveComponent("ComLoginButton")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 8,
          offset: 8,
          style: {"text-align":"center","margin-bottom":"40px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_image, {
              src: require('@/assets/vahtilogo.png')
            }, null, 8, ["src"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_col, {
      span: 8,
      offset: 8
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_card, null, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Vahti Vaatimustenhallintajärjestelmä", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Tämä on Vahti- järjestelmän kirjautumissivu.", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, " Vahti on järjestelmä, jota käytetään terveydenhuollon rakennushankkeissa. Vahti ohjaa ja opastaa käyttäjää kirjaamaan toiminnan tiloille aiheuttamat tarpeet eli vaatimukset. ", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, " Vahdissa tilanimikkeistö on yhtenäistetty sairaanhoitopiirien käytössä olevien tilanimikkeiden pohjalta ja suunnittelualakohtainen sisältö noudattaa vakiintunutta nimikkeistöä. ", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " Tiedot tallennetaan tietokantaan, mikä mahdollistaa monipuoliset hakutoiminnot sekä raportoinnin ja vertailun. ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", null, " Vahti-järjestelmän omistaa Suomen sairaalatekniikan yhdistys ry (SSTY ry) Järjestelmän toteutuksesta ja ylläpidosta vastaa Datasund Oy yhteyshenkilönä toimii Jani Saine, Datasund Oy ", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, "jani.saine[ät]tietosund.fi", -1)),
            _createElementVNode("p", null, [
              _createVNode(_component_ComLoginButton)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}