<template>
    <ComLoading v-if="!hankeVaatimuskategoriat"></ComLoading>
    <template v-else-if="hanke">
        <h2>Vaatimukset</h2>
        <el-collapse v-model="vastuuyksikkoActive">
            <el-collapse-item name="hanke" title="">
                <template #title>
                    <el-icon class="collapse-icon-right" v-if="!vastuuyksikkoActive.includes('hanke')">
                        <Folder />
                    </el-icon>
                    <el-icon class="collapse-icon-right" v-else><FolderOpened /></el-icon>
                    <span class="label">Koko hanketta koskevat vaatimukset</span>
                </template>
                <ComHanketiedotVaatimuksetField :vaatimus="hanke.vaatimukset" :type="VaatimusType.Hanke"/>
            </el-collapse-item>
            <template v-if="vastuuyksikot">
                <el-collapse-item
                    :name="vastuuyksikko.id"
                    v-for="(vastuuyksikko, index) in vastuuyksikot"
                    :key="index">
                    <template #title>
                        <el-icon class="collapse-icon-right" v-if="!vastuuyksikkoActive.includes(vastuuyksikko.id)">
                            <Folder />
                        </el-icon>
                        <el-icon class="collapse-icon-right" v-else><FolderOpened /></el-icon>
                        <span class="label">{{ 'Vastuuyksikköä ' + vastuuyksikko.nimi + ' koskevat vaatimukset'}}</span>
                    </template>
                    <ComHanketiedotVaatimuksetField :vaatimus="vastuuyksikko.vaatimukset ?? ''" :vastuuyksikko="vastuuyksikko" :type="VaatimusType.Vastuuyksikko"/>
                </el-collapse-item>
            </template>
        </el-collapse>
        <h2>Kategorisoidut vaatimukset</h2>
        <el-tabs>
            <el-tab-pane v-for="(kategoria, index) in hankeVaatimuskategoriat" :key="index" :label="kategoria.nimi">
                <el-collapse v-model="kategoriaActive">
                    <template v-if="kategoria.children">
                        <el-collapse-item v-for="(child, index) in kategoria.children" :name="child.id" :key="index" :title="child.nimi">
                            <template #title>
                                <el-icon class="collapse-icon-right" v-if="!kategoriaActive.includes(child.id)">
                                    <Folder />
                                </el-icon>
                                <el-icon class="collapse-icon-right" v-else><FolderOpened /></el-icon>
                                <span class="label">{{ child.nimi }}</span>
                            </template>
                            <ComHanketiedotVaatimuksetField :vaatimus="getHankeVaatimus(child.id)" :type="VaatimusType.Kategorisoidut" :kategoriaId="child.id"/>
                        </el-collapse-item>
                    </template>
                </el-collapse>
            </el-tab-pane>
        </el-tabs>
    </template>
</template>

<script setup lang="ts">
import ComLoading from "@/components/common/ComLoading.vue"
import { useHanketiedotComponent } from "./hanketiedot"
import { Folder, FolderOpened } from "@element-plus/icons-vue"
import { useHankeVaatimusKategoriat } from "@/methods/hankeVaatimusComposables";
import { ref } from "vue";
import ComHanketiedotVaatimuksetField from "./ComHanketiedotVaatimuksetField.vue";
import VaatimusType from "@/types/VaatimusEnum";

const { hankeVaatimuskategoriat } = useHankeVaatimusKategoriat()
const { hanke, vastuuyksikot } = useHanketiedotComponent()
const vastuuyksikkoActive = ref<(string | number)[]>([])
const kategoriaActive = ref<number[]>([])

const getHankeVaatimus = (kategoria: number) => {
    return hanke?.value?.kategorisoidutVaatimukset?.find((k) => k.kategoria?.id == kategoria)?.vaatimus
}

</script>

<style scoped>
:deep(.el-collapse-item__header) {
    font-weight: bold;
}

.collapse-icon-right {
    padding-right: 10px;
    font-size: 20px;
    font-weight: 800;
}
</style>
