import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadFilled = _resolveComponent("UploadFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    "auto-upload": false,
    "file-list": _ctx.file,
    "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
    limit: 1
  }, {
    tip: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "el-upload__tip" }, "Tiedoston maksimi koko 5 Mt.", -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
        default: _withCtx(() => [
          _createVNode(_component_UploadFilled)
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "el-upload__text" }, [
        _createTextVNode(" Vedä tiedosto tähän tai "),
        _createElementVNode("em", null, "klikkaa valitaksesi tiedosto.")
      ], -1))
    ]),
    _: 1
  }, 8, ["file-list"]))
}