<template>
    <ComSelect
        v-model="valitutKayttajat"
        multiple
        filterable
        remote
        reserve-keyword
        value-key="email"
        placeholder="Kirjoita ja valitse"
        :loading="loading"
        :remote-method="loadKayttajat"
        style="width: 100%">
        <el-option v-for="kayttaja in kayttajat" :key="kayttaja" :label="kayttaja.email" :value="kayttaja" />
    </ComSelect>
</template>

<script setup lang="ts">
import { KayttajaResponse } from "@/api/models/kayttaja-response"
import { ref, watch } from "vue"
import ComSelect from "../common/ComSelect.vue"
import { useKayttajat } from "@/methods/paakayttajaComposables";

const emit = defineEmits(["valitutKayttajat"])
const searchStr = ref("")
const { kayttajat, loading } = useKayttajat(searchStr)
const valitutKayttajat = ref<KayttajaResponse[]>()

const loadKayttajat = async (val: string) => {
    if (val && val.length > 2) {
        searchStr.value = val
    }
}

watch(valitutKayttajat, () => {
    emit("valitutKayttajat", valitutKayttajat.value)
})

</script>
