import Paging from "@/types/Paging"
import { hankePage } from "@/views/hanke"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { watch, watchEffect } from "vue"
import { useHuonekorttiUndelete } from "@/methods/huonekorttiComposablses"
import { ElMessage, ElMessageBox } from "element-plus"

export function useRoskakori() {
    const { selectedVastuuyksikkoIds } = hankePage()
    const { huonekortit, total, paramsObject, pageSize, currentPage } = useHuonekortit()
    const { undeleteHuonekortti, error, response } = useHuonekorttiUndelete()

    const palautaButtonClicked = async (id: number) => {
        ElMessageBox.confirm('Haluatko varmasti palauttaa huonekortin?', 'Palauta huonekortti', {
            confirmButtonText: 'Kyllä',
            cancelButtonText: 'Peruuta',
            type: 'warning'
        }).then(() => {
            undeleteHuonekortti(id)
        }).catch(() => {})
    }

    const pageChangeClicked = (val: Paging) => {
        currentPage.value = val.currentPage
        pageSize.value = val.pageSize
    }

    watchEffect(() => {
        paramsObject.value = {
            ...paramsObject.value,
            poistettu: true,
            vastuuyksikot: selectedVastuuyksikkoIds.value,
            paging: {
                currentPage: currentPage.value,
                pageSize: pageSize.value
            }
        }
    })

    watch(response, (val) => {
        if(val) {
            ElMessage.success("Huonekortti palautettu onnistuneesti!")
        }
    })
    watch(error, (val) => {
        if(val) {
            ElMessage.error("Huonekortin palauttaminen epäonnistui!")
        }
    })

    return {
        huonekortit,
        total,
        pageSize,
        currentPage,
        pageChangeClicked,
        palautaButtonClicked
    }
}