<template>
    <div class="card-margin-bottom">
        <el-form inline label-position="top" :rules="rules" ref="addHuonekorttiRef" :model="huonekortti">
            <el-col :lg="12" :sm="24">
                <el-row>
                    <el-col :lg="8" :sm="24">
                        <el-form-item label="Tilanimike" prop="tilanimike">
                            <el-select size="small" v-model="huonekortti.tilanimike.id" style="width: 100%">
                                <el-option
                                    v-for="tilanimike in tilanimikkeet"
                                    :key="tilanimike.id"
                                    :label="tilanimike.nimi"
                                    :value="tilanimike.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="8" :sm="24">
                        <el-form-item label="Täsmennys" prop="toiminta">
                            <el-input
                                size="small"
                                v-model="huonekortti.toiminta"
                                :maxlength="100"
                                :disabled="mallihuonekortti != undefined" />
                        </el-form-item>
                    </el-col>
                    <el-col :lg="8" :sm="24">
                        <el-form-item label="Vastuuyksikkö" prop="vastuuyksikko">
                            <el-select
                                size="small"
                                v-model="huonekortti.vastuuyksikko.id"
                                v-if="writableVastuuyksikot"
                                style="width: 100%">
                                <el-option
                                    v-for="vastuuyksikko in writableVastuuyksikot"
                                    :key="vastuuyksikko.id"
                                    :value="vastuuyksikko.id"
                                    :label="vastuuyksikko.nimi" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <template v-if="hanke?.sairaala?.id && huonekortti.tilanimike.id">
                        <ComMallihuonekortitSelect
                            :tilanimike="huonekortti.tilanimike.id"
                            :sairaala="hanke.sairaala.id"
                            @mallihuonekorttiSelected="mallihuonekorttiSelected" />
                    </template>
                </el-row>
            </el-col>
            <el-col :lg="10" :sm="24">
                <el-form-item prop="tilat">
                    <template v-for="(tila, index) in huonekortti.tilat" :key="index">
                        <el-col :span="3" style="text-align: center">
                            <el-form-item :label="index == 0 ? 'Tilat' : ''">
                                <el-text>{{ index + 1 }}</el-text>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="index == 0 ? 'Tilatunnus' : ''">
                                <el-input v-model="tila.tilatunnus" size="small" placeholder="Tilatunnus" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-form-item :label="index == 0 ? 'Pinta-ala m2' : ''">
                                <el-input
                                    v-model="tila.pintaAla"
                                    size="small"
                                    placeholder="Pinta-ala m2"
                                    :precision="2"
                                    :step="0.1"
                                    type="number" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4" :offset="1">
                            <el-form-item :label="index == 0 ? '&nbsp;' : ''">
                                <ComPlusButton
                                    v-if="index + 1 === huonekortti.tilat?.length"
                                    @click="addTila(huonekortti)" />
                                <ComMinusButton
                                    v-if="index + 1 !== huonekortti.tilat?.length"
                                    @click="removeTila(huonekortti, index)" />
                            </el-form-item>
                        </el-col>
                    </template>
                </el-form-item>
            </el-col>
            <el-col :lg="2" :sm="24">
                <el-form-item label="&nbsp;">
                    <el-button type="success" size="small" @click="validateAndAddHuonekortti()">Lisää</el-button>
                </el-form-item>
            </el-col>
        </el-form>
    </div>
</template>

<script setup lang="ts">
import { HuonekorttiRequest } from "@/api/models/huonekortti-request"
import { TilaResponse } from "@/api/models/tila-response"
import { ref, reactive, watchEffect, watch } from "vue"
import ComPlusButton from "../../common/buttons/ComPlusButton.vue"
import ComMinusButton from "../../common/buttons/ComMinusButton.vue"
import { ElMessage, FormValidateCallback, type ElForm } from "element-plus"
import ComMallihuonekortitSelect from "@/components/mallihuonekortit/ComMallihuonekortitSelect.vue"
import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"
import { hankePage } from "@/views/hanke"
import { huonekorttiListing } from "./huonekortit"
import { useTilanimikeet } from "@/methods/tilanimikeComposables"
import { useAddHuonekortti, useAddHuonekorttiFromMallihuonekortti } from "@/methods/huonekorttiComposablses"

const { writableVastuuyksikot, hanke } = hankePage()
const { addTila, removeTila } = huonekorttiListing()
const {
    addHuonekortti,
    error: addHuonekorttiError,
    loading: addHuonekorttiLoading,
    response: addHuonekorttiResponse,
} = useAddHuonekortti()
const {
    addHuonekorttiFromMallihuonekortti,
    error: addHuonekorttiFromMallihuonekorttiError,
    loading: addHuonekorttiFromMallihuonekorttiLoading,
    response: addHuonekorttiFromMallihuonekorttiResponse,
} = useAddHuonekorttiFromMallihuonekortti()
const { tilanimikkeet } = useTilanimikeet()
const addHuonekorttiRef = ref<typeof ElForm>()
const mallihuonekortti = ref<MallihuonekorttiResponse | undefined>()
const huonekortti = reactive({
    toiminta: "",
    vastuuyksikko: {
        id: undefined,
    },
    tilanimike: {
        id: undefined,
    },
    tilat: [] as TilaResponse[],
}) as HuonekorttiRequest
const rules = ref({
    tilanimike: [
        {
            required: true,
            trigger: "change",
            type: "object",
            fields: {
                id: {
                    type: "number",
                    required: true,
                    message: "Valitse tilanimike!",
                },
            },
        },
    ],
    toiminta: [
        {
            required: false,
            max: 100,
        },
    ],
    vastuuyksikko: [
        {
            required: true,
            trigger: "change",
            type: "object",
            fields: {
                id: {
                    type: "number",
                    required: true,
                    message: "Valitse vastuuyksikkö!",
                },
            },
        },
    ],
})

const validateAndAddHuonekortti = async () => {
    addHuonekorttiRef.value?.validate(async (valid: FormValidateCallback) => {
        if (!valid) {
            return false
        } else {
            huonekortti.tilat?.forEach((t) => (t.vastuuyksikko = { id: huonekortti.vastuuyksikko.id }))

            if (!mallihuonekortti.value) {
                addHuonekortti(huonekortti)
            } else {
                addHuonekorttiFromMallihuonekortti(
                    huonekortti.vastuuyksikko.id,
                    mallihuonekortti.value.id,
                    huonekortti.tilat
                )
            }
        }
    })
}
const resetFields = () => {
    if (addHuonekorttiRef.value) {
        addHuonekorttiRef.value.resetFields()

        huonekortti.toiminta = ""
        huonekortti.vastuuyksikko = { id: undefined }
        huonekortti.tilanimike = { id: undefined }
        mallihuonekortti.value = undefined

        huonekortti.tilat = []

        addTila(huonekortti)
    }
}

const mallihuonekorttiSelected = (val: MallihuonekorttiResponse | undefined) => {
    mallihuonekortti.value = val
    huonekortti.toiminta = val ? val.toiminta : ""
}

watchEffect(() => {
    if (hanke.value && huonekortti.tilat?.length === 0) {
        addTila(huonekortti)
    }
})
watch(addHuonekorttiLoading, (val) => {
    if (!val && addHuonekorttiResponse.value) {
        ElMessage.success("Huonekortti lisätty")

        resetFields()
    }
})
watch(addHuonekorttiError, (val) => {
    if (val) {
        ElMessage.error("Huonekortin lisäys epäonnistui")
    }
})
watch(addHuonekorttiFromMallihuonekorttiLoading, (val) => {
    if (!val && addHuonekorttiFromMallihuonekorttiResponse.value) {
        ElMessage.success("Huonekortti lisätty")

        resetFields()
    }
})
watch(addHuonekorttiFromMallihuonekorttiError, (val) => {
    if (val) {
        ElMessage.error("Huonekortin lisäys epäonnistui")
    }
})
</script>
