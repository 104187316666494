<template>
    <ComImportButton @click="visible = true"></ComImportButton>
    <template v-if="visible">
        <el-dialog :title="header[active]" v-model="visible" destroy-on-close append-to-body>
            <ComHuonekorttiValinta v-show="active === 0" class="card-margin-bottom"></ComHuonekorttiValinta>
            <ComVastuuyksikkoValinta v-show="active === 1" class="card-margin-bottom"></ComVastuuyksikkoValinta>
            <ComVarmistus v-show="active === 2" class="card-margin-bottom"></ComVarmistus>
            <el-row class="card-margin-bottom">
                <el-col :span="5">
                    <el-button-group>
                        <el-button size="small" @click="active--" v-if="active > 0">
                            <el-icon><ArrowLeft /></el-icon>
                            Edellinen
                        </el-button>
                        <el-button size="small" @click="seuraava" v-if="active < 2">
                            Seuraava
                            <el-icon class="el-icon--right"><ArrowRight /></el-icon>
                        </el-button>
                    </el-button-group>
                </el-col>
            </el-row>
            <el-steps :active="active">
                <el-step title="Vaihe 1" description="Valitse kopioitavat huonekortit"></el-step>
                <el-step title="Vaihe 2" description="Valitse kopioinnin kohde"></el-step>
                <el-step title="Vaihe 3" description="Varmistus"></el-step>
            </el-steps>
        </el-dialog>
    </template>
</template>

<script setup lang="ts">
import ComHuonekorttiValinta from "./ComHuonekorttiValinta.vue"
import ComVastuuyksikkoValinta from "./ComVastuuyksikkoValinta.vue"
import ComVarmistus from "./ComVarmistus.vue"
import { ElMessage } from "element-plus"
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue"
import ComImportButton from "@/components/common/buttons/ComImportButton.vue"
import { useComTuonti } from "./tuonti"

const header = [
    "Valitse tuotavat huonekortit",
    "Valitse vastuuyksikkö mihin huonekortit tuodaan",
    "Hyväksy tuonti",
]
const { huonekortit, active, visible, vastuuyksikko } = useComTuonti()

const seuraava = () => {
    if (active.value === 0) {
        if (huonekortit.value.length === 0) {
            ElMessage.error("Valitse ainakin yksi huonekortti.")

            return
        }
    }
    if (active.value === 1) {
        if (!vastuuyksikko.value) {
            ElMessage.error("Valitse vastuuyksikkö.")

            return
        }
    }

    active.value++
}

</script>
