import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"margin-right":"5px"} }
const _hoisted_2 = { style: {"float":"right"} }

import ComCard from "../common/ComCard.vue"
import ComDeleteKommentti from "./ComDeleteKommentti.vue"
import ComLoading from "../common/ComLoading.vue"
import ComEmpty from "../common/ComEmpty.vue"
import ComAddKommentti from "./ComAddKommentti.vue"
import { useKommentitComponent } from "./kommentit"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComKommentit',
  setup(__props) {

const { kommentit, tyyppi, viite } = useKommentitComponent()


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(kommentit))
      ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
      : (_unref(kommentit).length == 0)
        ? (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_unref(kommentit), (kommentti, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "card-margin-bottom"
            }, [
              _createVNode(ComCard, { header: "" }, {
                header: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(kommentti.aika), 1),
                  _createElementVNode("label", null, _toDisplayString(kommentti.kayttaja?.etunimi + " " + kommentti.kayttaja?.sukunimi), 1),
                  _createElementVNode("span", _hoisted_2, [
                    _createVNode(ComDeleteKommentti, { kommentti: kommentti }, null, 8, ["kommentti"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(kommentti.kommentti), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
    (_unref(kommentit))
      ? (_openBlock(), _createBlock(ComAddKommentti, {
          key: 3,
          tyyppi: _unref(tyyppi),
          viite: _unref(viite)
        }, null, 8, ["tyyppi", "viite"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})