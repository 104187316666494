import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { TilaHistoriaResponse } from "@/api/models/tila-historia-response"
import { computed } from "vue"
import ComTilaHistoriaItem from "./ComTilaHistoriaItem.vue"
import { huonekorttiPage } from "@/views/huonekortti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComTilaHistoria',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const { huonekortti } = huonekorttiPage()
const tilatCurrent = computed(() => huonekortti?.value?.tilat)
const historia = computed(() => {
    const temp = props.data

    return temp.sort((a, b) => a.id - b.id).reverse()
})

const getCurrentData = (val: TilaHistoriaResponse) => {
    if (val.tyyppi != HuonekorttiHistoriaTyyppi.Muutos) {
        return undefined
    }

    const i = historia.value.findIndex((h) => h.id == val.id)
    const next = historia.value[i - 1]

    if (next?.tila?.id != val.tila?.id) {
        return tilatCurrent.value?.find((t) => t.id == val.tila?.id)
    } else {
        return next
    }
}

return (_ctx: any,_cache: any) => {
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createBlock(_component_el_timeline, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(historia.value, (muutos) => {
        return (_openBlock(), _createBlock(ComTilaHistoriaItem, {
          key: muutos.id,
          previousData: muutos,
          data: getCurrentData(muutos)
        }, null, 8, ["previousData", "data"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})