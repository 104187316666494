import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { HuonekorttiNimikeHistoriaResponse } from "@/api/models/huonekortti-nimike-historia-response"
import ComEmpty from "@/components/common/ComEmpty.vue"
import ComFilterInput from "@/components/common/ComFilterInput.vue"
import { computed, ref } from "vue"
import ComHuonekorttiNimikeHistoriaItem from "./ComHuonekorttiNimikeHistoriaItem.vue"
import { huonekorttiPage } from "@/views/huonekortti"
import { useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiNimikeHistoria',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props
const filter = ref("")
const { huonekortti } = huonekorttiPage()
const { diffHistoriaNimike, tyyppiColor } = useHuonekorttiHistoriaMethods()
const historia = computed(() => {
    return props.data
})
const filteredData = computed(() => {
    if (!filter.value) {
        return historia.value
    }
    if (historia.value) {
        return historia.value.filter((n) => {
            return (
                n.nimike!.nimi!.toLowerCase().indexOf(filter.value.toLowerCase()) > -1 ||
                n.tasmennys!.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            )
        })
    } else {
        return []
    }
})

const currentData = (muutos: HuonekorttiNimikeHistoriaResponse) => {
    if (muutos.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        const temp = historia.value.filter((n) => {
            return n.huonekorttiNimikeId == muutos.huonekorttiNimikeId
        })

        const i = temp.findIndex((h) => h.id == muutos.id)

        if (temp[i - 1]) {
            return diffHistoriaNimike(muutos, temp[i - 1])
        } else {
            const huonekorttiNimike = huonekortti?.value?.nimikkeet?.find(
                (n) => n.id == muutos.huonekorttiNimikeId
            )

            if (huonekorttiNimike) {
                return diffHistoriaNimike(muutos, huonekorttiNimike)
            }
        }
    } else {
        return diffHistoriaNimike(muutos)
    }
}

const type = (type: HuonekorttiHistoriaTyyppi | undefined) => {
    return tyyppiColor(type)
}

return (_ctx: any,_cache: any) => {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComFilterInput, {
      modelValue: filter.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event))
    }, null, 8, ["modelValue"]),
    (_ctx.data)
      ? (_openBlock(), _createBlock(_component_el_timeline, { key: 0 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredData.value, (muutos) => {
              return (_openBlock(), _createBlock(_component_el_timeline_item, {
                key: muutos.id,
                timestamp: muutos.aika,
                placement: "top",
                type: type(muutos.tyyppi),
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createVNode(ComHuonekorttiNimikeHistoriaItem, {
                    data: currentData(muutos)
                  }, null, 8, ["data"])
                ]),
                _: 2
              }, 1032, ["timestamp", "type"]))
            }), 128))
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(ComEmpty, { key: 1 }))
  ], 64))
}
}

})