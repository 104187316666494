<template>
    <ComCommentButton @click="showDrawerClick"></ComCommentButton>
    <template v-if="showDrawer">
        <el-drawer v-model="showDrawer" append-to-body title="Kommentit" size="30%" destroy-on-close>
            <ComKommentit/>
        </el-drawer>
    </template>
</template>

<script setup lang="ts">
import kommenttiTyyppi from "@/types/Kommentti"
import { PropType, ref, watchEffect } from "vue"
import { useKommentitComponent } from "./kommentit"
import ComCommentButton from "../common/buttons/ComCommentButton.vue";
import ComKommentit from "./ComKommentit.vue";

const props = defineProps({
    viite: {
        type: Number,
        required: true
    },
    tyyppi: {
        type: Number as PropType<kommenttiTyyppi>,
        required: true
    },
    paakayttaja: {
        type: Boolean,
        required: true
    }
})

const showDrawer = ref(false)
const { tyyppi: tyyppiState, viite: viiteState, isPaakayttaja } = useKommentitComponent()

const showDrawerClick = () => {
    showDrawer.value = true
    tyyppiState.value = props.tyyppi
    viiteState.value = props.viite
}

watchEffect(() => {
    isPaakayttaja.value = props.paakayttaja
})
</script>
