import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { LiiteResponse } from "@/api/models/liite-response"
import { Document } from "@element-plus/icons-vue"
import { ElMessageBox } from "element-plus"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComDownloadButton from "../common/buttons/ComDownloadButton.vue"
import ComLoading from "../common/ComLoading.vue"
import ComLiiteAdd from "./ComLiiteAdd.vue"
import { useLiiteComponent } from "./liite"
import { useDeleteLiite, useDownloadLiite } from "@/methods/liiteComposables"
import { huonekorttiPage } from "@/views/huonekortti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLiiteList',
  setup(__props) {

const { liitteet } = useLiiteComponent()
const { deleteLiite } = useDeleteLiite()
const { kirjoitus } = huonekorttiPage()

const deleteLiiteClick = async (liite: LiiteResponse) => {
    ElMessageBox.confirm("Haluatko varmasti poistaa liitteen " + liite.tiedosto).then(async () => {
        await deleteLiite(liite.id)
    })
}
const downloadLiiteClick = async (id : number) => {
    const response = await useDownloadLiite(id)

    if(response) {
        window.open(response.url, "_blank")
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (!_unref(liitteet))
    ? (_openBlock(), _createBlock(ComLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_el_table, {
          data: _unref(liitteet),
          style: {"padding-bottom":"40px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { label: "Nimi" }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_space, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Document))
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("span", null, _toDisplayString(scope.row.nimi ? scope.row.nimi : scope.row.tiedosto), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "Seloste",
              prop: "seloste"
            }),
            _createVNode(_component_el_table_column, { width: "85px" }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_space, { style: {"float":"right"} }, {
                  default: _withCtx(() => [
                    _createVNode(ComDownloadButton, {
                      onClick: ($event: any) => (downloadLiiteClick(scope.row.id))
                    }, null, 8, ["onClick"]),
                    (_unref(kirjoitus))
                      ? (_openBlock(), _createBlock(ComDeleteButton, {
                          key: 0,
                          onClick: ($event: any) => (deleteLiiteClick(scope.row))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        (_unref(kirjoitus))
          ? (_openBlock(), _createBlock(ComLiiteAdd, { key: 0 }))
          : _createCommentVNode("", true)
      ], 64))
}
}

})