import { computed, ref, watch, watchEffect } from "vue"
import Order from "@/types/OrderEnum"
import HuonekorttiSort from "@/types/HuonekorttiSort"
import HuonekorttiSortEnum from "@/types/HuonekorttiSortEnum"
import { useHuonekortit } from "@/methods/huonekortitComposables"
import { hankePage } from "@/views/hanke"
import { ElMessage } from "element-plus"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { useLocalStorage } from "@vueuse/core"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { HuonekorttiRequest } from "@/api/models/huonekortti-request"
import { TilaResponse } from "@/api/models/tila-response"
import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { useHuonekorttiDelete } from "@/methods/huonekorttiComposablses"

interface perHanke {
    id: number
    filter: string
    page: number
}

interface state {
    perHanke: perHanke[],
    sort: HuonekorttiSort
}

const state = ref<state>({
    perHanke: [],
    sort: { order: Order.asc, prop: HuonekorttiSortEnum.id },
})

export function huonekorttiListing() {
    const {
        id,
        selectedVastuuyksikot,
        selectedVastuuyksikkoIds,
        hankeVastuuyksikot,
        tilanimikkeet,
        writableVastuuyksikot,
        tilanumero,
        paakayttaja,
        increaseTilanumero,
    } = hankePage()
    const { huonekortit, total, paramsObject, loading: huonekorttiLoading, mutate: updateHuonekortit, setAllFilters } = useHuonekortit()
    const { deleteHuonekortti, response: deleteResponse, loading: deleteLoading, error: deleteError } = useHuonekorttiDelete()
    const edit = ref<number | undefined>()
    const pageSize = useLocalStorage("huonekorttiPageSize", 30)
    const status = ref<HuonekorttiStatusResponse[]>()
    const filter = computed({
        get: () => state.value?.perHanke.find((h) => h.id === id.value)?.filter ?? "",
        set: (val) => {
            const index = state.value?.perHanke.findIndex((h) => h.id === id.value) ?? -1

            if (index === -1) {
                addState(val)
            } else {
                state.value.perHanke[index].filter = val
            }
        },
    })
    const currentPage = computed({
        get: () => state.value?.perHanke.find((h) => h.id === id.value)?.page ?? 1,
        set: (val) => {
            const index = state.value?.perHanke.findIndex((h) => h.id === id.value) ?? -1

            if (index === -1) {
                addState(undefined, val)
            } else {
                state.value.perHanke[index].page = val
            }
        },
    })
    const sort = computed(
        () => state.value?.sort ?? { order: Order.asc, prop: HuonekorttiSortEnum.id }
    )
    const vastuuyksikkoSelected = computed(() => selectedVastuuyksikkoIds.value?.length != 0)
    const loading = computed(() => vastuuyksikkoSelected.value && (huonekorttiLoading.value))

    const sortChanged = (val: HuonekorttiSort) => {
        paramsObject.value.sort = val.prop
        paramsObject.value.order = val.order

        if(!val.order) {
            state.value.sort = { order: Order.asc, prop: HuonekorttiSortEnum.id }
        }
        else {
            state.value.sort = val
        }
    }
    const addState = (filter?: string, page?: number) => {
        state.value.perHanke.push({
            id: id.value,
            filter: filter ?? "",
            page: page ?? 1,
        })
    }
    const addTila = (item: HuonekorttiResponse | HuonekorttiRequest | HuonekorttiRequestPut) => {
        increaseTilanumero()

        const tila = {
            tilatunnus: tilanumero.value.toString(),
            pintaAla: 0,
            vastuuyksikko: { id: item.vastuuyksikko.id },
        } as TilaResponse

        if ("id" in item) {
            tila.huonekortti = { id: item.id }
        }

        item.tilat?.push(tila)
    }
    const removeTila = (item: HuonekorttiResponse | HuonekorttiRequest | HuonekorttiRequestPut, index: number) => {
        if (item.tilat?.length !== 1) {
            item.tilat?.splice(index, 1)
        } else {
            ElMessage({ message: "Huonekortilla pitää olla vähintään yksi tila!", type: "warning" })
        }
    }

    watchEffect(() => {
        paramsObject.value.paging = {
            currentPage: currentPage.value,
            pageSize: pageSize.value,
        }
        paramsObject.value.vastuuyksikot = selectedVastuuyksikkoIds.value
        paramsObject.value.status = status.value
        paramsObject.value.sort = sort.value.prop
        paramsObject.value.order = sort.value.order
        paramsObject.value.poistettu = false

        setAllFilters(filter.value)
    })

    watch(filter, () => {
        currentPage.value = 1
    })

    watchEffect(() => {
        if(deleteError.value) {
            ElMessage.error("Huonekortin poistaminen epäonnistui!")
        }
    })

    watchEffect(() => {
        if(!deleteLoading.value && deleteResponse.value) {
            ElMessage.success("Huonekortti poistettu!")
        }
    })

    return {
        huonekortit,
        edit,
        tilanimikkeet,
        hankeVastuuyksikot,
        total,
        filter,
        loading,
        currentPage,
        pageSize,
        sort,
        selectedVastuuyksikot,
        vastuuyksikkoSelected,
        writableVastuuyksikot,
        status,
        hankeId: id,
        paakayttaja,
        updateHuonekortit,
        sortChanged,
        addTila,
        removeTila,
        deleteHuonekortti
    }
}
