<template>
    <ComOhjePohja :title="'Sähköluokat'">
        <div>
            <b>G2</b>
            <p>
                Lääkintätila, jossa lääkintälaitetta käytetään sydämenläheisiin toimintoihin ja/tai jossa
                lääkintälaitteen rikkoontuminen saattaa aiheuttaa potilaalle välittömän hengenvaaran (leikkaussalit,
                tehohoito jne.); Tilat suojataan IT-sähkönjakelujärjestelmällä, sekä niihin liittyvällä
                hälytysjärjestelmällä (EV-MEV = eristys valvontalaitteisto)
            </p>
            <b>G1</b>
            <p>
                Lääkintätila, jossa käytetään sähkökäyttöisiä lääkintälaitteita (vastaanottohuoneet, potilashuoneet,
                potilasvessat) Tilat suojataan vikavirtasuojakytkimillä ja lisäpotentiaalintasauksella.
            </p>
            <b>G0</b>
            <p>
                Tila, jossa ei käytetä sähkökäyttöisiä lääkintälaitteita (toimistot, varastot, yleiset WC:t jne.) Tilat
                suojataan vikavirtasuojakytkimillä
            </p>
        </div>
    </ComOhjePohja>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import ComOhjePohja from "./ComOhjePohja.vue"

export default defineComponent({
    components: {
        ComOhjePohja,
    },
    setup() {
        return {}
    },
})
</script>
