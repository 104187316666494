import { HuonekorttiId } from "@/api/models/huonekortti-id"
import { computed, Ref } from "vue"
import { MallihuonekorttiRequestPut } from "../api/models/mallihuonekortti-request-put"
import http from "@/http-common"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { MallihuonekorttiResponse } from "@/api/models/mallihuonekortti-response"

const url = (id : number | undefined) =>  `/sairaalat/${id}/mallihuonekortit`

export function useMallihuonekortit(sairaala: Ref<number | undefined>, tilanimike?: Ref<number | undefined>) {
    const key = computed(() => ["mallihuonekortit", {sairaala: sairaala.value, tilanimike: tilanimike?.value}])
    
    const fetcher = async () : Promise<MallihuonekorttiResponse[]> => {
        const response = await http.get(url(sairaala.value), { 
            params: { 
                sairaala: sairaala.value, 
                ...(tilanimike?.value ? { tilanimike: tilanimike.value } : {})
            } 
        })

        return response.data

    }

    const { 
        data: mallihuonekortit,
        error, 
        isPending: loading,
    } = useQuery({ queryKey: key, queryFn: () => fetcher(), enabled: () => sairaala.value != undefined })

    return {
        mallihuonekortit,
        error,
        loading,
    }
}

export function useAddMallihuonekortti() {
    const queryClient = useQueryClient()

    const { 
        error, 
        data: response, 
        isPending: loading, 
        mutate
    } = useMutation({
        mutationFn: async (request: {huonekortti: HuonekorttiId, sairaala: number}): Promise<MallihuonekorttiResponse> => {
            const response = await http.post(url(request.sairaala), request.huonekortti)

            return response.data
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["mallihuonekortit"]})
    })

    const addMallihuonekortti = (huonekortti: HuonekorttiId, sairaala: number) => {
        mutate({huonekortti: huonekortti, sairaala: sairaala})
    }

    return {
        error,
        response,
        loading,
        addMallihuonekortti,
    }
}

export function useDeleteMallihuonekortti() {
    const queryClient = useQueryClient()

    const { 
        error, 
        data: response, 
        isPending: loading, 
        mutate
    } = useMutation({
        mutationFn: (id: number) => http.delete(`/sairaalat/mallihuonekortit/${id}`),
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["mallihuonekortit"]})
    })

    const deleteMallihuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        error,
        response,
        loading,
        deleteMallihuonekortti,
    }
}

export function useUpdateMallihuonekortti() {
    const queryClient = useQueryClient()    

    const { 
        error, 
        data: response, 
        isPending: loading, 
        mutate
    } = useMutation({
        mutationFn: async (request: {id: number, request: MallihuonekorttiRequestPut}) : Promise<MallihuonekorttiResponse> => {
            const response = await http.put(`/sairaalat/mallihuonekortit/${request.id}`, request.request)
            
            return response.data
        },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ["mallihuonekortit"]})
    })

    const updateMallihuonekortti = (id: number, request: MallihuonekorttiRequestPut) => {
        mutate({id: id, request: request})
    }

    return {
        error,
        response,
        loading,
        updateMallihuonekortti,
    }
}