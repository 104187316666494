<template>
    <ComCardExpand title="Hankkeet" :show="true" v-if="hankkeet" class="etusivu-hankkeet">
        <div class="float-right-flex">
            <el-checkbox label="Arkistoidut" v-model="arkistoidut" border data-test-id="etusivuArkistoidutCheckbox" />
        </div>
        <el-tree :data="hankkeet" :props="props">
            <template #default="{ data }">
                <div @click="hankeLink(data.id)" :class="{ 'arkistoitu-text': data.arkistoitu }">
                    <el-icon class="hanke-icon">
                        <DocumentCopy></DocumentCopy>
                    </el-icon>
                    <span>{{ data.nimi }}</span>
                </div>
            </template>
        </el-tree>
    </ComCardExpand>
    <ComCard v-else>
        <ComLoading />
    </ComCard>
</template>

<script setup lang="ts">
import ComCardExpand from "@/components/common/ComCardExpand.vue"
import ComLoading from "../common/ComLoading.vue"
import { DocumentCopy } from "@element-plus/icons-vue"
import router from "@/router"
import ComCard from "../common/ComCard.vue"
import { useHankkeet } from "@/methods/hankeComposables"

const { hankkeet, arkistoidut } = useHankkeet()
const props = {
    label: "nimi",
}
const hankeLink = (val: number) => {
    router.push({ name: "Hanke", params: { hankeId: val } })
}

</script>
