import { computed, ref } from "vue";

const state = ref({
    huonekortit: [] as number[],
})


export function useComSideMenu() {
    const add = (val: number) => {
        if (!state.value.huonekortit.find((h) => h === val)) {
            state.value.huonekortit.push(val)
        }
    }
    const remove = (val: number) => {
        const f = state.value.huonekortit.indexOf(val)

        if (f !== -1) {
            state.value.huonekortit.splice(f, 1)
        }
    }
    const clear = () => {
        state.value.huonekortit = []
    }
    const huonekortit = computed(() => state.value.huonekortit)

    return {
        huonekortit,
        add,
        remove,
        clear
    }
}