<template>
    <el-space wrap>
        <ComSelect v-model="vastuuyksikkoSelected" multiple>
            <el-option
                v-for="(vastuuyksikko, index) in hankeVastuuyksikot"
                :key="index"
                :label="vastuuyksikko.nimi"
                :value="vastuuyksikko.id" />
        </ComSelect>
        <el-link type="primary" @click="valitseKaikkiclick">valitse kaikki</el-link>
        <ComOikeusButtons :oikeudet="oikeudet" @oikeus-change="(val) => (oikeudet = val)" />
    </el-space>
</template>

<script setup lang="ts">
import { OikeusProperties } from "@/api/models/oikeus-properties"
import { ref, watchEffect } from "vue"
import ComOikeusButtons from "../ComOikeusButtons.vue"
import ComSelect from "@/components/common/ComSelect.vue"
import { OikeusRequest } from "@/api/models/oikeus-request"
import { hankePage } from "@/views/hanke"

const emit = defineEmits(["oikeudetChange"])
const { hankeVastuuyksikot } = hankePage()
const vastuuyksikkoSelected = ref<number[]>()
const oikeudet = ref<OikeusProperties>({
    luku: true,
    kirjoitus: false,
    raportointi: false,
})

const valitseKaikkiclick = () => {
    vastuuyksikkoSelected.value = []

    vastuuyksikkoSelected.value = hankeVastuuyksikot.value?.map((v) => v.id)
}

watchEffect(() => {
    if (vastuuyksikkoSelected.value) {
        const response: OikeusRequest[] = []

        vastuuyksikkoSelected.value.forEach((a) => {
            const oikeus: OikeusRequest = {}
            oikeus.luku = oikeudet.value.luku
            oikeus.kirjoitus = oikeudet.value.kirjoitus
            oikeus.raportointi = oikeudet.value.raportointi
            oikeus.vastuuyksikko = { id: a }

            response.push(oikeus)
        })

        emit("oikeudetChange", response)
    }
})
</script>
