import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

import ComCardExpand from "../common/ComCardExpand.vue"
import ComEditor from "../common/ComEditor.vue"
import ComOhjeSahkoluokka from "../ohjeet/ComOhjeSahkoluokka.vue"
import { useToimintatiedot } from "./toimintatiedot";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComToimintatiedot',
  setup(__props) {

const { kirjoitus, sahkoluokka, toimintatiedot, toimintaAika, tallenna  } = useToimintatiedot()


return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_unref(toimintatiedot))
    ? (_openBlock(), _createBlock(ComCardExpand, {
        key: 0,
        title: "Toimintatiedot",
        show: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { gutter: 10 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _cache[6] || (_cache[6] = _createElementVNode("label", null, "Toiminnan kuvaus", -1)),
                  _createVNode(ComEditor, {
                    modelValue: _unref(toimintatiedot).toiminnanKuvaus,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(toimintatiedot).toiminnanKuvaus) = $event)),
                    disabled: !_unref(kirjoitus)
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("label", null, "Toiminnan tilalle asettamat vaatimukset", -1)),
                  _createVNode(ComEditor, {
                    modelValue: _unref(toimintatiedot).toiminnanVaatimukset,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(toimintatiedot).toiminnanVaatimukset) = $event)),
                    disabled: !_unref(kirjoitus)
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form, {
            inline: true,
            row: "",
            class: "row-margin"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "Henkilömäärä normaalisti" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    class: "number-input-mini",
                    min: 0,
                    size: "small",
                    modelValue: _unref(toimintatiedot).henkilomaaraNorm,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(toimintatiedot).henkilomaaraNorm) = $event)),
                    disabled: !_unref(kirjoitus)
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "Henkilömäärä maksimi" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input_number, {
                    class: "number-input-mini",
                    min: 0,
                    size: "small",
                    modelValue: _unref(toimintatiedot).henkilomaaraMax,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(toimintatiedot).henkilomaaraMax) = $event)),
                    disabled: !_unref(kirjoitus)
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "Sähköluokka" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_radio_group, {
                        placeholder: "Valitse",
                        size: "small",
                        modelValue: _unref(toimintatiedot).sahkoluokka,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(toimintatiedot).sahkoluokka) = $event)),
                        disabled: !_unref(kirjoitus)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sahkoluokka), (item) => {
                            return (_openBlock(), _createBlock(_component_el_radio_button, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "disabled"]),
                      _createVNode(ComOhjeSahkoluokka)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "Toiminta-aika" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    "allow-create": "",
                    filterable: "",
                    placeholder: "Valitse tai kirjoita",
                    size: "small",
                    modelValue: _unref(toimintatiedot).toimintaAika,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(toimintatiedot).toimintaAika) = $event)),
                    disabled: !_unref(kirjoitus),
                    style: {"width":"240px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toimintaAika), (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item,
                          label: item,
                          value: item
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_unref(kirjoitus))
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "success",
                onClick: _unref(tallenna),
                size: "small"
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Tallenna")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})