import { Ref, computed } from "vue"
import http from "@/http-common"
import { useQuery } from "@tanstack/vue-query"
import { NimikeResponse } from "@/api/models/nimike-response"
import { KategoriaResponse } from "@/api/models/kategoria-response"
import { CascaderOption } from "element-plus/es/components/cascader-panel/src/node"

const nimikkeetUrl = "/nimikkeet"
const nimikeKategoriatUrl = "/nimikkeet/kategoriat"

export function useNimikkeetData() {
    const key = ["/nimikkeet"]
    
    const fetcher = async () : Promise<NimikeResponse[]> => {
        return (await http.get(nimikkeetUrl)).data
    }

    const { data, isLoading: loading, error } = useQuery({queryKey: key, queryFn: fetcher, staleTime: 1000 * 60 * 60 * 24})

    const getNimikeById = (id: Ref<number | undefined>) => {
        return computed(() => {
            if (data.value) {
                return data.value.find((nimike) => nimike.id === id.value)
            } else {
                return undefined
            }
        })
    }

    return {
        nimikkeet: data,
        loading,
        error,
        getNimikeById,
    }
}

export function useNimikeKategoriaData(nimikkeet?: Ref<boolean>, ei_kaytossa?: Ref<boolean>) {
    const key = computed(() => {
        const params = Object.assign({},
            nimikkeet?.value ? { nimikkeet: nimikkeet.value } : null,
            ei_kaytossa?.value ? { ei_kaytossa: ei_kaytossa.value } : null
        )

        return [nimikeKategoriatUrl, params]
    })
    
    const fetcher = async () : Promise<KategoriaResponse[]> => {
        return (await http.get(nimikeKategoriatUrl, { params: key.value[1] })).data
    }

    const { data, error, isLoading: loading } = useQuery({queryKey: key, queryFn: fetcher, staleTime: 1000 * 60 * 60 * 24})

    return {
        nimikeKategoriat: data,
        error,
        loading
    }
}

export function setNodes(kategoriat: KategoriaResponse[], poistetut?: boolean) {
    const nodes = [] as CascaderOption[]

    kategoriat.forEach((k) => {
        const node = {} as CascaderOption
        node.value = k.id
        node.label = k.nimi
        node.disabled = !k.kaytossa
        k.children?.forEach((c) => {
            const child = {} as CascaderOption
            child.value = c.id
            child.label = c.nimi
            child.disabled = !c.kaytossa
            c.nimikkeet?.forEach((n) => {
                const nimike = {} as CascaderOption
                nimike.value = n.id
                nimike.label = n.nimi
                nimike.disabled = !n.kaytossa
                if (!nimike.disabled || (nimike.disabled && poistetut)) {
                    if (child.children == undefined) child.children = []

                    child.children.push(nimike)
                }
            })
            if (!child.disabled || (child.disabled && poistetut)) {
                if (node.children == undefined) node.children = []

                node.children.push(child)
            }
        })
        if (!node.disabled || (node.disabled && poistetut)) {
            nodes.push(node)
        }
    })
    return nodes
}
