import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import kommenttiTyyppi from "@/types/Kommentti"
import { PropType, ref, watchEffect } from "vue"
import { useKommentitComponent } from "./kommentit"
import ComCommentButton from "../common/buttons/ComCommentButton.vue";
import ComKommentit from "./ComKommentit.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComKommentointi',
  props: {
    viite: {
        type: Number,
        required: true
    },
    tyyppi: {
        type: Number as PropType<kommenttiTyyppi>,
        required: true
    },
    paakayttaja: {
        type: Boolean,
        required: true
    }
},
  setup(__props) {

const props = __props

const showDrawer = ref(false)
const { tyyppi: tyyppiState, viite: viiteState, isPaakayttaja } = useKommentitComponent()

const showDrawerClick = () => {
    showDrawer.value = true
    tyyppiState.value = props.tyyppi
    viiteState.value = props.viite
}

watchEffect(() => {
    isPaakayttaja.value = props.paakayttaja
})

return (_ctx: any,_cache: any) => {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ComCommentButton, { onClick: showDrawerClick }),
    (showDrawer.value)
      ? (_openBlock(), _createBlock(_component_el_drawer, {
          key: 0,
          modelValue: showDrawer.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showDrawer).value = $event)),
          "append-to-body": "",
          title: "Kommentit",
          size: "30%",
          "destroy-on-close": ""
        }, {
          default: _withCtx(() => [
            _createVNode(ComKommentit)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})