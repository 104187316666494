import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "side-menu-header" }
const _hoisted_2 = { class: "side-menu-expand" }
const _hoisted_3 = { class: "node_title" }

import { ref } from "vue"
import ComSideMenuVastuuyksikko from "./ComSideMenuVastuuyksikko.vue"
import ComExpand from "@/components/common/ComExpand.vue"
import { DocumentCopy } from "@element-plus/icons-vue"
import router from "@/router"
import ComTooltip from "@/components/common/ComTooltip.vue"
import { useHankkeet } from "@/methods/hankeComposables"
import Orientation from "@/types/OrientationEnum"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSideMenu',
  setup(__props) {

const props = {
    children: "vastuuyksikot",
    label: "nimi",
}
const { hankkeet } = useHankkeet()
const expand = ref(false)
const width = ref(280)

const expandMenu = () => {
    expand.value = !expand.value
    width.value = expand.value ? 480 : 280
}

const navigate = (id: number) => {
    router.push({ name: "Hanke", params: { hankeId: id } })
}


return (_ctx: any,_cache: any) => {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", {
    class: "side-menu-container hidden-xs-only",
    style: _normalizeStyle({ width: width.value + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_image, {
        src: require('@/assets/vahtilogo.png'),
        class: "side-menu-logo"
      }, null, 8, ["src"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(ComExpand, {
          way: _unref(Orientation).horizontal,
          show: expand.value,
          onExpand: expandMenu
        }, null, 8, ["way", "show"])
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", null, [
      _createElementVNode("label", null, "Hankkeet")
    ], -1)),
    _createVNode(_component_el_tree, {
      data: _unref(hankkeet),
      "node-key": "id",
      props: props,
      class: "side-menu-tree",
      "highlight-current": false
    }, {
      default: _withCtx(({ node, data }) => [
        (node.level === 2)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass({ 'arkistoitu-text': node.parent.data.arkistoitu }),
              style: {"overflow":"hidden"}
            }, [
              _createVNode(ComSideMenuVastuuyksikko, {
                data: data,
                checkbox: false
              }, null, 8, ["data"])
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["side-menu-hanke", { 'arkistoitu-text': data.arkistoitu }])
            }, [
              _createVNode(ComTooltip, {
                content: data.nimi
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(data.nimi), 1)
                ]),
                _: 2
              }, 1032, ["content"]),
              _createVNode(_component_el_icon, {
                onClick: _withModifiers(($event: any) => (navigate(data.id)), ["stop"]),
                class: "side-menu-hanke-icon"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DocumentCopy))
                ]),
                _: 2
              }, 1032, ["onClick"])
            ], 2))
      ]),
      _: 1
    }, 8, ["data"])
  ], 4))
}
}

})