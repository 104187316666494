<template>
    <div class="card-margin-bottom">
        <el-row>
            <el-col :span="24">
                <el-checkbox
                    label="Valitse erikseen raportoitavat huonekortit"
                    v-model="huonekorteittain"></el-checkbox>
            </el-col>
        </el-row>
        <el-divider v-if="huonekorteittain" />
        <el-row v-if="huonekorteittain">
            <el-col :span="24">
                <ComHuonekorttiTableWithFilterAndPagination />
            </el-col>
        </el-row>
    </div>
</template>

<script setup lang="ts">
import ComHuonekorttiTableWithFilterAndPagination from "@/components/hanke/huonekortit/table/ComHuonekorttiTableWithFilterAndPagination.vue"
import { ref, watch } from "vue"

const emit = defineEmits(["huonekorteittain"])
const huonekorteittain = ref(false)

watch(huonekorteittain, () => {
    emit("huonekorteittain", huonekorteittain.value)
})

</script>
