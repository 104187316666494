<template>
    <div @click="logout">
        <ComTooltip content="Kirjaudu ulos" placement="bottom">
            <slot></slot>
        </ComTooltip>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useAuth0 } from "@/auth/auth0-plugin"
import ComTooltip from "../ComTooltip.vue"

export default defineComponent({
    name: "LogoutButton",
    components: { ComTooltip },
    setup() {
        const logoutUrl = process.env.VUE_APP_AUTH0_LOGOUT_URL

        const auth0 = useAuth0()
        const logout = (): void => {
            if (!auth0) {
                return
            }

            auth0.logout({
                logoutParams: {
                    returnTo: logoutUrl,
                },
            })
        }

        return {
            logout,
        }
    },
})
</script>
