<template>
    <el-timeline-item :timestamp="previousData.aika" placement="top" type="primary" size="large" v-if="tyhja">
        <el-card>
            <table style="width: 100%">
                <tbody>
                    <tr>
                        <td>
                            <label>Tekijä:</label>
                            {{ previousData.kayttaja?.etunimi }} {{ previousData.kayttaja?.sukunimi }}
                        </td>
                        <td>
                            <label>Aika:</label>
                            {{ previousData.aika }}
                        </td>
                    </tr>
                    <tr>
                        <td v-if="henkilomaaraNorm">
                            <label>Henkilömäärä normaalisti:</label>
                            <span v-html="henkilomaaraNorm" />
                        </td>
                        <td v-if="henkilomaaraMax">
                            <label>Henkilömäärä maksimi:</label>
                            <span v-html="henkilomaaraMax" />
                        </td>
                        <td v-if="sahkoluokka">
                            <label>Sähköluokka:</label>
                            <span v-html="sahkoluokka" />
                        </td>
                        <td v-if="toimintaAika">
                            <label>Toiminta-aika:</label>
                            <span v-html="toimintaAika" />
                        </td>
                    </tr>
                    <tr v-if="toiminnanKuvaus">
                        <th colspan="4">Toiminnan kuvaus</th>
                    </tr>
                    <tr v-if="toiminnanKuvaus">
                        <td colspan="4">
                            <span v-html="toiminnanKuvaus" />
                        </td>
                    </tr>
                    <tr v-if="toiminnanVaatimukset">
                        <th colspan="4">Toiminnan tilalle asettamat vaatimukset</th>
                    </tr>
                    <tr v-if="toiminnanVaatimukset">
                        <td colspan="4">
                            <span v-html="toiminnanVaatimukset" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </el-timeline-item>
</template>

<script setup lang="ts">
import { HuonekorttiHistoriaResponse } from "@/api/models/huonekortti-historia-response"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { computed } from "vue"
import { htmlToText } from "html-to-text"
import { diffAndSanitize } from "@/utils/diff";

const props = defineProps<{
    data: HuonekorttiHistoriaResponse | HuonekorttiResponse,
    previousData: HuonekorttiHistoriaResponse,
}>()
    
const henkilomaaraNorm = computed(() => {
    const a = props.previousData.henkilomaaraNorm?.toString() ?? ""
    const b = props.data.henkilomaaraNorm?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const henkilomaaraMax = computed(() => {
    const a = props.previousData.henkilomaaraMax?.toString().trim() ?? ""
    const b = props.data.henkilomaaraMax?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const sahkoluokka = computed(() => {
    const a = props.previousData.sahkoluokka?.toString() ?? ""
    const b = props.data.sahkoluokka?.toString() ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toimintaAika = computed(() => {
    const a = props.previousData.toimintaAika ?? ""
    const b = props.data.toimintaAika ?? ""

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toiminnanKuvaus = computed(() => {
    const a = htmlToText(props.previousData.toiminnanKuvaus ?? "")
    const b = htmlToText(props.data.toiminnanKuvaus ?? "")
    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const toiminnanVaatimukset = computed(() => {
    const a = htmlToText(props.previousData.toiminnanVaatimukset ?? "")
    const b = htmlToText(props.data.toiminnanVaatimukset ?? "")

    if (a === b) {
        return false
    }

    return diffAndSanitize(a, b)
})

const tyhja = computed(() => {
    if (
        !henkilomaaraMax.value &&
        !henkilomaaraNorm.value &&
        !sahkoluokka.value &&
        !toimintaAika.value &&
        !toiminnanKuvaus.value &&
        !toiminnanVaatimukset.value
    ) {
        return false
    }

    return true
})
</script>
